//================================================================
//  Component: Approval Modal
//================================================================

//  Purpose: Allows the approver to approve or reject a business request

//  Example:
//    <ApprovalModal
//      approval={approval}                     // useState in ApprovalsPage.js
//      modalState={modalState}                 // useReducer in ApprovalsPage.js
//      setModalState={setModalState}           // useReducer in ApprovalsPage.js 
//    ></ApprovalModal>    

//================================================================

// Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../Library/GlobalContexts';

// Functions
import WriteDocument from '../../../Library/WriteDocument';

// Images
import Tick from '../../../Components/Images/Icon_Approved_Green.svg';
import Reject from '../../../Components/Images/Icon_Reject_Red.svg';

export default function ApprovalModal({
    approval,
    modalState,
    setModalState
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Submit the approved/rejected status to Firestore
  function onApproveRejectHandler(status){

    // If Status === 'Rejected' > Check rejection reason
    if (modalState?.status === 'Rejected' && modalState?.reason.length === 0) {
        return setModalState({'error': 'Please provide a reason for rejection.'})
    }

    //=================================================
    //  Write Document to Firestore
    //=================================================

    const document = {
        'status': status,
        'comments': modalState.reason,
        'modified': new Date(),
        'modifiedby': getUser.emailaddress,
        'approvaldate': status === 'Approved' && new Date()
    }

    WriteDocument('approvals', approval?.approvalid, document, true)
    .then(() => {

        navigate('/approvals');

    }).catch((error) => {

        setModalState({'error': error.message});

    });

  };

  //------------------------------------------------------
  //  HTML 
  //------------------------------------------------------

  if (modalState.modalOpen !== true) return;

  return (
    <div className='Modal-Background'>

        <dialog className={`w-[50%] flex flex-col gap-2 rounded-md z-100 absolute inset-y-0.5 p-[2%] overflow-auto bg-white border-t-[15px] border-t-solid ${modalState?.status === 'Approved' ? 'border-t-[#61B280]' : 'border-t-[#D85C5C]'} [@media(max-width:450px)]:w-[80%]  [@media(max-width:450px)]:p-[5%]`}>

            {/* Modal Header */}
            <div className='flex flex-row gap-2 items-center'>
                {
                    modalState?.status === 'Approved' ?
                    <img className='[@media(max-width:450px)]:hidden' src={Tick} alt='Approved'></img>
                    :
                    <img className='[@media(max-width:450px)]:hidden' src={Reject} alt='Rejected'></img>
                }
                <p className='font-medium text-xl m-0 capitalize [@media(max-width:450px)]:px-[2%]'>
                    {modalState?.status === 'Approved' ? 'Approve' : 'Reject'} Request
                </p>
            </div>

            {/* Modal Body */}
            <div className='flex flex-col px-[2%] pt-[1%] gap-2'>
                
                {/* Message */}
                <p className='p-0 m-0'>Are you sure you want to {modalState?.status === 'Approved' ? 'approve' : 'reject'} the request below?</p>
                <ul className='list-disc'>
                    <li className='font-medium'>{approval?.requestname}</li>
                </ul>

                {/* Comments & Approvals */}
                <div className='flex flex-column'>

                    <textarea
                        className='Input-Field-TextArea'
                        placeholder='Comments'
                        onChange={(e) => setModalState({'reason': e.target.value})}
                        value={modalState.reason}
                        disabled={modalState.readOnly}
                    >
                    </textarea>

                    <label className='py-[10px] text-[#DE0000] font-medium'>{modalState.error}</label>
                </div>

            </div>

            {/* Modal Footer (Buttons) */}
            <div className='flex flex-row gap-2 px-[2%]'>
                <button 
                    className='Primary-Button'
                    style={
                        modalState?.status === 'Approved' ?
                        { backgroundColor: '#61B280', border: '1px solid #61B280' }
                        :
                        { backgroundColor: '#D85C5C', border: '1px solid #D85C5C' }
                    }
                    onClick={() => onApproveRejectHandler(modalState?.status)}
                >
                    {modalState?.status === 'Approved' ? 'Approve' : 'Reject'}
                </button>
                <button 
                    className='Secondary-Button'
                    style={
                        modalState?.status === 'Approved' ?
                        { color: '#61B280', border: '1px solid #61B280' }
                        :
                        { color: '#D85C5C', border: '1px solid #D85C5C' }
                    }                    
                    onClick={() => setModalState({ 'modalOpen': false })}
                >
                    Cancel
                </button>
            </div>

        </dialog>

    </div>
  )
}
