//================================================================
//  Component: Business Request Overview
//================================================================

//  Purpose: Component of the BusinessRequestsId.js page, created to support tabular view

//  Properties:

//  Example:
//  <BusinessRequestsOverview
//      businessRequest={businessRequest}       // useState that holds the business request
//      approvalStatus={approvalStatus}         // useState that holds the business requests approval status
//      setPageStatus={setPageStatus}           // useState holding page status ('pending', 'onload', etc)
//  ></BusinessRequestsOverview>

//================================================================


//Libraries
import React, { useContext, useState } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import StatusLabel from '../StatusLabel/StatusLabel';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import WriteDocument from '../../Library/WriteDocument';


export default function BusinessRequestsOverview({
    businessRequest,
    approvalStatus,
    setPageStatus,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to determine if Re-trigger Approvals button is visible
    const [retriggerButtonVisible, setRetriggerButtonVisible] = useState(businessRequest?.status === 'Rejected');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handle Re-triggers
    function handleRetrigger() {

        setRetriggerButtonVisible(false);

        // Find the rejected approval item
        QueryDocument('approvals', [
            ['businessrequestid', '==', businessRequest?.businessrequestid],
            ['status', '==', 'Rejected']
        ],  ['created', 'desc'])
        .then((docs) => {

            // Create new approval item with 'Retrigger-Approval' status
            const newApproval = {
                'approvaldate': '',
                'businessrequestid': businessRequest?.businessrequestid,
                'approvalid': `${businessRequest?.businessrequestid}-RETRIGGER-${getUser?.emailaddress}-${Date.now()}`,
                'approver': docs[0].approver,
                'businessunit': businessRequest?.primarybeneficiary,
                'comments': '',
                'created': new Date(),
                'modified': new Date(),
                'modifiedby': getUser.emailaddress,
                'summary': businessRequest?.summary,
                'owner': businessRequest?.owner,
                'businesslead': businessRequest?.businesslead,
                'linemanager': businessRequest?.linemanager,
                'financemanager': businessRequest?.financemanager,
                'primarybeneficiary': businessRequest?.primarybeneficiary,
                'secondarybeneficiary': businessRequest?.secondarybeneficiary,
                'requestname': businessRequest?.initiativename,
                'role': '', 
                'stage': docs[0]?.stage,
                'status': 'Retrigger-Approval',
            }

            return WriteDocument('approvals', newApproval.approvalid, newApproval, false);

        })
        .catch((error) => {

            console.log('error', error);
            setPageStatus('error-fatal');

        });


    }

    // Handle submit
    function onSubmit(e){

        // Prevent default behaviour (refresh)
        e.preventDefault();

        // Set status to pending
        setPageStatus('pending');
        
        // Write document to Firestore
        const businessRequestDoc = { 
        'status': approvalStatus,
        };
    
        return WriteDocument('businessrequests', businessRequest?.businessrequestid, businessRequestDoc, true).then(() =>{
        
            setPageStatus('success');

        }).catch((error) =>{

        console.log(error);
        setPageStatus('error-fatal');

        });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return(
        <div className='Page-Section-Container'>

            {/* Form */}
            <form className='BusinessRequest-Form' onSubmit={(e) => onSubmit(e)}>

                {/* =========================================================== */}
                {/*  1. What is the name of this initiative / application? *    */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>1. What is the name of this initiative / application? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='text' value={businessRequest?.initiativename} disabled></input>
                </div>

                {/* =========================================================== */}
                {/*  2. What is the business or project need? *                 */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>2. What is the business or project need? *</b> </label>
                    <textarea className={'Input-Field-TextArea-ReadOnly'} name='businessneed' value={businessRequest?.businessneed} disabled></textarea>
                </div>

                {/* =========================================================== */}
                {/*  3. Who is the owner of this initiative / application? *    */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>3. Who is the owner of this initiative / application? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.owner} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  4. Who is the project lead / business lead supporting this initiative / application? *    */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>4. Who is the project lead / business lead supporting this initiative / application? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.businesslead} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  5. Who is your line manager? *                                                            */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>5. Who is your line manager? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.linemanager} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  6. Who is the finance manager for this request? *                                         */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>6. Who is the finance manager for this request? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.financemanager} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  7.  Who will be the primary beneficiary of this business improvement solution? *          */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>7. Who will be the primary beneficiary of this business improvement solution? *</b> </label>
                    <select className={'Input-Field-Select'} style={{ width: '300px'}} value={businessRequest?.primarybeneficiary} disabled>
                        <option hidden value=''>-</option>
                        <option value='Capella'> Capella </option>
                        <option value='Communities'> Communities </option>
                        <option value='Construction'> Construction </option>
                        <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                        <option value='Corporate Real Estate'> Corporate Real Estate </option>
                        <option value='Development'> Development </option>
                        <option value='DOO Office'> DOO Office </option>
                        <option value='EHS'> EHS </option>
                        <option value='Finance'> Finance </option>
                        <option value='Integrated Solutions'> Integrated Solutions </option>
                        <option value='Investment Management'> Investment Management </option>
                        <option value='Legal'> Legal </option>
                        <option value='People & Culture'> People & Culture </option>
                        <option value='RAP'> RAP </option>
                        <option value='Risk and Insurance'> Risk and Insurance </option>
                        <option value='Sustainability'> Sustainability </option>
                    </select>
                    </div>

                {/* ========================================================================================== */}
                {/*  8. Who will be the secondary beneficiary(s) for this business improvement solution? *     */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>8. Who will be the secondary beneficiary(s) for this business improvement solution? *</b></label>
                    <select className={'Input-Field-Select'} value={businessRequest?.secondarybeneficiary} style={{ width: '400px', height: '100px' }} multiple disabled>
                        <option hidden value=''>-</option>
                        <option value='Capella'> Capella </option>
                        <option value='Communities'> Communities </option>
                        <option value='Construction'> Construction </option>
                        <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                        <option value='Corporate Real Estate'> Corporate Real Estate </option>
                        <option value='Development'> Development </option>
                        <option value='DOO Office'> DOO Office </option>
                        <option value='EHS'> EHS </option>
                        <option value='Finance'> Finance </option>
                        <option value='Integrated Solutions'> Integrated Solutions </option>
                        <option value='Investment Management'> Investment Management </option>
                        <option value='Legal'> Legal </option>
                        <option value='People & Culture'> People & Culture </option>
                        <option value='RAP'> RAP </option>
                        <option value='Risk and Insurance'> Risk and Insurance </option>
                        <option value='Sustainability'> Sustainability </option>
                    </select>
                </div>

                {/* ========================================================================================== */}
                {/*  9. Who is the BU DOO supporting this initiative / application? *                          */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>9. Who is the BU DOO supporting this initiative / application? *</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.budoo} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  10. What type of request is this? *                                                        */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>10. What type of request is this? *</b> </label>
                    <select className={'Input-Field-Select'} value={businessRequest?.requesttype} style={{ width: '450px' }} disabled>
                        <option hidden value=''>-</option>
                        <option value='New Initiative / Product'> New Initiative / Product </option>
                        <option value='Renewal of an existing product'> Renewal of an existing product </option>
                        <option value='Replacing an existing product'> Replacing an existing product </option>
                    </select>
                </div>

                {/* ========================================================================================== */}
                {/*  10a. What is the name of the existing product? *                                           */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row' hidden={businessRequest?.requesttype === 'Replacing an existing product' ? false : true}>
                    <label><b style={{fontWeight: "500"}}>10a. What is the name of the existing product? *</b> </label>
                    <input className='Input-Field-Text-ReadOnly' type='text' value={businessRequest?.aexistingproduct} disabled></input>
                </div>

                {/* ========================================================================================== */}
                {/*  11. Will this initiative improve one or more of the following? *                           */}
                {/* ========================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>11. Will this initiative improve one or more of the following? *</b></label>
                    <select className={'Input-Field-Select'} value={businessRequest?.improvements} style={{ width: '400px', height: '100px' }} multiple disabled>
                        <option hidden value=''>-</option>
                        <option value='Business efficiency'> Business efficiency </option>
                        <option value='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                        <option value='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                        <option value='ESG requirements or credentials'> ESG requirements or credentials </option>
                        <option value='Investment in our people'> Investment in our people </option>
                        <option value='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                        <option value='Regulatory compliance'> Regulatory compliance </option>
                        <option value='Risk mitigation'> Risk mitigation </option>
                    </select>
                </div>

                {/* =================================================================================================== */}
                {/*  12. Provide a summary of how this initiative will improve the items chosen above *                 */}
                {/* =================================================================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>12. Provide a summary of how this initiative will improve the items chosen above *</b> </label>
                    <textarea className={'Input-Field-TextArea-ReadOnly'} value={businessRequest?.summary} disabled></textarea>
                </div>

                {/* =========================================================== */}
                {/*  13. Does this initiative drive revenue growth? *           */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>13. Does this initiative drive revenue growth? *</b> </label>
                    <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                        <label className='Radio-Buttons-Container'>
                            Yes
                            <input type='radio' name='revenuegrowth' value='yes' defaultChecked={businessRequest?.revenuegrowth.toLowerCase() === 'yes'} disabled></input>
                            <span className='Radio-Checkmark'></span>
                        </label>

                        <label className='Radio-Buttons-Container'>
                            No
                            <input type='radio' name='revenuegrowth' value='no' defaultChecked={businessRequest?.revenuegrowth.toLowerCase() === 'no'} disabled></input>
                            <span className='Radio-Checkmark'></span>
                        </label>
                    </div>
                </div>

                {/* =========================================================== */}
                {/*  13a. If yes, when would revenue growth commence?*          */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row' hidden={businessRequest?.revenuegrowth === 'yes' ? false : true}>
                    <label><b style={{fontWeight: "500"}}>13a. If yes, when would revenue growth commence? *</b> </label>
                    <select className='Input-Field-Select' value={businessRequest?.arevenuegrowthcommence} style={{ width: '450px'}} disabled>
                        <option hidden value=''>-</option>
                        <option value='Within 6 months'> Within 6 months </option>
                        <option value='6 months to 1 year'> 6 months to 1 year </option>
                        <option value='1 to 2 years'> 1 to 2 years </option>
                        <option value='In more than 2 years'> In more than 2 years </option>
                    </select>
                </div>

                {/* =========================================================== */}
                {/*  14. Does this product accelerate funds growth? *           */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>14. Does this product accelerate funds growth? *</b> </label>
                    <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                        <label className='Radio-Buttons-Container'>
                            Yes
                            <input type='radio' name='fundsgrowth' value='yes' defaultChecked={businessRequest?.fundsgrowth.toLowerCase() === 'yes'} disabled></input>
                            <span className='Radio-Checkmark'></span>
                        </label>
                        <label className='Radio-Buttons-Container'>
                            No
                            <input type='radio' name='fundsgrowth' value='no' defaultChecked={businessRequest?.fundsgrowth.toLowerCase() === 'no'} disabled></input>
                            <span className='Radio-Checkmark'></span>
                        </label>
                    </div>
                </div>

                {/* =========================================================== */}
                {/*  14a. If yes, when would funds growth commence? *           */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row' hidden={businessRequest?.fundsgrowth === 'yes' ? false : true}>
                    <label><b style={{fontWeight: "500"}}>14a. If yes, when would funds growth commence? *</b> </label>
                    <select className={'Input-Field-Select'} value={businessRequest?.afundsgrowthcommence} style={{ width: '450px'}} disabled>
                        <option hidden value=''>-</option>
                        <option value='Within 6 months'> Within 6 months </option>
                        <option value='6 months to 1 year'> 6 months to 1 year </option>
                        <option value='1 to 2 years'> 1 to 2 years </option>
                        <option value='In more than 2 years'> In more than 2 years </option>
                    </select>
                </div>

                {/* =========================================================== */}
                {/*  15. What LoA Type is this initiative / application? *      */}
                {/* =========================================================== */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>15. What LoA Type is this initiative / application? *</b> </label>
                    <select className='Input-Field-Select' value={businessRequest?.loatype} disabled>
                        <option hidden value=''>-</option>
                        <option value='IT Capex'> IT Capex </option>
                        <option value='IT Opex'> IT Opex </option>
                        <option value='Project Based'> Project Based </option>
                    </select>
                </div>

                {/* ================================================================ */}
                {/*  16. What is the anticipated complete cost of this initiative? * */}
                {/* ================================================================ */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>16. What is the anticipated complete cost of this initiative? *</b> </label>
                    <select className='Input-Field-Select' value={businessRequest?.cost} disabled>
                        <option hidden value=''>-</option>
                        <option value='$0'> $0 </option>
                        <option value='$1 to $10k'> $1 to $10k </option>
                        <option value='$10k to $30k'> $10k to $30k </option>
                        <option value='$30k to $50k'> $30k to $50k </option>
                        <option value='$50k to $100k'> $50k to $100k </option>
                        <option value='$100k to $250k'> $100k to $250k </option>
                        <option value='$250k or more'> $250k or more </option>
                    </select>
                </div>

                {/* ================================================================ */}
                {/*  Created By                                                      */}
                {/* ================================================================ */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>Created By</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.createdby} disabled></input>
                </div>

                {/* ================================================================ */}
                {/*  Created Date                                                    */}
                {/* ================================================================ */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>Created Date</b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} style={{width: '185px'}} type='text' value={businessRequest?.created?.toDate().toLocaleString()} disabled></input>
                </div>

                {/* ============================================ */}
                {/*  CSM                                         */}
                {/* ============================================ */}

                <div className='BusinessRequest-Form-Row' hidden={businessRequest?.status === 'Awaiting Line Manager Approval' || businessRequest?.status === 'Awaiting Finance Manager Approval' || businessRequest?.status === 'BU Approved' || businessRequest?.status === 'Rejected'}>
                    <label><b style={{fontWeight: "500"}}> Customer Success Manager </b> </label>
                    <input className={'Input-Field-Text-ReadOnly'} type='email' value={businessRequest?.csm} disabled></input>
                </div>

                {/* ================================================================ */}
                {/*  Approval Status                                                 */}
                {/* ================================================================ */}

                <div className='BusinessRequest-Form-Row'>
                    <label><b style={{fontWeight: "500"}}>Approval Status</b> </label>

                    <StatusLabel status={businessRequest?.status}></StatusLabel>

                    {/*  Re-trigger Approval Button */}
                    <div hidden={getUser.roles?.isApprovalAdmin === false || (businessRequest?.status !== 'Rejected' && retriggerButtonVisible === false)}>
                        <p className='text-base mb-2'>If you want to change the rejection status on this business request and re-trigger the approvals, click the button below.</p>
                        <button className='Primary-Button' onClick={() => handleRetrigger()}>Re-trigger Approvals</button>
                    </div>

                </div>
 

                {/* ================================================================ */}
                {/*  Buttons                                                         */}
                {/* ================================================================ */}

                <div className='mt-3'>
                    <button className='Primary-Button' type='submit' disabled={approvalStatus === businessRequest?.status} >Submit</button>
                </div>

            </form>

        </div>
    )

  //------------------------------------------------------
}
