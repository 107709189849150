//================================================================
//  Component: Business Requests
//================================================================

//  Purpose: This page displays all business requests submitted by LL Users

//  Example:
//    <BusinessRequestForm></BusinessRequestForm>    

//================================================================


//Libraries
import React, { useState, useContext, useEffect, useReducer } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import ApproversTable from './Components/ApproversTable';

//Functions
import CollectionListener from '../../Library/CollectionListener';

//Images

//CSS


export default function Approvers() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store form inputs
  const [formData, setFormData] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      // Groups
      'itApprovers': [],
      'dpmApprovers': [],
      'steercoApprovers': [],

      // Roles
      'DPM': [],
      'IT': [],
      'RDOO': [],
      'RCFO': [],
      'BU Steer Co': [],
    }
  );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Onload --> Get all business requests
  useEffect(()=>{
  
    if (getUser === undefined) return;
  
    function onLoad(documents){

      setFormData({

        // Groups
        'itApprovers': documents.filter((doc) => doc.approvalbucket === 'IT Approval'),
        'dpmApprovers': documents.filter((doc) => doc.approvalbucket === 'DPM Approval'),
        'rdooApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'RDOO' ),
        'rcfoApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'RCFO' ),
        'steercoApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'BU Steer Co'),

        // Roles (Groups contain different roles --> they are unique)
        'IT': documents.filter((doc) => doc.role === 'IT'),
        'DPM': documents.filter((doc) => doc.role === 'DPM'),
        'RDOO': documents.filter((doc) => doc.role === 'RDOO'),
        'RCFO': documents.filter((doc) => doc.role === 'RCFO'),
        'BU Steer Co': documents.filter((doc) => doc.role === 'BU Steer Co'),
      
      });

      setPageStatus('onload');

    }
    
    function onChange(documents){

      setFormData({

        // Approval Buckets
        'itApprovers': documents.filter((doc) => doc.approvalbucket === 'IT Approval'),
        'dpmApprovers': documents.filter((doc) => doc.approvalbucket === 'DPM Approval'),
        'rdooApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'RDOO' ),
        'rcfoApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'RCFO' ),
        'steercoApprovers': documents.filter((doc) => doc.approvalbucket === 'LLA Digital Steer Co Approval' && doc.role === 'BU Steer Co'),

        // Roles (Groups contain different roles --> they are unique)
        'IT': documents.filter((doc) => doc.role === 'IT'),
        'DPM': documents.filter((doc) => doc.role === 'DPM'),
        'RDOO': documents.filter((doc) => doc.role === 'RDOO'),
        'RCFO': documents.filter((doc) => doc.role === 'RCFO'),
        'BU Steer Co': documents.filter((doc) => doc.role === 'BU Steer Co'),
      
      });

    }
    
    function onError(){

      setPageStatus('error-fatal');

    }
    
    const unsubscribe = CollectionListener('approvers', onLoad, onChange, onError)
    
    return () =>{
      unsubscribe();
    };
    
  // eslint-disable-next-line
  }, [getUser]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>

            {/* Heading */}
            <div className='flex flex-col w-fit-content'>
                <p className='text-[20px] font-medium m-0 p-0 text-wrap'>Approvers</p>
                <p className='mt-2 text-base text-wrap m-0'>
                  Below is a list of all the approvers across each approval group. Click <b className='font-medium'>Edit</b> to modify the approvers in each group.
                </p>
            </div>

          </div>
        }
        requiredRoles={ ['isCSM', 'isApprovalAdmin'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='flex flex-col gap-4 mb-2 [@media(max-width:450px)]:gap-1'>

            {/* IT Approvers */}
            <ApproversTable
              formData={formData}
              setFormData={setFormData}
              heading='IT Approvers'
              modalHeading={
                <>
                  <p className='font-medium text-xl mb-4'>Modify IT Approvers</p>
                  <hr></hr>
                </>
              }
              fieldKey={'itApprovers'}
              role='IT'
              approvalbucket='IT Approval'
              modalType={undefined}
            ></ApproversTable>

            {/* DPM Approvers */}
            <ApproversTable
              formData={formData}
              setFormData={setFormData}
              heading='DPM Approvers'
              modalHeading={
                <>
                  <p className='font-medium text-xl mb-4'>Modify DPM Approvers</p>
                  <hr></hr>
                </>
              }
              fieldKey={'dpmApprovers'}
              role='DPM'
              approvalbucket='DPM Approval'
              modalType={undefined}
            ></ApproversTable>

            {/* RDOO Approvers */}
            <ApproversTable
              formData={formData}
              setFormData={setFormData}
              heading='RDOO Approvers'
              modalHeading={
                <>
                  <p className='font-medium text-xl mb-4'>Modify RDOO Approver</p>
                  <hr></hr>
                </>
              }
              fieldKey={'rdooApprovers'}
              role='RDOO'
              approvalbucket='LLA Digital Steer Co Approval'
              modalType={undefined}
            ></ApproversTable>

            {/* RCFO Approvers */}
            <ApproversTable
              formData={formData}
              setFormData={setFormData}
              heading='RCFO Approvers'
              modalHeading={
                <>
                  <p className='font-medium text-xl mb-4'>Modify RCFO Approver</p>
                  <hr></hr>
                </>
              }
              fieldKey={'rcfoApprovers'}
              role='RCFO'
              approvalbucket='LLA Digital Steer Co Approval'
              modalType={undefined}
            ></ApproversTable>

            {/* BU Steer Co Representatives  */}
            <ApproversTable
              formData={formData}
              setFormData={setFormData}
              heading='Steer Co Approvers'
              modalHeading={
                <>
                  <p className='font-medium text-xl mb-4'>Modify Steer Co Approvers</p>
                  <hr></hr>
                </>
              }
              fieldKey={'steercoApprovers'}
              role='BU Steer Co'
              approvalbucket='LLA Digital Steer Co Approval'
              modalType='steerco'
            ></ApproversTable>

          </div>
        }

      ></PageComponent> 
    )
}
