//================================================================
//  Application: Digital Business Improvement Portal
//================================================================

//  Product Owner: Gary Searle
//  Created 22/11/23

//================================================================

//Libraries
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts
import { SetAppSize } from './Library/GlobalContexts.js';

//Pages
import NotFound from './Pages/NotFound/NotFound.js';
import BusinessRequestForm from './Pages/BusinessRequestForm/BusinessRequestForm.js';
import BusinessRequests from './Pages/BusinessRequests/BusinessRequests.js';
import BusinessRequestsId from './Pages/BusinessRequests/Pages/BusinessRequestsId';
import TrackRequests from './Pages/TrackRequests/TrackRequests.js'
import TrackRequestsId from './Pages/TrackRequests/Pages/TrackRequestId.js'
import ICTAssessments from './Pages/ICTAssessments/ICTAssessments.js';
import ICTAssessmentDetail from './Pages/ICTAssessments/Pages/ICTAssessmentDetail.js';
import ApprovalCenter from './Pages/ApprovalCenter/ApprovalCenter.js';
import ApprovalPage from './Pages/ApprovalCenter/Pages/ApprovalPage.js';
import Approvers from './Pages/Approvers/Approvers.js';
import Dashboard from './Pages/Dashboard/Dashboard.js';
import ICTAssessmentForm from './Pages/ICTAssessmentForm/ICTAssessmentForm.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setAppSize = useContext(SetAppSize);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
  
    function handleResize(e){

      setAppSize({
        'width': e.currentTarget.innerWidth,
        'height': e.currentTarget.innerHeight
      });

    };

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    // 1. Setup an event listener to get width and height of app
    useEffect(() => {

      setAppSize({
        'width': window.innerWidth,
        'height': window.innerHeight
      });

      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)
    
      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>
       
        <Routes>
          <Route path='/' element={<BusinessRequestForm />} />
          <Route path='/home' element={<BusinessRequestForm />} />
          <Route path='/trackrequests' element={<TrackRequests />} />
          <Route path='/trackrequests/id' element={<TrackRequestsId />} />
          <Route path='/businessrequests' element={<BusinessRequests />} />
          <Route path='/businessrequests/id' element={<BusinessRequestsId />} />
          <Route path='/ictassessments' element={<ICTAssessments />} />
          <Route path='/ictassessments/id' element={<ICTAssessmentDetail />} />
          <Route path='/approvals' element={<ApprovalCenter />} />
          <Route path='/approvals/id' element={<ApprovalPage />} />
          <Route path='/approvers' element={<Approvers />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/ictassessmentform' element={<ICTAssessmentForm />} />

          <Route path='*' element={<NotFound />}></Route>
        </Routes>

        <Footer></Footer>

      </div>

    </div>
  )

  //------------------------------------------------------
}