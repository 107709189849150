//================================================================
//  Component: Status Label
//================================================================

//  Purpose: This is a label component displaying the status of a business request

//  Properties:
//    - status = {A string, this contains the business request status}

//  StatusLabel:
//    <StatusLabel
//      status={request}
//    ></StatusLabel>    

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

//CSS
import './StatusLabel.css';


export default function StatusLabel({
  status
}) { 

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div>
        {
            [
                {'name': 'Awaiting Line Manager Approval', 'background': '#00A7A4', 'color': 'white', 'bordercolor': '#00A7A4', 'status': 'Awaiting Line Manager Approval'}, 
                {'name': 'Awaiting Finance Manager Approval', 'background': '#E86428', 'color': 'white', 'bordercolor': '#E86428', 'status': 'Awaiting Finance Manager Approval'},

                {'name': 'BU Approved', 'background': '#048257', 'color': 'white', 'bordercolor': '#048257', 'status': 'BU Approved'},  

                {'name': 'Awaiting BU Approval', 'background': '#FFEAC0', 'color': 'white', 'bordercolor': '#8F6200', 'status': 'Awaiting BU Approval'}, 
                
                {'name': 'Awaiting IT Approval', 'background': '#15BBC7', 'color': 'white', 'bordercolor': '#15BBC7', 'status': 'Awaiting IT Approval'},
 
                {'name': 'Awaiting DPM Approval', 'background': '#0073BA', 'color': 'white', 'bordercolor': '#0073BA', 'status': 'Awaiting DPM Approval'},
                {'name': 'Awaiting LLA Digital Steer Co Approval', 'background': '#5d258f', 'color': 'white', 'bordercolor': '#5d258f', 'status': 'Awaiting LLA Digital Steer Co Approval'}, 

                {'name': 'Awaiting Approval', 'background': '#DC9A03', 'color': 'white', 'bordercolor': '#DC9A03', 'status': 'Awaiting Approval'}, 
                
                {'name': 'Approved', 'background': '#3C7D0F', 'color': 'white', 'bordercolor': '#3C7D0F', 'status': 'Approved'}, 
                {'name': 'Rejected', 'background': '#C4314B', 'color': 'white', 'bordercolor': '#C4314B', 'status': 'Rejected'},

                {'name': 'Retriggered Approval', 'background': '#166ADE', 'color': 'white', 'bordercolor': '#166ADE', 'status': 'Retrigger-Approval'},
                {'name': 'Not Applicable', 'background': '#616161', 'color': 'white', 'bordercolor': '#616161', 'status': 'Not Applicable'}

            ].filter((style) => style.status === status).map((object, index) => (
                <label key={index} style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}}  className='Status-Label'>
                    {object.name}
                </label>
            ))
        }
    </div>
  )
}
