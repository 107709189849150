//================================================================
//  Component: Requests Dashboard 
//================================================================
 
//  Purpose: This is the Digital Business Requests Dashboard

//================================================================

//Libraries
import React, { useContext, useState, useEffect ,useReducer } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, CategoryScale, LinearScale, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import CollectionListener from '../../../Library/CollectionListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images

// CSS
import '../Dashboard.css';

// Register Elements for Charts
ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement, ChartDataLabels);

export default function Requests() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store dashboard data
    const [dashboardData, setDashboardData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
          // Original Data
          'businessrequests': [],
          'approvals': [],
          'approvers': [],

          // Chart Data
          'requestspendingapprovalpie': {},
          'requestspendingapprovalpieoptions': {
            responsive: true,
            plugins: {
              datalabels: {
                anchor: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: 2,
                color: 'white',
                textAlign: 'center',
                font: {
                  size: 11,
                },
                formatter: function (value, context) {
                  if (context.chart.data.datasets[0].data[context.dataIndex] === 0) {
                    return null
                  }
                  else {
                    return context.chart.data.labels[context.dataIndex];
                  }
                  
                },
              }
            },
          },
          'requestsperbu': {},
          'requestsperbuoptions': {
            responsive: true,
            plugins: {
              datalabels: {
                display: false
              }
            },
            scales: {
              x: {
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0
                  }
              }
            }
          },
          'requestspendingapprovallist': [],
          'requestspermonth': {},
          'requestspermonthoptions': {
            responsive: true,
            plugins: {
              datalabels: {
                display: false
              }
            },
            scales: {
              x: {
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0
                  }
              }
            }
          },
          'activeapprovals': '',
          'rejectedrequests': [],
          'rejectedapprovals': [],
          'totalrejectedrequests': '',
          'totalapprovedrequests': '',
          'averagedurationbyapprovaltype': {},
          'averagedurationbyapprovaltypeoptions': {
            responsive: true,
            plugins: {
              datalabels: {
                display: false
              }
            },
            scales: {
              x: {
                  ticks: {
                    maxRotation: 0,
                    minRotation: 0
                  }
              }
            }
          }
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function getLastTwelveMonths() {
      var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      var today = new Date();
      today.setDate(1); // Start from the first day of the current month
      var months = [];
      for (var i = 0; i <= 11; i++) {
          var month = new Date(today.getTime());
          month.setMonth(today.getMonth() - i);
          months.push(monthNames[month.getMonth()] + ' ' + month.getFullYear());
      }
      return months;
  }
 
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Collection Listener > 'Business Requests'
    useEffect(()=>{
    
        if (getUser === undefined) return;
      
        function onLoad(document){

          // ----- Filter Data by Approval Status ----- //
          const awaitingLineManagerApproval = document.filter((request) => request?.status === 'Awaiting Line Manager Approval').length;
          const awaitingFinanceManagerApproval = document.filter((request) => request?.status === 'Awaiting Finance Manager Approval').length;
          const buApproved = document.filter((request) => request?.status === 'BU Approved').length;
          const awaitingITApproval = document.filter((request) => request?.status === 'Awaiting IT Approval').length;
          const awaitingDPMApproval = document.filter((request) => request?.status === 'Awaiting DPM Approval').length;
          const awaitingSteerCoApproval = document.filter((request) => request?.status === 'Awaiting LLA Digital Steer Co Approval').length;
          const approved = document.filter((request) => request?.status === 'Approved').length;
          const rejected = document.filter((request) => request?.status === 'Rejected').length;

          // Total Pending and Rejected Approvals
          const activeApprovals = awaitingLineManagerApproval + awaitingFinanceManagerApproval + buApproved + awaitingITApproval + awaitingDPMApproval + awaitingSteerCoApproval;
          const rejectedRequests = document.filter((request) => request?.status === 'Rejected');

          // ----- Requests Pending Approval (Pie Chart) ----- //
          const requestsPendingApprovalPieData = {
            labels: [ 
              ['Awaiting', 'Line Manager Approval'],
              ['Awaiting', 'Finance Manager Approval'],
              ['BU Approved'],
              ['Awaiting', 'IT Approval'],
              ['Awaiting', 'DPM Approval'],
              ['Awaiting LLA Digital', 'Steer Co Approval'],
              ['Approved'],
              ['Rejected']
            ],
            datasets: [
              {
                data: [awaitingLineManagerApproval, awaitingFinanceManagerApproval, buApproved, awaitingITApproval, awaitingDPMApproval, awaitingSteerCoApproval, approved, rejected],
                backgroundColor: ['#016866', '#287a78','#428c8a','#589f9d','#6fb2b0','#85c6c3','#9bdad7','#b2eeeb']
              },
            ],
          }

          // ----- Number of Requests Per Month ----- //
          const lastTwelveMonths = getLastTwelveMonths().reverse();
          const formattedLastTwelveMonths = [];
          lastTwelveMonths.forEach(month => {
            let monthLabel = `${month.split(' ')[0].slice(0, 3)} ${month.split(' ')[1].slice(-2)}`
            formattedLastTwelveMonths.push(monthLabel)
          })
          let noOfRequestsPerMonth= {
            labels: formattedLastTwelveMonths,
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          lastTwelveMonths.forEach(month => {

            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const monthlyTotal = document.filter((request) => monthNames[request.created.toDate().getMonth()] === month.split(' ')[0]).length
            
            noOfRequestsPerMonth.datasets[0].data.push(monthlyTotal)

          })
          
          // ----- Requests Per Business Unit ----- //

          // - Loop through business units and collect totals
          let listOfBusinessUnits = [...new Set(document?.map(item => item.primarybeneficiary))]
          
          // Wrap business unit labels
          let listOfBusinessUnitsWrapped = []
          listOfBusinessUnits.forEach(businessunit => {
            let buWords = businessunit.split(' ')
            listOfBusinessUnitsWrapped.push(buWords)
          });
          
          // Prepare requests per business unit data
          let requestsPerBUData= {
            labels: listOfBusinessUnitsWrapped,
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          // Update requests per business unit totals
          listOfBusinessUnits.forEach(businessunit => {
            const totalRequests = document.filter((request) => request?.primarybeneficiary === businessunit).length
            requestsPerBUData.datasets[0].data.push(totalRequests)
          });

          // ----- Update Dashboard Data ----- //
          setDashboardData({ 
            'businessrequests': document,
            'requestspendingapprovalpie': requestsPendingApprovalPieData,
            'activeapprovals': activeApprovals,
            'totalapprovedrequests': approved,
            'rejectedrequests': rejectedRequests,
            'totalrejectedrequests': rejected,
            'requestsperbu': requestsPerBUData,
            'requestspermonth': noOfRequestsPerMonth
          });
        
          setPageStatus('onload');
  
        }
        
        function onChange(document){

          // ----- Filter Data by Approval Status ----- //
          const awaitingLineManagerApproval = document.filter((request) => request?.status === 'Awaiting Line Manager Approval').length;
          const awaitingFinanceManagerApproval = document.filter((request) => request?.status === 'Awaiting Finance Manager Approval').length;
          const buApproved = document.filter((request) => request?.status === 'BU Approved').length;
          const awaitingITApproval = document.filter((request) => request?.status === 'Awaiting IT Approval').length;
          const awaitingDPMApproval = document.filter((request) => request?.status === 'Awaiting DPM Approval').length;
          const awaitingSteerCoApproval = document.filter((request) => request?.status === 'Awaiting LLA Digital Steer Co Approval').length;
          const approved = document.filter((request) => request?.status === 'Approved').length;
          const rejected = document.filter((request) => request?.status === 'Rejected').length;

          // Total Pending and Rejected Approvals
          const activeApprovals = awaitingLineManagerApproval + awaitingFinanceManagerApproval + buApproved + awaitingITApproval + awaitingDPMApproval + awaitingSteerCoApproval;
          const rejectedRequests = document.filter((request) => request?.status === 'Rejected');

          // ----- Requests Pending Approval (Pie Chart) ----- //
          const requestsPendingApprovalPieData = {
            labels: [ 
              ['Awaiting', 'Line Manager Approval'],
              ['Awaiting', 'Finance Manager Approval'],
              ['BU Approved'],
              ['Awaiting', 'IT Approval'],
              ['Awaiting', 'DPM Approval'],
              ['Awaiting LLA Digital', 'Steer Co Approval'],
              ['Approved'],
              ['Rejected']
            ],
            datasets: [
              {
                data: [awaitingLineManagerApproval, awaitingFinanceManagerApproval, buApproved, awaitingITApproval, awaitingDPMApproval, awaitingSteerCoApproval, approved, rejected],
                backgroundColor: ['#016866', '#287a78','#428c8a','#589f9d','#6fb2b0','#85c6c3','#9bdad7','#b2eeeb']
              },
            ],
          }

          // ----- Number of Requests Per Month ----- //
          const lastTwelveMonths = getLastTwelveMonths().reverse();
          const formattedLastTwelveMonths = [];
          lastTwelveMonths.forEach(month => {
            let monthLabel = `${month.split(' ')[0].slice(0, 3)} ${month.split(' ')[1].slice(-2)}`
            formattedLastTwelveMonths.push(monthLabel)
          })
          let noOfRequestsPerMonth= {
            labels: formattedLastTwelveMonths,
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          lastTwelveMonths.forEach(month => {

            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const monthlyTotal = document.filter((request) => monthNames[request.created.toDate().getMonth()] === month.split(' ')[0]).length
            
            noOfRequestsPerMonth.datasets[0].data.push(monthlyTotal)

          })
          
          // ----- Requests Per Business Unit ----- //

          // - Loop through business units and collect totals
          let listOfBusinessUnits = [...new Set(document?.map(item => item.primarybeneficiary))]
          
          // Wrap business unit labels
          let listOfBusinessUnitsWrapped = []
          listOfBusinessUnits.forEach(businessunit => {
            let buWords = businessunit.split(' ')
            listOfBusinessUnitsWrapped.push(buWords)
          });
          
          // Prepare requests per business unit data
          let requestsPerBUData= {
            labels: listOfBusinessUnitsWrapped,
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          // Update requests per business unit totals
          listOfBusinessUnits.forEach(businessunit => {
            const totalRequests = document.filter((request) => request?.primarybeneficiary === businessunit).length
            requestsPerBUData.datasets[0].data.push(totalRequests)
          });

          // ----- Update Dashboard Data ----- //
          setDashboardData({ 
            'businessrequests': document,
            'requestspendingapprovalpie': requestsPendingApprovalPieData,
            'activeapprovals': activeApprovals,
            'totalapprovedrequests': approved,
            'rejectedrequests': rejectedRequests,
            'totalrejectedrequests': rejected,
            'requestsperbu': requestsPerBUData,
            'requestspermonth': noOfRequestsPerMonth
          });
        
          setPageStatus('onload');
  
        }
        
        function onError(){
          setPageStatus('error-fatal');
        }
        
        const unsubscribe = CollectionListener('businessrequests', onLoad, onChange, onError)
        
        return () =>{
          unsubscribe();
        };
        
      // eslint-disable-next-line
    }, [getUser]);

    // Collection Listener > 'Approvals'
    useEffect(()=>{
    
        if (getUser === undefined) return;
      
        function onLoad(document){

          // Get all rejected approval items 
          const rejectedApprovals = document.filter((request) => request?.status === 'Rejected');

          // Get all approved requests and calculate approval duration
          const approved = document.filter((request) => request?.status === 'Approved' && request?.approvaldate !== '');
                    
          // Average Duration of Approvals
          const averageDurationByApprovalType = {
            labels: [
              ['Line Manager', 'Approval'],
              ['Finance Manager', 'Approval'],
              ['IT', 'Approval'],
              ['DPM', 'Approval'],
              ['LLA Digital', 'Steer Co', 'Approval']
            ],
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          // Loop through approval stages > Collect totals
          const listOfStages = ['Line Manager Approval', 'Finance Manager Approval', 'IT Approval', 'DPM Approval', 'LLA Digital Steer Co Approval']
          listOfStages.forEach((stage) => {
            
            const items = approved.filter((approval) => approval.stage === stage);
            let totalDuration = 0;

            items.forEach((item) => {
              const diffSeconds = Math.abs(item.approvaldate - item.created);
              const diffHours = diffSeconds / 60 / 60 / 24;
              totalDuration += diffHours;

            })

            averageDurationByApprovalType.datasets[0].data.push(totalDuration/items.length)

          })


          setDashboardData({ 
            'approvals': document,
            'requestspendingapprovallist': document.filter((approval) => approval?.status === 'Awaiting Approval'),
            'averagedurationbyapprovaltype': averageDurationByApprovalType,
            'rejectedapprovals': rejectedApprovals
          });

          setPageStatus('onload');
  
        }
        
        function onChange(document){

          // Get all rejected approval items 
          const rejectedApprovals = document.filter((request) => request?.status === 'Rejected');
          
          // Get all approved requests and calculate approval duration
          const approved = document.filter((request) => request?.status === 'Approved' && request?.approvaldate !== '');
                    
          // Average Duration of Approvals
          const averageDurationByApprovalType = {
            labels: [
              ['Line Manager', 'Approval'],
              ['Finance Manager', 'Approval'],
              ['IT', 'Approval'],
              ['DPM', 'Approval'],
              ['LLA Digital', 'Steer Co', 'Approval']
            ],
            datasets: [
              {
                label: 'Total Requests',
                data: [],
                backgroundColor: '#007A78',
                barThickness: 30
              },
            ],
          }

          // Loop through approval stages > Collect totals
          const listOfStages = ['Line Manager Approval', 'Finance Manager Approval', 'IT Approval', 'DPM Approval', 'LLA Digital Steer Co Approval']
          listOfStages.forEach((stage) => {

            const items = approved.filter((approval) => approval.stage === stage)
            let totalDuration = 0;

            items.forEach((item) => {
              const diffSeconds = Math.abs(item.approvaldate - item.created);
              const diffHours = diffSeconds / 60 / 60 / 24;
              totalDuration += diffHours;

            })

            averageDurationByApprovalType.datasets[0].data.push(totalDuration/items.length)

          })


          setDashboardData({ 
            'approvals': document,
            'requestspendingapprovallist': document.filter((approval) => approval?.status === 'Awaiting Approval'),
            'averagedurationbyapprovaltype': averageDurationByApprovalType,
            'rejectedapprovals': rejectedApprovals
          });

          setPageStatus('onload');
  
        }
        
        function onError(){
          setPageStatus('error-fatal');
        }
        
        const unsubscribe = CollectionListener('approvals', onLoad, onChange, onError)
        
        return () =>{
          unsubscribe();
        };
        
      // eslint-disable-next-line
    }, [getUser]);

    // Collection Listener > 'Approvers'
    useEffect(()=>{
    
        if (getUser === undefined) return;
      
        function onLoad(document){

          // Sort in alphabetical order by Approval Bucket 
          document.sort(function(a, b) {
            var textA = a.approvalbucket.toUpperCase();
            var textB = b.approvalbucket.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });
          
          setDashboardData({ 'approvers': document });
          setPageStatus('onload');
  
        }
        
        function onChange(document){

          // Sort in alphabetical order by Approval Bucket 
          document.sort(function(a, b) {
            var textA = a.approvalbucket.toUpperCase();
            var textB = b.approvalbucket.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          setDashboardData({ 'approvers': document });
          setPageStatus('onload');
  
        }
        
        function onError(){
          setPageStatus('error-fatal');
        }
        
        const unsubscribe = CollectionListener('approvers', onLoad, onChange, onError)
        
        return () =>{
          unsubscribe();
        };
        
      // eslint-disable-next-line
    }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Error Fatal
    //------------------------------------------------------
    
    if (pageStatus === 'error-fatal') {
      return (
        <div className='bg-[#FAFAFA] mb-[2%] p-[4%] rounded-[5px] text-center'>
          <h2 className='mb-[15px]'>Oops something went wrong</h2>
          <p className='leading-[1.7]'>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the error message below. 
          </p>
          <p className='leading-[1.7]'> 
            <b>Message:</b> Failed to load the page - Fatal Error.
          </p>
          <button className='Primary-Button' onClick={() => window.location.reload()}> Try Again </button>
        </div>
      )
    }

    //------------------------------------------------------
    //  Onload
    //------------------------------------------------------

    else {
      return (
        <div className='bg-[#016866] m-0 p-[10px] border-solid border-[#d7d7d7] border-1 rounded-none' style={{borderRadius: 'none'}}>
    
          <div className='Requests-Dashboard-Container'>
    
            {/* Requests Pending Approval (Doughnut Chart) */}
            <div className='Requests-Pending-Approval-Pie'>
              <p className='font-medium text-[15px] text-black mb-2'>Requests Per Approval Stage </p>
              {
                Object.keys(dashboardData?.businessrequests).length > 0 ?
                <Doughnut data={dashboardData?.requestspendingapprovalpie} options={dashboardData?.requestspendingapprovalpieoptions} plugins={[ChartDataLabels]}/>
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Number of Requests Per Month (Bar Chart) */}
            <div className='Requests-Per-Day'>
              <p className='font-medium text-[15px] text-black mb-3'>Number of Requests Per Month</p>
              {
                Object.keys(dashboardData?.businessrequests).length > 0 ?
                <Bar data={dashboardData?.requestspermonth} options={dashboardData?.requestspermonthoptions}/>
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Active Approvals (Total) */}
            <div className='Active-Approvals'>
              <div className='flex flex-col justify-center items-center h-[100px]'>
                <h1 className='font-medium text-[60px] my-1'>
                  {dashboardData?.activeapprovals}
                </h1>
                <p>Pending Approvals</p>
              </div>
            </div>
    
            {/* List of Approvers (List) */}
            <div className='Approvers-List'>
              <p className='font-medium text-[15px] text-black mb-3'>List of Approvers</p>
              {
                dashboardData?.approvers.length > 0 ?
                (
                  <table className='text-left w-full'>
                    <thead className='w-full'>
                      <tr className='w-full flex bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='p-[5px] w-1/2 font-medium'>Approval Bucket</th>
                        <th className='p-[5px] w-1/2 font-medium'>Email Address</th>
                      </tr>
                    </thead>
                    <tbody className='bg-grey-light flex flex-col items-center justify-between overflow-y-scroll overflow-x-hidden w-full' style={{height: '250px'}}>
                      {
                        dashboardData?.approvers?.map((approver, index) => (
                          <tr key={index} className='flex w-full'>
                            <td className='p-[5px] w-1/2'>{approver?.approvalbucket}</td>
                            <td className='p-[5px] w-1/2 break-all'>{approver?.emailaddress}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                )
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Average Duration for Approval by Type (Bar Chart)*/}
            <div className='Average-Duration-Approval-Type w-full'>
              <p className='font-medium text-[15px] text-black mb-3'>Average Duration for Approval by Type (Days)</p>
              {
                dashboardData?.approvals.length > 0 ?
                <Bar data={dashboardData?.averagedurationbyapprovaltype} options={dashboardData?.averagedurationbyapprovaltypeoptions}/>
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Total Rejected Requests (Total)*/}
            <div className='Total-Rejected-Requests'>
              <div className='flex flex-col justify-center items-center h-[100px]'>
                <h1 className='font-medium text-[60px] my-1'>
                  {dashboardData.totalrejectedrequests}
                </h1>
                <p>Rejected Requests</p>
              </div>
            </div>
    
            {/* Total Approved Requests (Total)*/}
            <div className='Total-Approved-Requests'>
              <div className='flex flex-col justify-center items-center h-[100px]'>
                <h1 className='font-medium text-[60px] my-1'>
                  {dashboardData.totalapprovedrequests}
                </h1>
                <p>Approved Requests</p>
              </div>
            </div>
    
            {/* Requests Submitted per Business Unit (Bar Chart) */}
            <div className='Requests-Business-Unit'>
              <p className='font-medium text-[15px] text-black mb-3'>Requests Submitted per Business Unit</p>
              {
                dashboardData?.businessrequests.length > 0 ?
                <Bar data={dashboardData?.requestsperbu} options={dashboardData?.requestsperbuoptions}/>
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Requests Pending Approval (List) */}
            <div className='Requests-Pending-Approval-List'>
              <p className='font-medium text-[15px] text-black mb-2'>Requests Awaiting Approval</p>
              {
                dashboardData?.requestspendingapprovallist.length > 0 ?
                (
                  <table className='text-left w-full'>
                    <thead>
                      <tr className='bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='pl-[10px] p-[5px] font-medium'>Initiative Name</th>
                        <th className='p-[5px] font-medium'>Stage</th>
                        <th className='p-[5px] font-medium'>Approver</th>
                        <th className='p-[5px] font-medium'>Created</th>
     
                      </tr>
                    </thead>
                    <tbody className='bg-grey-light overflow-y-scroll overflow-x-hidden w-full h-[200px]' style={{height: '100px'}}>
                      {
                        dashboardData?.requestspendingapprovallist?.map((approval, index) => (
                          <tr key={index}>
                            <td className='pl-[10px] p-[5px]'>{approval?.requestname}</td>
                            <td className='p-[5px]'>{approval?.stage}</td>
                            <td className='p-[5px] break-all'>{approval?.approver.length > 0 ? approval?.approver : '-'}</td>
                            <td className='p-[5px]'>{ConvertDate(approval?.created)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                )
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
            {/* Recently Rejected Requests (List) */}
            <div className='Rejected-Requests'>
              <p className='font-medium text-[15px] text-black mb-3'>Recently Rejected Requests</p>
              {
                dashboardData?.rejectedapprovals.length > 0 ?
                (
                  <table className='text-left w-full'>
                    <thead>
                      <tr className='bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='p-[5px] font-medium'>Initiative Name</th>
                        <th className='p-[5px] font-medium'>Stage</th>
                        <th className='p-[5px] font-medium'>Approver</th>
                        <th className='p-[5px] font-medium'>Rejection Date</th>
                        <th className='p-[5px] font-medium'>Comments</th>
                      </tr>
                    </thead>
                    <tbody className='overflow-y-scroll overflow-x-hidden' style={{maxHeight: '250px'}}>
                      {
                        dashboardData?.rejectedapprovals?.map((approval, index) => (
                          <tr key={index}>
                            <td className='p-[5px]'>{approval?.requestname}</td>
                            <td className='p-[5px]'>{approval?.stage}</td>
                            <td className='p-[5px] break-all'>{approval?.approver.length > 0 ? approval?.approver : '-'}</td>
                            <td className='p-[5px]'>{ConvertDate(approval?.modified)}</td>
                            <td className='p-[5px]'>
                              {
                                approval?.comments.length > 32 ? approval?.comments.substring(0,32) + '...' : approval?.comments
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                )
                :
                <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>No data found</p>
              }
            </div>
    
          </div>
    
        </div>
      )
    }

}