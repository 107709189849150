//================================================================
//  Page: Approval Center
//================================================================

//  Purpose: This is the approval center for all approvers

//================================================================


//Libraries
import React, { useState, useContext, useReducer, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts
import { GetUser, GetAppSize } from '../../Library/GlobalContexts';

//Functions
import QueryListener from '../../Library/QueryListener';
import getDocument from '../../Library/GetDocument';
import convertDate from '../../Library/ConvertDate';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import StatusLabel from '../../Components/StatusLabel/StatusLabel';

//Images
import Link from '../../Components/Images/Icon_Link_Black.svg';

//CSS
import './ApprovalCenter.css';


export default function ApprovalCenter() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getAppSize = useContext(GetAppSize);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store form inputs
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'pendingApprovals': [],
        'completedApprovals': [],

        // Approval Modal States
        'document': undefined,    
        'readOnly': false,    
        'isOpen': false,
        'reason': '',
        'error': '',  
        
        // Not Found Modal States
        'notFoundIsOpen': false,
                        
      }
    );

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handle the hidden CSS property based off app width   
    function isHidden(){

      if (getAppSize.width < 700) {

        return true;

      }

      return false;

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onLoad
    //  1. Check for query string that contains a valid firestore doc
    useEffect(() => {

      const approvalId = searchParams.get('approvalid');

      if (approvalId === '') return;
      if (approvalId === null) return;

      getDocument('approvals', approvalId).then((document) => {

        // If already submitted --> 'readOnly' the modal
        if (document.status.includes('Awaiting') === false) {

          return setFormData({
            'readOnly': true,
            'isOpen': true,
            'document': document,
          });

        }

        // Show the modal
        setFormData({
          'isOpen': true,
          'document': document,
          'readOnly': false,
        });

      }).catch(() => {

        // No approval doc exists --> show the 'something went wrong' modal
        setFormData({
          'notFoundIsOpen': true,
          'isOpen': false,
        });

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);
  

    // onLoad
    //  1. Find all pending & complete approvals
    useEffect(() => {

      if (pageStatus !== 'pending') return;
      if (getUser.emailaddress === undefined) return;
  
      function onLoadChange(documents){
  
        setFormData({
          'pendingApprovals': documents.filter((doc) => doc.status.includes('Awaiting')).reverse(),
          'completedApprovals': documents.filter((doc) => !doc.status.includes('Awaiting')),
        });

      };

      function onError(error) {

        console.log('Error', error)
        setPageStatus('error-fatal');

      };

      const unsubscribe = QueryListener('approvals', [
        ['approver', '==', getUser.emailaddress] 
      ], onLoadChange, onLoadChange, onError, ['created', 'desc']);

      setPageStatus('onload');

      return () => {

        unsubscribe();
      
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>

            {/* Heading */}
            <div className='flex flex-col w-fit-content'>
                <p className='text-[20px] font-medium m-0 p-0 text-wrap'>Approval Center</p>
                <p className='mt-2 text-base text-wrap m-0'>
                  Below is a list of all of the business requests currently pending your approval. Click on the item to view the full submission.
                </p>
            </div>

          </div>
        }
        requiredRoles={ ['isLLUser'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='flex flex-col gap-2 [@media(max-width:450px)]:p-[4%]'>

            {/* ------ My Approvals ------ */}
            <div>
              <p className='font-medium text-[18px] text-[#006866] my-2'>My Approvals</p>
              <hr className='my-3'></hr>
            </div>

            {/* - Desktop Version */}
            <div className='Table-Container [@media(max-width:450px)]:hidden'>

              <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                  <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}></th>
                    <th className='py-[15px] px-[10px] font-medium'>Name</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Business Owner</th>
                    <th className='py-[15px] px-[10px] font-medium'>Role</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Business Unit</th>
                    <th className='py-[15px] px-[10px] font-medium'>Stage</th>
                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Modified</th>
                  </tr>
                </thead>
             
                <tbody>
                  {

                    // No Results
                    formData.pendingApprovals.length === 0 ? 
                    (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-[2%]'>
                            No results found.
                          </div>
                        </td>
                      </tr>
                    )
                    :
                    (

                      // Results
                      formData.pendingApprovals.map((row, index) => (
                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/approvals/id?approvalid=${row?.approvalid}`)}>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}></td>
                          <td className='py-[15px] px-[10px]'>{row?.requestname}</td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{row?.owner}</td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{row?.role.length > 0 ? row?.role : '-'}</td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{row?.primarybeneficiary}</td>
                          <td className='py-[15px] px-[10px]'>{row?.stage}</td>
                          <td className='py-[15px] px-[10px]'>
                            <StatusLabel status={row?.status}></StatusLabel>
                          </td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{convertDate(row?.modified)}</td>
                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>

            </div>

            {/* - Mobile Version */}
            <div className='hidden [@media(max-width:450px)]:block'>
              {
                // No Results
                formData.pendingApprovals.length === 0 ? 
                (
                    <div className='Table-Container text-center p-4'>
                      No results found.
                    </div>
                )
                :
                (

                  // Results
                  formData.pendingApprovals.map((row, index) => (
                    <div key={index} className='Table-Container flex flex-col gap-2 p-3 mb-2 cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/approvals/id?approvalid=${row?.approvalid}`)}>

                      {/* Name */}
                      <div className='flex flex-row gap-2'>
                        <p className='text-base font-medium m-0 underline'>{row?.requestname}</p>
                        <img src={Link} alt='Link'></img>
                      </div>

                      {/* Business Owner */}
                      <p className='m-0 text-neutral-600'>{row?.owner}</p>

                      {/* Stage */}
                      <p className='m-0'>{row?.stage}</p>

                      {/* Status */}
                      <StatusLabel status={row?.status}></StatusLabel>

                    </div>
                  ))
                )
              }
            </div>

            {/* -------- History -------- */}
            <div className='mt-4'>
              <p className='font-medium text-[18px] text-[#006866] my-2'>History</p>
              <hr className='my-3'></hr>
            </div>
              
            {/* - Desktop Version */}
            <div className='Table-Container  [@media(max-width:450px)]:hidden'>

              <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                  <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}></th>
                    <th className='py-[15px] px-[10px] font-medium'>Name</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Business Owner</th>
                    <th className='py-[15px] px-[10px] font-medium'>Role</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Business Unit</th>
                    <th className='py-[15px] px-[10px] font-medium'>Stage</th>
                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                    <th className='py-[15px] px-[10px] font-medium' hidden={isHidden()}>Modified</th>
                  </tr>
                </thead>
                <tbody>
                  {

                    // No Results
                    formData.completedApprovals.length === 0 ? 
                    (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-[2%]'>
                            No results found.
                          </div>
                        </td>
                      </tr>
                    )
                    : 
                    (

                      // Results
                      formData.completedApprovals.map((row, index) => (

                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/approvals/id?approvalid=${row?.approvalid}`)}>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}></td>
                          <td className='py-[15px] px-[10px]'>{row?.requestname}</td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{row?.owner}</td>
                          <td className='py-[15px] px-[10px]'>{row?.role.length > 0 ? row?.role : '-'}</td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{row?.primarybeneficiary}</td>
                          <td className='py-[15px] px-[10px]'>{row?.stage}</td>
                          <td className='py-[15px] px-[10px]'>
                            <StatusLabel status={row?.status}></StatusLabel>
                          </td>
                          <td className='py-[15px] px-[10px]' hidden={isHidden()}>{convertDate(row?.modified)}</td>
                        </tr>
                        
                      ))
                    )
                  }
                </tbody>
              </table>

            </div>  

            {/* - Mobile Version */}
            <div className='hidden [@media(max-width:450px)]:block [@media(max-width:450px)]:mb-[10%]'>
              {
                // No Results
                formData.completedApprovals.length === 0 ? 
                (
                    <div className='Table-Container text-center p-4'>
                      No results found.
                    </div>
                )
                :
                (

                  // Results
                  formData.completedApprovals.map((row, index) => (
                    <div key={index} className='Table-Container flex flex-col gap-2 p-3 mb-2 cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/approvals/id?approvalid=${row?.approvalid}`)}>

                      {/* Name */}
                      <div className='flex flex-row gap-2'>
                        <p className='text-base font-medium m-0 underline'>{row?.requestname}</p>
                        <img src={Link} alt='Link'></img>
                      </div>

                      {/* Business Owner */}
                      <p className='m-0 text-neutral-600'>{row?.owner}</p>

                      {/* Stage */}
                      <p className='m-0'>{row?.stage}</p>

                      {/* Status */}
                      <StatusLabel status={row?.status}></StatusLabel>

                    </div>
                  ))
                )
              }
            </div>

          </div>
        }
      ></PageComponent> 
    )
}
