//================================================================
//  Component: Navigation Item
//================================================================

//  Purpose: This is the standard for adding a navigation link to the nav bar

//  Properties:
//    - style = <String> - CSS class > determines if the link is active/inactive
//    - link = <String> - url/route
//    - title = <String> - display name for navigation link
//    - roles = [Array] - list of roles required to view this navigation item

//  Example:
//    <NavItem 
//      style={changeStyleHandler('/projects')} 
//      link={'/projects'} 
//      title={'Projects'} 
//      roles={['portalAdmin','portalUser']}
//    ></NavItem>

//================================================================


//Libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Functions
import CheckUserRole from '../../Library/checkUserRole';


export default function NavItem({
  style,
  link, 
  title,
  roles
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  Check Role Service
  //------------------------------------------------------

    //Get the user's roles 
    const userRoles = getUser?.roles;

    //Check if the current item has the required role
    const roleCheck = CheckUserRole(roles, userRoles, true);

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
 
    // Check user role > display nav link
    if(roleCheck === true || roles === undefined)
      return (
        <Link className={style} to={link}>
          <div className='no-underline px-2'>
            {title}
          </div>
        </Link>
      )
  ;

  //------------------------------------------------------
}