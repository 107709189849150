//================================================================
//  Component: Approval Page
//================================================================

//  Purpose: This is a child page of 'ApprovalCenter.js'

//  Example:
//  <ApprovalPage></ApprovalPage>

//================================================================


//Libraries
import React, { useState, useReducer, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import ApprovalModal from '../Components/ApprovalModal';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';

//Functions
import queryDocument from '../../../Library/QueryDocument';
import getDocument from '../../../Library/GetDocument';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import Tick from '../../../Components/Images/Icon_Tick_DarkTeal.svg';

//CSS
import '../ApprovalCenter.css';


export default function ApprovalPage() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'not-found', 'onload',, 'success' 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds the current approval doc from Firestore
    const [approval, setApproval] = useState();

    // Used to store the current business request
    const [businessRequest, setBusinessRequest] = useState();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store form inputs
    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          'pendingApprovals': [],
          'completedApprovals': [],
  
          // Approval Modal States
          'status': '',
          'modalOpen': false,
          'readOnly': false,    
          'reason': '',
          'error': '',
 
        }
    );

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Find the approval document
    //  1. Save to useState & update useReducer
    useEffect(() => {

        const approvalId = searchParams.get('approvalid');

        if (approvalId === '') return;
        if (approvalId === null) return;

        const approvalDocPromise =  queryDocument('approvals', [['approvalid', '==', approvalId]]);
        const businessReqPromise = getDocument('businessrequests', `${approvalId.split('-')[0]}-${approvalId.split('-')[1]}`);

        Promise.all([approvalDocPromise, businessReqPromise])
        .then((results) => {

            let approvalDoc = results[0];
            let businessRequestDoc = results[1];

            if (approvalDoc.length === 0) return setPageStatus('not-found');
            if (!businessRequestDoc) return setPageStatus('not-found');

            setBusinessRequest(businessRequestDoc)
            setApproval(approvalDoc[0]);
            setPageStatus('onload');
            setFormData({'reason': approvalDoc[0].comments})

            if (approvalDoc[0].status === 'Rejected' || approvalDoc[0].status === 'Approved') return setFormData({'readOnly': true});

        }).catch((error) => {

            console.log('error', error);
            setPageStatus('error-fatal');

        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
        header={
            pageStatus !== 'success' &&
            <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
            
                {/* Breadcrumbs */}
                <BreadCrumbs
                    rootLinkName={'Approvals'}                      
                    rootLinkRoute={'/approvals'}                    
                    childLinkName={businessRequest?.initiativename}
                ></BreadCrumbs>    

                {/* Heading */}
                {
                    approval?.status !== 'Awaiting Approval' ?
                    (
                        <div className='flex flex-col w-fit-content'>
                            <p className='text-[20px] font-medium m-0 p-0 text-wrap'>
                                {businessRequest?.initiativename}
                            </p>
                            
                            <p className='mt-2 text-base text-wrap'>
                                This business request was submitted by <b className='capitalize font-medium'>{businessRequest?.createdby.split('@')[0].split('.')[0]} {businessRequest?.createdby.split('@')[0].split('.')[1]}</b> on {businessRequest?.created?.toDate().toLocaleString()}.
                            </p>
        
                            <StatusLabel
                                status={approval?.status}
                            ></StatusLabel>
        
                        </div>
                    )
                    :
                    (
                        <div className='flex flex-col w-fit-content'>
                            <p className='text-[20px] font-medium m-0 p-0 text-wrap'>
                                Review Business Request
                            </p>
                            <p className='mt-2 text-base text-wrap'>
                                This business request was submitted by <b className='capitalize font-medium'>{businessRequest?.createdby.split('@')[0].split('.')[0]} {businessRequest?.createdby.split('@')[0].split('.')[1]}</b> on {businessRequest?.created?.toDate().toLocaleString()}. 
                            </p>
        
                            <StatusLabel
                                status={businessRequest?.status}
                            ></StatusLabel>
        
                        </div>
                    )
                }

            </div>
        }
        requiredRoles={['isApprover']}
        requiredRolesValue={ true }
        status={pageStatus}
        body={
            <div className='flex flex-col gap-3 mb-[2%] [@media(max-width:450px)]:mb-[5%]'>
                
                {/* Approve / Reject */}
                <div className='flex flex-row items-center gap-2 justify-end [@media(max-width:450px)]:pt-[2%]' hidden={approval?.status !== 'Awaiting Approval'}>

                    {/* Buttons */}
                    <div className='flex flex-row gap-2'>
                        <button 
                            className='Primary-Button' 
                            style={{backgroundColor: '#237B4B', border: '1px solid #237B4B', color: 'white'}} 
                            onClick={() => setFormData({
                                'status': 'Approved',
                                'modalOpen': true,
                            })} 
                            disabled={formData.readOnly}
                        >
                            Approve
                        </button>
                        <button 
                            className='Secondary-Button' 
                            style={{backgroundColor: '#C4314B', border: '1px solid #C4314B', color: 'white'}} 
                            onClick={() => setFormData({
                                'status': 'Rejected',
                                'modalOpen': true,
                            })} 
                            disabled={formData.readOnly}
                        >
                            Reject
                        </button>
                    </div>

                </div>

                {/* Approval Summary (Only Visible if Historical Item) */}
                <div hidden={approval?.status === 'Awaiting Approval'}>

                    {/* Summary */}
                    <div className='Page-Section-Container [@media(max-width:450px)]:p-[5%] [@media(max-width:450px)]:mt-4'>
                        <div>
                            <p className='font-medium text-[18px] text-[#006866] my-2'>Approval Summary</p>
                            <hr className='my-3'></hr>
                        </div>
                        <div className='flex flex-column gap-2'>
                            <span><strong className='font-medium'>Stage:</strong>  {approval?.stage} </span>
                            <span><strong className='font-medium'>Approver:</strong>  {approval?.approver}</span>
                            <span><strong className='font-medium'>Last Modified:</strong>  {ConvertDate(approval?.modified)} </span>
                            <span><strong className='font-medium'>Status:</strong>  {approval?.status} </span>
                            <span><strong className='font-medium'>Comments:</strong>  {approval?.comments?.length > 0 ? approval?.comments : 'N/A'} </span>
                        </div>
                    </div>

                </div>

                {/* Business Request Form */}
                <div className='Page-Section-Container [@media(max-width:450px)]:p-[5%]'>
                    
                    {/* Heading */}
                    <div hidden={approval?.status === 'Awaiting Approval'}>
                        <p className='font-medium text-[18px] text-[#006866] my-2'>Business Request</p>
                        <hr className='my-3'></hr>
                    </div>

                    <form className='BusinessRequest-Form [@media(max-width:450px)]:justify-center'>

                        {/* =========================================================== */}
                        {/*  1. What is the name of this initiative / application? *    */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 1. What is the name of this initiative / application? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' type='text' value={businessRequest?.initiativename} disabled></input>
                        </div>
                        
                        {/* =========================================================== */}
                        {/*  2. What is the business or project need? *                 */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 2. What is the business or project need? * </label>
                            <textarea className='Input-Field-TextArea [@media(max-width:450px)]:w-[300px]' name='businessneed' value={businessRequest?.businessneed} disabled></textarea>
                        </div>

                        {/* =========================================================== */}
                        {/*  3. Who is the owner of this initiative / application? *    */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 3. Who is the owner of this initiative / application? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' name='owner' type='text' value={businessRequest?.owner} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  4. Who is the project lead / business lead supporting this initiative / application? *    */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 4. Who is the project lead / business lead supporting this initiative / application? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' name='businesslead' type='text' value={businessRequest?.businesslead} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  5. Who is your line manager? *                                                            */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 5. Who is your line manager? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' name='linemanager' type='text' value={businessRequest?.linemanager} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  6. Who is the finance manager for this request? *                                         */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 6. Who is the finance manager for this request? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' type='text' value={businessRequest?.financemanager} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  7.  Who will be the primary beneficiary of this business improvement solution? *          */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 7. Who will be the primary beneficiary of this business improvement solution? * </label>
                            <select className='Input-Field-Select [@media(max-width:450px)]:w-[300px]' name='primarybeneficiary' value={businessRequest?.primarybeneficiary} style={{ width: '300px'}} disabled>
                                <option hidden value=''>-</option>
                                <option value='Capella'> Capella </option>
                                <option value='Communities'> Communities </option>
                                <option value='Construction'> Construction </option>
                                <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                                <option value='Corporate Real Estate'> Corporate Real Estate </option>
                                <option value='Development'> Development </option>
                                <option value='DOO Office'> DOO Office </option>
                                <option value='EHS'> EHS </option>
                                <option value='Finance'> Finance </option>
                                <option value='Integrated Solutions'> Integrated Solutions </option>
                                <option value='Investment Management'> Investment Management </option>
                                <option value='Legal'> Legal </option>
                                <option value='People & Culture'> People & Culture </option>
                                <option value='RAP'> RAP </option>
                                <option value='Risk and Insurance'> Risk and Insurance </option>
                                <option value='Sustainability'> Sustainability </option>
                            </select>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  8. Who will be the secondary beneficiary(s) for this business improvement solution? *     */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>
                                <b style={{fontWeight: "500"}}>8. Who will be the secondary beneficiary(s) for this business improvement solution? *</b>
                                <p className='text-sm text-slate-600 mx-3 mb-0 mt-2 p-0 [@media(max-width:450px)]:m-0'>To select more than one, press Ctrl and select your options.</p> 
                            </label>
                            <select className='Input-Field-MultiSelect [@media(max-width:450px)]:w-[300px]' name='secondarybeneficiary' value={businessRequest?.secondarybeneficiary} multiple disabled>
                                <option hidden value=''>-</option>
                                <option value='Capella'> Capella </option>
                                <option value='Communities'> Communities </option>
                                <option value='Construction'> Construction </option>
                                <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                                <option value='Corporate Real Estate'> Corporate Real Estate </option>
                                <option value='Development'> Development </option>
                                <option value='DOO Office'> DOO Office </option>
                                <option value='EHS'> EHS </option>
                                <option value='Finance'> Finance </option>
                                <option value='Integrated Solutions'> Integrated Solutions </option>
                                <option value='Investment Management'> Investment Management </option>
                                <option value='Legal'> Legal </option>
                                <option value='People & Culture'> People & Culture </option>
                                <option value='RAP'> RAP </option>
                                <option value='Risk and Insurance'> Risk and Insurance </option>
                                <option value='Sustainability'> Sustainability </option>
                            </select>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  9. Who is the BU DOO supporting this initiative / application? *                          */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 9. Who is the BU DOO supporting this initiative / application? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' name='budoo' type='text' value={businessRequest?.budoo} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  10. What type of request is this? *                                                        */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 10. What type of request is this? * </label>
                            <select className='Input-Field-Select [@media(max-width:450px)]:w-[300px]' name='requesttype' value={businessRequest?.requesttype} disabled>
                                <option hidden value=''>-</option>
                                <option value='New Initiative / Product'> New Initiative / Product </option>
                                <option value='Renewal of an existing product'> Renewal of an existing product </option>xw
                                <option value='Replacing an existing product'> Replacing an existing product </option>
                            </select>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  10a. What is the name of the existing product? *                                           */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row' hidden={businessRequest?.requesttype === 'Replacing an existing product' ? false : true}>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 10a. What is the name of the existing product? * </label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' name='aexistingproduct' type='text' value={businessRequest?.aexistingproduct} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  11. Will this initiative improve one or more of the following? *                           */}
                        {/* ========================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>
                                <b style={{fontWeight: "500"}}>11. Will this initiative improve one or more of the following? *</b>
                                <p className='text-sm text-slate-600 mx-3 mb-0 mt-2 p-0'>To select more than one, press Ctrl and select your options.</p> 
                            </label>
                            <select className='Input-Field-MultiSelect [@media(max-width:450px)]:w-[300px]' name='improvements' value={businessRequest?.improvements} multiple disabled>
                                <option hidden value=''>-</option>
                                <option value='Business efficiency'> Business efficiency </option>
                                <option value='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                                <option value='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                                <option value='ESG requirements or credentials'> ESG requirements or credentials </option>
                                <option value='Investment in our people'> Investment in our people </option>
                                <option value='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                                <option value='Regulatory compliance'> Regulatory compliance </option>
                                <option value='Risk mitigation'> Risk mitigation </option>
                            </select>
                        </div>

                        {/* =================================================================================================== */}
                        {/*  12. Provide a summary of how this initiative will improve the items chosen above *                 */}
                        {/* =================================================================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> 12. Provide a summary of how this initiative will improve the items chosen above * </label>
                            <textarea className='Input-Field-TextArea [@media(max-width:450px)]:w-[300px]' name='summary'  value={businessRequest?.summary} disabled></textarea>
                        </div>

                        {/* =========================================================== */}
                        {/*  13. Does this initiative drive revenue growth? *           */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>13. Does this initiative drive revenue growth? *</label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='revenuegrowth' value='yes' defaultChecked={businessRequest?.revenuegrowth.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>

                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='revenuegrowth' value='no' defaultChecked={businessRequest?.revenuegrowth.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>

                        {/* =========================================================== */}
                        {/*  13a. If yes, when would revenue growth commence?*          */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row' hidden={businessRequest?.revenuegrowth === 'yes' ? false : true}>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>13a. If yes, when would revenue growth commence? *</label>
                            <select className='Input-Field-Select [@media(max-width:450px)]:w-[300px]' value={businessRequest?.arevenuegrowthcommence} disabled>
                                <option hidden value=''>-</option>
                                <option value='Within 6 months'> Within 6 months </option>
                                <option value='6 months to 1 year'> 6 months to 1 year </option>
                                <option value='1 to 2 years'> 1 to 2 years </option>
                                <option value='In more than 2 years'> In more than 2 years </option>
                            </select>
                        </div>

                        {/* =========================================================== */}
                        {/*  14. Does this product accelerate funds growth? *           */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>14. Does this product accelerate funds growth? </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='fundsgrowth' value='yes' defaultChecked={businessRequest?.fundsgrowth.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='fundsgrowth' value='no' defaultChecked={businessRequest?.fundsgrowth.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>

                        {/* =========================================================== */}
                        {/*  14a. If yes, when would funds growth commence? *           */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row' hidden={businessRequest?.fundsgrowth === 'yes' ? false : true}>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>14a. If yes, when would funds growth commence? </label>
                            <select className={'Input-Field-Select'} value={businessRequest?.afundsgrowthcommence} disabled>
                                <option hidden value=''>-</option>
                                <option value='Within 6 months'> Within 6 months </option>
                                <option value='6 months to 1 year'> 6 months to 1 year </option>
                                <option value='1 to 2 years'> 1 to 2 years </option>
                                <option value='In more than 2 years'> In more than 2 years </option>
                            </select>
                        </div>
                        
                        {/* =========================================================== */}
                        {/*  15. What LoA Type is this initiative / application? *      */}
                        {/* =========================================================== */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>15. What LoA Type is this initiative / application? </label>
                            <select className='Input-Field-Select [@media(max-width:450px)]:w-[300px]' value={businessRequest?.loatype} disabled>
                                <option hidden value=''>-</option>
                                <option value='IT Capex'> IT Capex </option>
                                <option value='IT Opex'> IT Opex </option>
                                <option value='Project Based'> Project Based </option>
                            </select>
                        </div>

                        {/* ================================================================ */}
                        {/*  16. What is the anticipated complete cost of this initiative? * */}
                        {/* ================================================================ */}

                        <div className='BusinessRequest-Form-Row'>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'>16. What is the anticipated complete cost of this initiative? </label>
                            <select className='Input-Field-Select [@media(max-width:450px)]:w-[300px]' value={businessRequest?.cost} disabled>
                                <option hidden value=''>-</option>
                                <option value='$0'> $0 </option>
                                <option value='$1 to $10k'> $1 to $10k </option>
                                <option value='$10k to $30k'> $10k to $30k </option>
                                <option value='$30k to $50k'> $30k to $50k </option>
                                <option value='$50k to $100k'> $50k to $100k </option>
                                <option value='$100k to $250k'> $100k to $250k </option>
                                <option value='$250k or more'> $250k or more </option>
                            </select>
                        </div>

                        {/* ================================================================ */}
                        {/*  Divider                                                         */}
                        {/* ================================================================ */}

                        <hr className='mt-3 mb-0' hidden={businessRequest?.status === 'Awaiting Line Manager Approval' || businessRequest?.status === 'Awaiting Finance Manager Approval' || businessRequest?.status === 'BU Approved'}></hr>

                        {/* ============================================ */}
                        {/*  Customer Success Manager                    */}
                        {/* ============================================ */}

                        <div className='BusinessRequest-Form-Row' hidden={businessRequest?.status === 'Awaiting Line Manager Approval' || businessRequest?.status === 'Awaiting Finance Manager Approval' || businessRequest?.status === 'BU Approved' || businessRequest?.status === 'Rejected'}>
                            <p className='text-base text-[#414141] mb-2'>If you have any questions or concerns, please reach out to the customer success manager listed below.</p>
                            <label className='font-medium my-[10px] [@media(max-width:450px)]:w-[300px]'> Customer Success Manager</label>
                            <input className='Input-Field-Text [@media(max-width:450px)]:w-[300px]' type='email' value={businessRequest?.csm} disabled></input>
                        </div>

                    </form>
                </div>

                {/* Approval Modal */}
                <ApprovalModal
                  approval={approval}
                  modalState={formData}      
                  setModalState={setFormData}
                ></ApprovalModal>    

            </div>
        }
        successContent={
            <div className='p-[4%] rounded-[5px] text-center items-center'>
                <div className='inline-flex gap-[12px] text-center'>
                    <img className='m-0 p-0 text-center' src={Tick} alt='Complete'></img>
                    <h4 className='my-2 text-[#007A78]'>Thank You</h4>
                </div>                        
                <p className='leading-[1.7]'> Your changes have now been saved.</p>
                <button className='Primary-Button' onClick={() => navigate('/approvals')}> Close </button>
            </div>  
        }
    ></PageComponent>
  )

}
