//================================================================
//  Component: Business Request Page
//================================================================

//  Purpose: This page will display the business request in detail

//  Properties:

//  Example:
//    <BusinessRequestsId></BusinessRequestsId>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components
import BusinessRequestsOverview from '../../../Components/BusinessRequests/BusinessRequestsOverview';
import BusinessRequestsApprovals from '../../../Components/BusinessRequests/BusinessRequestsApprovals';
import TabView from '../../../Components/TabView/TabView';
import PageComponent from '../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';

//Functions
import DocumentListener from '../../../Library/DocumentListener';

//Images
import Tick from '../../../Components/Images/Icon_Tick_DarkTeal.svg';


export default function BusinessRequestsId() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save the page status > 'pending', 'onload', 'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds the current business request
    const [businessRequest, setBusinessRequest] = useState();

    // Used to store the approval status
    const [approvalStatus, setApprovalStatus] = useState();

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Find the business request document
    useEffect(() => {

        const businessRequestId = searchParams.get('businessrequestid');

        if (businessRequestId === '') return;
        if (businessRequestId === null) return;

        function onLoad(document){

            setBusinessRequest(document);
            setApprovalStatus(document?.status);
            setPageStatus('onload');       

        }
        
        function onChange(document){

            setBusinessRequest(document);
            setApprovalStatus(document?.status);
            setPageStatus('onload');       
 
        }
        
        function onError(error){

            console.log('error', error)
            setPageStatus('error-fatal');

        }
        
        const unsubscribe = DocumentListener('businessrequests', businessRequestId, onLoad, onChange, onError);
        
        return () =>{
            unsubscribe();
        };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return(
        <PageComponent
            header={
                <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
            
                    {/* Breadcrumbs */}
                    <BreadCrumbs
                        rootLinkName={'Business Requests'}                      
                        rootLinkRoute={'/businessrequests'}                    
                        childLinkName={businessRequest?.initiativename}
                    ></BreadCrumbs>    

                    {/* Heading */}
                    <div className='flex flex-col w-fit-content'>
                        <p className='text-[20px] font-medium m-0 p-0 text-wrap'>{businessRequest?.initiativename}</p>
                        <p className='mt-2 text-base text-wrap'>
                            This business request was submitted by <b className='capitalize font-medium'>{businessRequest?.createdby.split('@')[0].split('.')[0]} {businessRequest?.createdby.split('@')[0].split('.')[1]}</b> on {businessRequest?.created?.toDate().toLocaleString()}.
                        </p>

                    </div>
                </div>
            }
            requiredRoles={['isApprover', 'isCSM', 'isApprovalAdmin']}
            requiredRolesValue={ true }
            status={pageStatus}
            body={
                <TabView
                    defaultView={1}
                    oneTitle={'Overview'}
                    oneContent={

                        // Overview Form                   
                        <BusinessRequestsOverview
                            businessRequest={businessRequest}
                            approvalStatus={approvalStatus}
                            setPageStatus={setPageStatus}
                        ></BusinessRequestsOverview>
                    
                    }
                    oneRoute={`/businessrequests/id?businessrequestid=${businessRequest?.businessrequestid}&view=overview`}
                    twoTitle={'Approvals'}
                    twoContent={

                        // Approvals                   
                        <BusinessRequestsApprovals
                            businessRequest={businessRequest}
                            setPageStatus={setPageStatus}
                        ></BusinessRequestsApprovals>

                    }
                    twoRoute={`/businessrequests/id?businessrequestid=${businessRequest?.businessrequestid}&view=approvals`}
                    // threeTitle={'History'}
                    // threeContent={

                    //     // History --> PLACEHOLDER                   
                    // }
                    // threeRoute={`/businessrequests/id?businessrequestid=${businessRequest.businessrequestid}&view=history`}
                ></TabView>    
            }
            successContent={
                <div className='p-[4%] rounded-[5px] text-center items-center'>
                    <div className='inline-flex gap-[12px] text-center'>
                        <img className='m-0 p-0 text-center' src={Tick} alt='Complete'></img>
                        <h4 className='my-2 text-[#007A78]'>Thank You</h4>
                    </div>                        
                    <p className='leading-[1.7]'> Your changes have now been saved.</p>
                    <button className='Primary-Button' onClick={() => navigate('/businessrequests')}> Close </button>
                </div>  
            }
        ></PageComponent>
    )

  //------------------------------------------------------
}
