//================================================================
//  Component: Approvers Table
//================================================================

//  Purpose: Child component for Approvers.js

//  Example:
//    <ApproversTable
//      formData={formData}                 // useReducer
//      setFormData={setFormData}           // useReducer
//      heading={<></>}                     // HTML for the table
//      modalHeading={<></>}                // HTML for edit approvers modal
//      fieldKey={fieldKey}                 // 'dpmApprovers', 'itApprovers' & 'steercoApprovers'
//      role={string}                       //  IT, DPM, RDOO, RCFO & BU Steer Co
//      approvalbucket={string}             //  IT Approval, DPM Approval & LLA Digital Steer Co Approval
//      modalType={string}                  // <OPTIONAL> Adds the business unit to the input fields ('undefined' for the standard modal or 'steerco' for the SteerCo approval modal) 
//    ></ApproversTable>    

//================================================================


//Libraries
import React, {useState, useReducer} from 'react';

//Contexts

//Components
import ModifyApproversModal from './ModifyApproversModal';

//Functions
import convertDate from '../../../Library/ConvertDate';

//Images
import expandIcon from '../../../Components/Images/Icon_Expand_Black.svg';


export default function ApproversTable({
    formData,
    heading,
    modalHeading,
    fieldKey,
    role,
    approvalbucket,
    modalType,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  const [expand, setExpand] = useState(true);

  // Used to store form inputs
  const [modalState, setModalState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      // Modal States
      'isOpen': false,                  // open & close the edit modal
      'row': undefined,                 // Curret row in the table
      'role': role,                     //  IT, DPM, RDOO, RCFO & BU Steer Co   
      'approvalbucket': approvalbucket, //  IT Approval, DPM Approval & LLA Digital Steer Co Approval
      'roleUsers': undefined,           // Users assigned to the above role
      'errorSubmit': '',                // form submission errors       
      'allowSubmit': false,             // Allow or prevent submission
    }
  );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='[@media(max-width:450px)]:p-[4%]'>
    
        {/* Heading --> Passed down from Approvers.js */}
        <div>
            <p className='font-medium text-[18px] text-[#006866] my-2'>{heading}</p>
            <hr className='my-3'></hr>
        </div>
    
        {/* Table */}
        <div className='Table-Container [@media(max-width:500px)]:hidden'>
            <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                    <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                        <th className='py-[15px] px-[10px] font-medium'></th>
                        <th className='py-[15px] px-[10px] font-medium'>Email</th>
                        <th className='py-[15px] px-[10px] font-medium'>Business Unit</th>
                        <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                        <th className='py-[15px] px-[10px] font-medium'>Modified By</th>
                        <th className='py-[15px] px-[10px] font-medium'>

                            {/* Expand & collapse table icon */}
                            {
                                expand === true ? (
                                    <img className='w-[25px] cursor-pointer' src={expandIcon} alt='expand-icon' onClick={() => setExpand(!expand)}></img>
                                ): (
                                    <img className='w-[25px] cursor-pointer' style={{transform: 'rotate(180deg)'}} src={expandIcon} alt='expand-icon' onClick={() => setExpand(!expand)}></img>
                                )
                            }

                        </th>
                    </tr>
                </thead>
                <tbody hidden={!expand}>
                    {
                        // Check if there is any records
                        formData[fieldKey].length === 0 ? (
                            <tr>
                                <td className='py-[15px] px-[10px] font-medium'></td>
                                <td className='py-[15px] px-[10px] ml-[5%]' colSpan='4'>No approvers</td>
                                <td 
                                    className='underline py-[15px] px-[10px] font-medium text-[#007A78] cursor-pointer' 
                                    onClick={() => {

                                        setModalState({
                                            'row': undefined,
                                            'isOpen': true,
                                            'roleUsers': formData[fieldKey],
                                        });

                                    }}>
                                        Edit
                                </td>
                            </tr>
                        )
                        : 

                        // Has records
                        (
                            formData[fieldKey].map((request, index) => (

                                <tr key={index} className='border-b-[#C8C8C9] border-solid border-b'>
                                    <td className='py-[15px] px-[10px]'></td>
                                    <td className='py-[15px] px-[10px] break-words'>{request?.emailaddress ? request?.emailaddress : '-'}</td>
                                    <td className='py-[15px] px-[10px]'>{request?.businessunit ? request?.businessunit : '-'}</td>
                                    <td className='py-[15px] px-[10px] break-words'>{convertDate(request?.modified) ? convertDate(request?.modified) : '-'}</td>
                                    <td className='py-[15px] px-[10px] capitalize'>
                                        {request?.modifiedby ? `${request?.modifiedby.split('@')[0].split('.')[1]}, ${request?.modifiedby.split('@')[0].split('.')[0]}`  : '-'}
                                    </td>
                                    <td className='py-[15px] px-[10px]'>
                                        <u 
                                        className='font-medium text-[#007A78] cursor-pointer' 
                                        onClick={() => {

                                            setModalState({
                                                'row': request,
                                                'isOpen': true,
                                                'roleUsers': formData[fieldKey],
                                            });

                                        }}>
                                            Edit
                                        </u>
    
                                    </td>
                                </tr>

                            ))
                        )
                    }
                </tbody>
            </table>
        </div>

        <div className='hidden [@media(max-width:450px)]:block m-0'>
            {
                // Check if there is any records
                formData[fieldKey].length === 0 ? (
                    <div className='flex flex-row justify-between items-center'>
                        <p className='p-0 m-0'>No approvers</p>
                        <p 
                            className='underline p-0 m-0 font-medium text-[#007A78] cursor-pointer' 
                            onClick={() => {

                                setModalState({
                                    'row': undefined,
                                    'isOpen': true,
                                    'roleUsers': formData[fieldKey],
                                });

                            }}>
                                Edit
                        </p>
                    </div>
                )
                : 

                // Has records
                (
                    formData[fieldKey].map((request, index) => (
                        <div key={index} className={`flex flex-row justify-between items-center my-3`}>
                            <p className='p-0 m-0'>{request?.emailaddress}</p>
                            <p 
                                className='underline p-0 m-0 font-medium text-[#007A78] cursor-pointer' 
                                onClick={() => {

                                    setModalState({
                                        'row': undefined,
                                        'isOpen': true,
                                        'roleUsers': formData[fieldKey],
                                    });

                                }}>
                                    Edit
                            </p>
                        </div>
                    ))
                )
            }
        </div>

        {/* Modify Approvers Modal */}
        <ModifyApproversModal
            modalState={modalState}
            setModalState={setModalState}
            modalHeader={modalHeading}
            existingUsers={formData[fieldKey]}
            modalType={modalType}
        ></ModifyApproversModal>   

    </div>
  )

}
