//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Contexts
import { GetAppSize, GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';
import NavItem from './NavItem';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.png';
import Menu from '../Images/Icon_Menu_White.svg';

//CSS
import './Navbar.css';


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getAppSize = useContext(GetAppSize);
    const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

    // If the menu is open or closed
    const [menuOpen, setMenuOpen] = useState(true);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the style of each component
    function changeStyleHandler(link, textColour) {

      if (textColour === undefined) {

        textColour = 'text-black'

      }

      //Exception for the default route '/' > We want to the home icon to be selected
      if(routerUseLocation.pathname === '/' && link.toLowerCase() === '/home') {

        //Update the style to clicked
        return `cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[14px] no-underline ${textColour}`;

      // Clicked item --> include child pages --> '/home' & '/home/child' are treated the same!
      } else if (routerUseLocation.pathname.toLowerCase().includes(link.toLowerCase())){
    
        //Update the style to clicked
        return `cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[14px] no-underline ${textColour}`;

      //Update the style to NOT clicked
      } else {
        
        return `cursor-pointer p-[10px] no-underline  ${textColour}`;

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    useEffect(() => {

      if (getUser?.roles === undefined) return setPageStatus('success');

    },[pageStatus, getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div>

        {/* ---------------------------------------------------- */}
        {/*  Nav Header                                          */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row justify-between items-center bg-[#141C25] text-[white] px-[10px] py-0 h-[70px] [@media(max-width:450px)]:pl-0 [@media(max-width:450px)]:pr-5'>
    
          {/* Logo */}
          <div className='flex items-center p-[25px]'>

            <NavLink to='/home'>
              <img className='mr-[20px] cursor-pointer' src={LLLogo} alt='Lendlease Logo' width='55px' height='39px'></img>
            </NavLink>

            <h5 className='font-medium m-0 whitespace-nowrap overflow-hidden overflow-ellipsis'> Digital Business Improvement Portal</h5>

          </div>

          {/* Show menu (Small Device) or signout button (Large Device) */}
          {
            getAppSize.width < 930 ? (

              <div>

                <img className='w-[25px]' onClick={() => setMenuOpen(!menuOpen)} src={Menu} alt='Menu'></img>

                {/* Routes routes & Sign out in Menu */}
                <div onClick={() => setMenuOpen(!menuOpen)} hidden={menuOpen} className='flex flex-column absolute top-[70px] right-[0px] bg-[#141c25ec] w-[220px] p-2 rounded-b'>

                  {/* Business Request Form */}
                  <NavItem style={changeStyleHandler('/home', 'text-white')} link={'/home'} title={'Home'} roles={['isLLUser']}></NavItem>

                  {/* Track Requests */}
                  <NavItem style={changeStyleHandler('/trackrequests', 'text-white')} link={'/trackrequests'} title={'Track Requests'} roles={['isLLUser']}></NavItem>

                  {/* All Business Requests */}
                  <NavItem style={changeStyleHandler('/businessrequests', 'text-white')} link={'/businessrequests'} title={'All Business Requests'} roles={['isCSM', 'isApprover', 'isApprovalAdmin']}></NavItem>

                  {/* ICT Assessments */}
                  <NavItem style={changeStyleHandler('/ictassessments', 'text-white')} link={'/ictassessments'} title={'ICT Assessments'} roles={['isCSM']}></NavItem>

                  {/* Approvals */}
                  <NavItem style={changeStyleHandler('/approvals', 'text-white')} link={'/approvals'} title={'Approvals'} roles={['isCSM', 'isApprover']}></NavItem>

                  {/* Approvers */}
                  <NavItem style={changeStyleHandler('/approvers', 'text-white')} link={'/approvers'} title={'Approvers'} roles={['isCSM', 'isApprovalAdmin']}></NavItem>

                  {/* Dashboard */}
                  <NavItem style={changeStyleHandler('/dashboard', 'text-white')} link={'/dashboard'} title={'Dashboard'} roles={['isCSM', 'isApprover']}></NavItem>

                  {/* Log out Button */}
                  <div className='flex flex-row-reverse'>
                    <Logout></Logout>
                  </div>
                  
                </div>

              </div>

            )
            : 
            (

              <Logout></Logout>

            )

          }

        </div>
        
        {/* ---------------------------------------------------- */}
        {/*  Nav Menu                                            */}
        {/* ---------------------------------------------------- */}

        {/* Show menu (Small Device) or signout button (Large Device) */}
        {
          getAppSize.width > 930 &&

            <div className='flex flex-row gap-2 border-b-[#C8C8C9] border-solid border-b px-[3%] m-0 h-[50px] items-center shadow-sm'>

              {/* Business Request Form */}
              <NavItem style={changeStyleHandler('/home')} link={'/home'} title={'Home'} roles={['isLLUser']}></NavItem>
            
              {/* Track Requests */}
              <NavItem style={changeStyleHandler('/trackrequests')} link={'/trackrequests'} title={'Track Requests'} roles={['isLLUser']}></NavItem>

              {/* All Business Requests */}
              <NavItem style={changeStyleHandler('/businessrequests')} link={'/businessrequests'} title={'All Business Requests'} roles={['isCSM', 'isApprover']}></NavItem>
            
              {/* ICT Assessments */}
              <NavItem style={changeStyleHandler('/ictassessments')} link={'/ictassessments'} title={'ICT Assessments'} roles={['isCSM']}></NavItem>
            
              {/* Approvals */}
              <NavItem style={changeStyleHandler('/approvals')} link={'/approvals'} title={'Approvals'} roles={['isCSM', 'isApprover']}></NavItem>
            
              {/* Approvers */}
              <NavItem style={changeStyleHandler('/approvers')} link={'/approvers'} title={'Approvers'} roles={['isCSM', 'isApprovalAdmin']}></NavItem>
            
              {/* Dashboard */}
              <NavItem style={changeStyleHandler('/dashboard')} link={'/dashboard'} title={'Dashboard'} roles={['isCSM', 'isApprover']}></NavItem>

            </div>

        }

      </div>
    )

}
