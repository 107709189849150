//================================================================
//  Page: Dashboard 
//================================================================

//  Purpose: This is the LLA Dashboard

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';
import Requests from './Components/Requests';
import Costs from './Components/Costs';

//Functions

//Images

// CSS
import './Dashboard.css';

export default function Dashboard() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------




  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container'>
            <p className='font-medium text-[20px] m-0'>Dashboard</p>
          </div>
        }
        requiredRoles={['isCSM', 'isApprover']}
        requiredRolesValue={true}
        status={'onload'}
        body={
          <TabView
            defaultView={1}
            oneTitle={'Business Requests'}
            oneContent={
              <Requests/>
            }
            oneRoute={'/dashboard?view=businessrequests'}
            twoTitle={'Costs'}
            twoContent={
              <Costs/>
            }
            twoRoute={'/dashboard?view=costs'}
          ></TabView>    
        }
      ></PageComponent>
    )
}