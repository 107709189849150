//================================================================
//  Component: Costs Dashboard
//================================================================

//  Purpose: This is the LLA Costs Dashboard

//================================================================

//Libraries
import React, { useContext, useState, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import CollectionListener from '../../../Library/CollectionListener';

// CSS
import '../Dashboard.css';

// Register Elements for Charts
ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels
);

export default function Costs() {
  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Used to set the ranges for costs in the costs list
  const costRange = {
    ZERO: '$0',
    ONE_TO_TEN_K: '$1 to $10k',
    TEN_K_TO_THIRTY_K: '$10k to $30k',
    THIRTY_K_TO_FIFTY_K: '$30k to $50k',
    FIFTY_K_TO_ONE_HUNDRED_K: '$50k to $100k',
    ONE_HUNDRED_K_TO_TWO_FIFTY_K: '$100k to $250k',
    TWO_FIFTY_K_OR_MORE: '$250k or more',
  };

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store dashboard data
  const [dashboardData, setDashboardData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      // Original Data
      businessrequests: [],
      approvals: [],

      // Chart Data
      requestdrivingrevenuelist: {},
      requestsdrivingfundslist: {},
      requestsestimatedcostsawaitingapproval: '',
      requestsestimatedcostsapproval: '',
      requestsestimatedcostsrejected: '',
      requestcostcomparisonpie: {},
      requestcostcomparisonpieoptions: {
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: 2,
            color: 'white',
            textAlign: 'center',
            font: {
              size: 10,
            },
            formatter: function (value, context) {
              if (
                context.chart.data.datasets[0].data[context.dataIndex] === 0
              ) {
                return null;
              } else {
                return context.chart.data.labels[context.dataIndex];
              }
            },
          },
        },
      },
      requestsbycostestimatebar: {},
      requestsbycostestimatebaroptions: {
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              autoSkip: true,
            },
          },
        },
      },
      bubycostestimatebar: {},
      bubycostestimatebaroptions: {
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              minRotation: 0,
              maxRotation: 0,
            },
          },
          y: {
            stacked: true,
          },
        },
      },
    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Gets appropriate colour for cost category for Requests Costs List
  const getCostCellStyle = (category) => {
    if (category === costRange.ZERO || category === costRange.ONE_TO_TEN_K) {
      return { backgroundColor: '#629e67' };

    } else if (category === costRange.TEN_K_TO_THIRTY_K) {
      return { backgroundColor: '#8ad491' };

    } else if (category === costRange.THIRTY_K_TO_FIFTY_K) {
      return { backgroundColor: '#ffd26a' };

    } else if (category === costRange.FIFTY_K_TO_ONE_HUNDRED_K) {
      return { backgroundColor: '#eaa300' };

    } else if (category === costRange.ONE_HUNDRED_K_TO_TWO_FIFTY_K) {
      return { backgroundColor: '#ff884c' };

    } else if (category === costRange.TWO_FIFTY_K_OR_MORE) {
      return { backgroundColor: '#c4314b' };

    } else {
      return { backgroundColor: 'white' };

    }
  };

  // Returns the average cost for each cost category
  const calculateAverage = (costString) => {
    const costMappings = {
      '$0': 0,
      '$1 to $10k': 5,
      '$10k to $30k': 20,
      '$30k to $50k': 40,
      '$50k to $100k': 75,
      '$100k to $250k': 175,
      '$250k or more': 250,
    };

    return costMappings[costString] || Number(costString.slice(1));
  };

  // Returns the highest cost associated with each cost category
  const getHighestCost = (costString) => {
    const costMappings = {
      $0: 0,
      '$1 to $10k': 10,
      '$10k to $30k': 30,
      '$30k to $50k': 50,
      '$50k to $100k': 100,
      '$100k to $250k': 250,
      '$250k or more': 250,
    };
    return costMappings[costString] || Number(costString.slice(1));
  };

  // Returns the lowest cost associated with each cost category
  const getLowestCost = (costString) => {
    const costMappings = {
      $0: 0,
      '$1 to $10k': 1,
      '$10k to $30k': 10,
      '$30k to $50k': 30,
      '$50k to $100k': 50,
      '$100k to $250k': 100,
      '$250k or more': 250,
    };
    return costMappings[costString] || Number(costString.slice(1));
  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Collection Listener > 'Business Requests'
  useEffect(() => {

    if (getUser === undefined) return;

    function onLoad(document) {

      // ----- Initatives Driving Revenue Growth ----- //
      const growthRevenueRequests = document.filter(
        (request) => request?.revenuegrowth === 'yes'
      );

      // ----- Initatives Driving Funds Growth ----- //
      const growthFundsRequests = document.filter(
        (request) => request?.fundsgrowth === 'yes'
      );

      // ----- Estimated Cost of all Initiatives Awaiting Approval ---- //

      // Filter Data for Items Awaiting Approval
      const activeApprovals = document.filter(
        (request) =>
          request?.status !== 'Approved' ||
          request?.status !== 'Rejected' ||
          request?.status !== 'Approved'
      );

      // Total Cost of Initiatives Awaiting Approval
      let estimatedActiveApprovalCost = 0;

      activeApprovals.forEach((request) => {
        estimatedActiveApprovalCost += calculateAverage(request.cost);
      });

      // ----- Estimated Cost of all Approved Initiatives ---- //

      // Total Approved Initiatives
      const approved = document.filter(
        (request) => request?.status === 'Approved'
      );

      // Total Cost of Approved Initiatives
      let estimatedApprovedCost = 0;

      approved.forEach((request) => {
        estimatedApprovedCost += calculateAverage(request.cost);
      });

      // ----- Estimated Cost of all Rejected Initiatives ---- //

      // Total Rejected Approvals
      const rejectedRequests = document.filter(
        (request) => request?.status === 'Rejected'
      );

      // Total Cost of Rejected Initiatives
      let estimatedRejectedCost = 0;

      rejectedRequests.forEach((request) => {
        estimatedRejectedCost += calculateAverage(request.cost);
      });

      // ----- Top Initiatives by Cost Estimate ----- //

      // Cost Estimate Bar chart - average cost sorted highest to lowest
      const costEstimateBar = _.orderBy(
        document,
        (item) => calculateAverage(item.cost),
        ['desc']
      );

      // Save cost estimates in order in new list
      let listOfRequests = [
        ...new Set(costEstimateBar?.map((item) => item.initiativename)),
      ]?.map((x) => {
        return x.split(' ').join(' ');
      });

      // Wrap Initiaitive Name labels
      let listOfRequestsWrapped = [];
      listOfRequests.forEach((request) => {
        let buWords = request.split(' ');
        listOfRequestsWrapped.push(buWords);
      });

      // Costs Bar Chart per Initiative
      let requestsCostEstimateBar = {
        labels: listOfRequestsWrapped,
        datasets: [
          {
            label: 'Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#007A78',
            barThickness: 30,
          },
        ],
      };

      // Calculate the average cost for each initiative and push to bar chart
      listOfRequests.forEach((requestName) => {
        const req = document.filter(
          (request) => request?.initiativename === requestName
        );
        const estimatedCost = calculateAverage(req[0].cost);
        requestsCostEstimateBar.datasets[0].data.push(estimatedCost);
      });

      // ----- Cost per Business Unit ----- //

      // Requests table by BU
      let listOfBusinessUnits = [
        ...new Set(document.map((item) => item.primarybeneficiary)),
      ].map((x) => {
        return x.split(' ').join(' ');
      });

      // Wrap business unit labels
      let listOfBusinessUnitsWrapped = [];
      listOfBusinessUnits.forEach((businessunit) => {
        let buWords = businessunit.split(' ');
        listOfBusinessUnitsWrapped.push(buWords);
      });

      const initiativesByBu = _.orderBy(
        document,
        (item) => item.primarybeneficiary,
        ['asc']
      );

      // Costs by BU - stacked bar
      let estimateCostPerBU = {
        labels: listOfBusinessUnitsWrapped,
        datasets: [
          {
            label: 'Lowest Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#007A78',
            barThickness: 30,
          },
          {
            label: 'Highest Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#ef9300',
            barThickness: 30,
          },
        ],
      };

      // Higher band / lower band costs associated with each BU - push to stacked bar chart
      listOfBusinessUnits.forEach((businessUnit) => {
        const bu = document.filter(
          (request) => request?.primarybeneficiary === businessUnit
        );
        let lowerEstimate = 0;
        let higherEstimate = 0;
        for (const request of bu) {
          lowerEstimate += getLowestCost(request.cost);
          higherEstimate += getHighestCost(request.cost);
        }

        estimateCostPerBU.datasets[0].data.push(lowerEstimate);
        estimateCostPerBU.datasets[1].data.push(higherEstimate);
      });

      
      // ----- Estimate Cost Allocation Comparison - % ----- //

      const initiativeCosts = document.map((request) =>
        calculateAverage(request.cost)
      );
      const costsArray = Object.values(initiativeCosts);
      const totalInitiativeCosts = costsArray.reduce(
        (sum, cost) => sum + cost,
        0
      );

      const buCostComparisonPie = {
        labels: listOfBusinessUnits,
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#016866',
              '#2b7b79',
              '#458f8c',
              '#5ea3a0',
              '#76b8b5',
              '#8ecdca',
              '#a6e2df',], 
          },
        ],
      };

      // Percentage cost associated with each BU
      listOfBusinessUnits.forEach((businessUnit) => {
        let totalCost = 0;
        const bu = document.filter(
          (request) => request?.primarybeneficiary === businessUnit
        );
        for (const request of bu) {
          totalCost += calculateAverage(request.cost);
        }
        const averagePerBu = totalCost / totalInitiativeCosts;
        const percentagePerBu = (averagePerBu * 100).toFixed(2) + '%';
        buCostComparisonPie.datasets[0].data.push(percentagePerBu);
      });

      // Update Dashboard Data
      setDashboardData({
        businessrequests: initiativesByBu,
        requestdrivingrevenuelist: growthRevenueRequests,
        requestsdrivingfundslist: growthFundsRequests,
        requestsestimatedcostsawaitingapproval: estimatedActiveApprovalCost,
        requestsestimatedcostsapproval: estimatedApprovedCost,
        requestsestimatedcostsrejected: estimatedRejectedCost,
        requestsbycostestimatebar: requestsCostEstimateBar,
        bubycostestimatebar: estimateCostPerBU,
        requestcostcomparisonpie: buCostComparisonPie,
      });

      setPageStatus('onload');
    }

    function onChange(document) {
      // ----- Initatives Driving Revenue Growth ----- //
      const growthRevenueRequests = document.filter(
        (request) => request?.revenuegrowth === 'yes'
      );

      // ----- Initatives Driving Funds Growth ----- //
      const growthFundsRequests = document.filter(
        (request) => request?.fundsgrowth === 'yes'
      );

      // ----- Estimated Cost of all Initiatives Awaiting Approval ---- //

      // Filter Data for Items Awaiting Approval
      const activeApprovals = document.filter(
        (request) =>
          request?.status !== 'Approved' ||
          request?.status !== 'Rejected' ||
          request?.status !== 'Approved'
      );


      // Total Cost of Initiatives Awaiting Approval
      let estimatedActiveApprovalCost = 0;

      activeApprovals.forEach((request) => {
        estimatedActiveApprovalCost += calculateAverage(request.cost);
      });

      // ----- Estimated Cost of all Approved Initiatives ---- //

      // Total Approved Initiatives
      const approved = document.filter(
        (request) => request?.status === 'Approved'
      );

      // Total Cost of Approved Initiatives
      let estimatedApprovedCost = 0;

      approved.forEach((request) => {
        estimatedApprovedCost += calculateAverage(request.cost);
      });

      // ----- Estimated Cost of all Rejected Initiatives ---- //

      // Total Rejected Approvals
      const rejectedRequests = document.filter(
        (request) => request?.status === 'Rejected'
      );

      // Total Cost of Rejected Initiatives
      let estimatedRejectedCost = 0;

      rejectedRequests.forEach((request) => {
        estimatedRejectedCost += calculateAverage(request.cost);
      });

      // ----- Top Initiatives by Cost Estimate ----- //

      // Cost Estimate Bar chart - average cost sorted highest to lowest
      const costEstimateBar = _.orderBy(
        document,
        (item) => calculateAverage(item.cost),
        ['desc']
      );

      // Save cost estimates in order in new list
      let listOfRequests = [
        ...new Set(costEstimateBar.map((item) => item.initiativename)),
      ].map((x) => {
        return x.split(' ').join(' ');
      });

      // Wrap Initiaitive Name labels
      let listOfRequestsWrapped = [];
      listOfRequests.forEach((request) => {
        let buWords = request.split(' ');
        listOfRequestsWrapped.push(buWords);
      });

      // Costs Bar Chart per Initiative
      let requestsCostEstimateBar = {
        labels: listOfRequestsWrapped,
        datasets: [
          {
            label: 'Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#007A78',
            barThickness: 30,
          },
        ],
      };

      // Calculate the average cost for each initiative and push to bar chart
      listOfRequests.forEach((requestName) => {
        const req = document.filter(
          (request) => request?.initiativename === requestName
        );
        const estimatedCost = calculateAverage(req[0].cost);
        requestsCostEstimateBar.datasets[0].data.push(estimatedCost);
      });

      // ----- Cost per Business Unit ----- //

      // Requests table by BU
      let listOfBusinessUnits = [
        ...new Set(document.map((item) => item.primarybeneficiary)),
      ].map((x) => {
        return x.split(' ').join(' ');
      });

      // Wrap business unit labels
      let listOfBusinessUnitsWrapped = [];
      listOfBusinessUnits.forEach((businessunit) => {
        let buWords = businessunit.split(' ');
        listOfBusinessUnitsWrapped.push(buWords);
      });

      const initiativesByBu = _.orderBy(
        document,
        (item) => item.primarybeneficiary,
        ['asc']
      );

      // Costs by BU - stacked bar
      let estimateCostPerBU = {
        labels: listOfBusinessUnitsWrapped,
        datasets: [
          {
            label: 'Lowest Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#007A78',
            barThickness: 30,
          },
          {
            label: 'Highest Estimated Cost ($ - K)',
            data: [],
            backgroundColor: '#ef9300',
            barThickness: 30,
          },
        ],
      };

      // Higher band / lower band costs associated with each BU - push to stacked bar chart
      listOfBusinessUnits.forEach((businessUnit) => {
        const bu = document.filter(
          (request) => request?.primarybeneficiary === businessUnit
        );
        let lowerEstimate = 0;
        let higherEstimate = 0;
        for (const request of bu) {
          lowerEstimate += getLowestCost(request.cost);
          higherEstimate += getHighestCost(request.cost);
        }

        estimateCostPerBU.datasets[0].data.push(lowerEstimate);
        estimateCostPerBU.datasets[1].data.push(higherEstimate);
      });

      
      // ----- Estimate Cost Allocation Comparison - % ----- //

      const initiativeCosts = document.map((request) =>
        calculateAverage(request.cost)
      );
      const costsArray = Object.values(initiativeCosts);
      const totalInitiativeCosts = costsArray.reduce(
        (sum, cost) => sum + cost,
        0
      );

      const buCostComparisonPie = {
        labels: listOfBusinessUnits,
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#016866',
              '#2b7b79',
              '#458f8c',
              '#5ea3a0',
              '#76b8b5',
              '#8ecdca',
              '#a6e2df',
            ],
          },
        ],
      };

      // Percentage cost associated with each BU
      listOfBusinessUnits.forEach((businessUnit) => {
        let totalCost = 0;
        const bu = document.filter(
          (request) => request?.primarybeneficiary === businessUnit
        );
        for (const request of bu) {
          totalCost += calculateAverage(request.cost);
        }
        const averagePerBu = totalCost / totalInitiativeCosts;
        const percentagePerBu = (averagePerBu * 100).toFixed(2);
        buCostComparisonPie.datasets[0].data.push(percentagePerBu);
      });

      // Set Dashboard Data
      setDashboardData({
        businessrequests: initiativesByBu,
        requestdrivingrevenuelist: growthRevenueRequests,
        requestsdrivingfundslist: growthFundsRequests,
        requestsestimatedcostsawaitingapproval: estimatedActiveApprovalCost,
        requestsestimatedcostsapproval: estimatedApprovedCost,
        requestsestimatedcostsrejected: estimatedRejectedCost,
        requestsbycostestimatebar: requestsCostEstimateBar,
        bubycostestimatebar: estimateCostPerBU,
        requestcostcomparisonpie: buCostComparisonPie,
      });

      setPageStatus('onload');
    }

    function onError() {
      setPageStatus('error-fatal');
    }

    const unsubscribe = CollectionListener(
      'businessrequests',
      onLoad,
      onChange,
      onError
    );

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Error Fatal
    //------------------------------------------------------
    
    if (pageStatus === 'error-fatal') {
      return (
        <div className='bg-[#FAFAFA] mb-[2%] p-[4%] rounded-[5px] text-center'>
          <h2 className='mb-[15px]'>Oops something went wrong</h2>
          <p className='leading-[1.7]'>
            An error occurred while we processed your request.
            <br></br>
            If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the error message below. 
          </p>
          <p className='leading-[1.7]'> 
            <b>Message:</b> Failed to load the page - Fatal Error.
          </p>
          <button className='Primary-Button' onClick={() => window.location.reload()}> Try Again </button>
        </div>
      )
    }

    //------------------------------------------------------
    //  Onload
    //------------------------------------------------------

    else {
      return (
        <div className='bg-[#016866] m-0 p-[10px] border-solid border-[#d7d7d7] border-1 rounded-none' style={{ borderRadius: 'none' }}>
          <div className='Costs-Dashboard-Container'>

            {/* All Business Requests By Cost (List) */}
            <div className='Requests-Costs-List'>
              <p className='font-medium text-[15px] text-black mb-2'>
                All Business Requests By Cost
              </p>
              {
                dashboardData?.businessrequests.length > 0 
                ? 
                (
                  <table className='text-left w-full'>
                    <thead className='w-full'>
                      <tr className='w-full flex bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='pl-[10px] py-[5px] w-1/2 font-medium'>
                          Business Request Name
                        </th>
                        <th className='py-[5px] w-1/2 font-medium'>
                          Business Unit
                        </th>
                        <th className='py-[5px] w-1/2 font-medium'>Cost</th>
                      </tr>
                    </thead>
                    <tbody className='bg-grey-light overflow-y-scroll overflow-x-hidden w-full h-[370px]'>
                      {
                        dashboardData?.businessrequests?.map((request, index) => (
                          <tr key={index} className='flex w-full'>
                            <td className='pl-[10px] py-[5px] w-1/2'>
                              {request?.initiativename}
                            </td>
                            <td className='p-[5px] w-1/2'>
                              {request?.primarybeneficiary}
                            </td>
                            <td className='p-[5px] w-1/2' style={getCostCellStyle(request?.cost)}>
                              {request?.cost}
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>

            {/* Estimate Cost Allocation Comparison (Doughnut Chart)*/}
            <div className='Requests-Cost-Comparison-Pie'>
              <p className='font-medium text-[15px] text-black mb-2'>
                Estimate Cost Allocation Comparison - %
              </p>
              {
                dashboardData?.businessrequests.length > 0 ? 
                (
                  <div className='flex flex-col items-center'>
                    <Doughnut
                      data={dashboardData?.requestcostcomparisonpie}
                      options={dashboardData?.requestcostcomparisonpieoptions}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>

            {/* Initiatives Driving Revenue Growth (Bar Chart)*/}
            <div className='Requests-Driving-Revenue-List'>
              <p className='font-medium text-[15px] text-black mb-1'>
                Initiatives Driving Revenue Growth
              </p>
              {
                dashboardData?.requestdrivingrevenuelist.length > 0 ? 
                (
                  <table className='w-full text-left'>
                    <thead>
                      <tr className='bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='pl-[10px] p-[4px] font-medium'>
                          {' '}
                          Business Request Name
                        </th>
                        <th className='p-[4px] font-medium'>Growth Time</th>
                      </tr>
                    </thead>
                    <tbody className='bg-grey-light overflow-y-scroll overflow-x-hidden w-full max-h-[135px]'>
                      {
                        dashboardData?.requestdrivingrevenuelist?.map(
                          (request, index) => (
                            <tr key={index}>
                              <td className='pl-[10px] p-[4px]'>
                                {request?.initiativename}
                              </td>
                              <td className='p-[4px]'>
                                {request?.arevenuegrowthcommence}
                              </td>
                            </tr>
                          )
                        )
                      }
                    </tbody>
                  </table>
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>

            {/* Initiatives Driving Funds Growth (Bar Chart) */}
            <div className='Requests-Driving-Funds-List'>
              <p className='font-medium text-[15px] text-black mb-1'>
                Initiatives Driving Funds Growth
              </p>
              {
                dashboardData?.requestsdrivingfundslist.length > 0 ? 
                (
                  <table className='text-left w-full'>
                    <thead>
                      <tr className='bg-[#F1F1F1] border-b-[#C8C8C9] border-solid border-b'>
                        <th className='pl-[10px] p-[4px] font-medium'>
                          Business Request Name
                        </th>
                        <th className='p-[4px] font-medium'>Growth Time</th>
                      </tr>
                    </thead>
                    <tbody className='bg-grey-light overflow-y-scroll overflow-x-hidden w-full max-h-[135px]'>
                      {
                        dashboardData?.requestsdrivingfundslist?.map(
                          (request, index) => (
                            <tr key={index}>
                              <td className='pl-[10px] p-[4px]'>
                                {request?.initiativename}
                              </td>
                              <td className='p-[4px]'>
                                {request?.afundsgrowthcommence}
                              </td>
                            </tr>
                          )
                        )
                      }
                    </tbody>
                  </table>
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>

            {/* Estimated Cost of all Initiatives Awaiting Approval (Total) */}
            <div className='Requests-Estimated-Costs-Awaiting-Approval'>
              <div className='flex flex-col justify-center items-center'>
                <h2 className='font-medium text-[45px]'>
                  ${dashboardData?.requestsestimatedcostsawaitingapproval}K
                </h2>
                <p>Estimated Cost of all Initiatives Awaiting Approval</p>
              </div>
            </div>

            {/* Estimated Cost of all Rejected Initiatives (List) */}
            <div className='Requests-Estimated-Costs-Rejected'>
              <div className='flex flex-col justify-center items-center'>
                <h2 className='font-medium text-[45px]'>
                  ${dashboardData?.requestsestimatedcostsrejected}K
                </h2>
                <p>Estimated Cost of all Rejected Initiatives</p>
              </div>
            </div>

            {/* Estimated Cost of all Approved Initiatives (List) */}
            <div className='Requests-Estimated-Costs-Approved'>
              <div className='flex flex-col justify-center items-center'>
                <h2 className='font-medium text-[45px]'>
                  ${dashboardData?.requestsestimatedcostsapproval}K
                </h2>
                <p>Estimated Cost of all Approved Initiatives</p>
              </div>
            </div>

            {/* Cost per Business Unit (Stacked Bar Chart) */}
            <div className='Requests-BU-Estimated-Costs-Bar'>
              <p className='font-medium text-[15px] text-black mb-2'>
                Cost per Business Unit
              </p>
              {
                dashboardData?.businessrequests.length > 0 ? 
                (
                  <Bar
                    data={dashboardData?.bubycostestimatebar}
                    options={dashboardData?.bubycostestimatebaroptions}
                  />
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>

            {/* Top Initiatives by Cost Estimate (Bar Chart) */}
            <div className='Requests-Estimated-Costs-Bar'>
              <p className='font-medium text-[15px] text-black mb-2'>
                Top Initiatives by Cost Estimate
              </p>
              {
                dashboardData?.businessrequests.length > 0 ? 
                (
                  <Bar
                    data={dashboardData?.requestsbycostestimatebar}
                    options={dashboardData?.requestsbycostestimatebaroptions}
                  />
                ) 
                : 
                (
                  <p className='text-[#6B6B6B] text-[18px] text-center my-[4%]'>
                    No data found
                  </p>
                )
              }
            </div>
          </div>
        </div>
      )
    }
}
