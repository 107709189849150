//================================================================
//  Component: ICT Assessment Page
//================================================================

//  Purpose: This page will display the ICT assessment in detail

//  Properties:

//  Example:
//    <ICTAssessmentDetail></ICTAssessmentDetail>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//Contexts

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';

//Functions
import queryDocument from '../../../Library/QueryDocument';
import ConvertDate from '../../../Library/ConvertDate';

//Images


export default function ICTAssessmentDetail() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Holds the ictassessmentid doc from Firestore
    const [assessment, setAssessment] = useState();

    // Used to save page status > 'pending', 'not-found', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    useEffect(() => {

        const assessmentId = searchParams.get('ictassessmentid');

        if (assessmentId === '') return;
        if (assessmentId === null) return;
  
        queryDocument('ictassessments', [
            ['ictassessmentid', '==', assessmentId]
        ])
        .then((docs) => {

            if (docs.length === 0) return setPageStatus('not-found');

            setAssessment(docs[0]);
            setPageStatus('onload');

        }).catch((error) => {

            console.log('error', error);
            setPageStatus('error-fatal');

        });

    }, [searchParams]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <PageComponent
            header={
                <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
                    
                    {/* Breadcrumbs */}
                    <BreadCrumbs
                        rootLinkName={'ICT Assessments'}                      
                        rootLinkRoute={'/ictassessments'}                    
                        childLinkName={assessment?.initiativename}
                    ></BreadCrumbs>    

                    {/* Heading */}
                    <div className='flex flex-col w-fit-content'>
                        <p className='text-[20px] font-medium m-0 p-0 text-wrap'>ICT Assessment</p>
                        <p className='mt-2 text-base text-wrap'>
                            This ICT assessment was submitted by <b className='text-zinc-800 font-medium capitalize'>{assessment?.createdby.split('.')[0]} {assessment?.createdby.split('.')[1].split('@')[0]}</b> on {ConvertDate(assessment?.created)} for <b className='text-zinc-800 font-medium'>{assessment?.initiativename}</b>.
                        </p>

                    </div>
                </div>
            }
            requiredRoles={ ['isCSM', 'isApprovalAdmin'] }
            requiredRolesValue={ true }
            status={pageStatus}
            body={
                <div className='Page-Section-Container'>
    
                    <form className='ICTAssessment-Form'>

                        {/* =========================================================== */}
                        {/*  1. What is the name of this initiative / application? *    */}
                        {/* =========================================================== */}       

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>1. What is the name of this initiative / application? *</b> </label>
                            <input className={'Input-Field-Text-ReadOnly'} type='text' value={assessment?.initiativename} disabled></input>
                        </div>              

                        {/* =========================================================== */}
                        {/*  2. What ICT Architecture is this initiative a part of? *   */}
                        {/* =========================================================== */}  

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>2. What ICT Architecture is this initiative a part of? *</b> </label>
                            <select className={'Input-Field-Select'} style={{ width: '300px'}} value={assessment?.ictarchitecture} disabled>
                                <option hidden value=''>{assessment?.ictarchitecture}</option>
                                <option value='Investment'> Investment </option>
                                <option value='Development'> Development </option>
                                <option value='Construction'> Construction </option>
                                <option value='Construction'> Group </option>
                                <option value='Construction'> Enterprise IT/Technology </option>
                            </select>                            
                        </div> 

                        {/* ============================================================================================================= */}
                        {/*  3. Does the product for this initiative rely on other technology to function effectively? *                  */}
                        {/* ============================================================================================================= */}               

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>3. Does the product for this initiative rely on other technology to function effectively? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='isdependent' value='yes' defaultChecked={assessment?.isdependent?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='isdependent' value='no' defaultChecked={assessment?.isdependent?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* ======================================================= */}
                        {/*  3a. If yes, what is the reliant technology? *          */}
                        {/* ======================================================= */}   

                        <div className='ICTAssessment-Form-Row' hidden={assessment?.isdependent?.toLowerCase() === 'no'}>
                            <label><b style={{fontWeight: '500'}}>3a. If yes, what is the reliant technology? *</b> </label>
                            <input className={'Input-Field-Text-ReadOnly'} type='text' value={assessment?.adependency} disabled></input>
                        </div>

                        {/* ========================================================================================== */}
                        {/*  4. Can this product integrate with other Lendlease products and / or dashboards? *        */}
                        {/* ========================================================================================== */}   

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>4. Can this product integrate with other Lendlease products and / or dashboards? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='productintegration' value='yes' defaultChecked={assessment?.productintegration?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='productintegration' value='no' defaultChecked={assessment?.productintegration?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* =================================================================================== */}
                        {/*  5. Does this product meet the Lendlease cyber security requirements?  *            */}
                        {/* =================================================================================== */}       

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>5. Does this product meet the Lendlease cyber security requirements? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='security' value='yes' defaultChecked={assessment?.security?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='security' value='no' defaultChecked={assessment?.security?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* ========================================================================================== */}
                        {/*  6. How long will it take to onboard this product and train team members in its use? *     */}
                        {/* ========================================================================================== */}   

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>6. How long will it take to onboard this product and train team members in its use? *</b> </label>
                            <select className={'Input-Field-Select'} style={{ width: '300px'}} value={assessment?.trainingtime} disabled>
                                <option hidden value=''>-</option>
                                <option value='Within 6 months'> Within 6 months </option>
                                <option value='6 months to 1 year'> 6 months to 1 year </option>
                                <option value='1 to 2 years'> 1 to 2 years </option>
                                <option value='In more than 2 years'> In more than 2 years </option>
                            </select>                            
                        </div>              

                        {/* ====================================================== */}
                        {/*  7. Is this product able to be scaled across BUs?  *   */}
                        {/* ====================================================== */}              

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>7. Is this product able to be scaled across BUs? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='buscalable' value='yes' defaultChecked={assessment?.buscalable?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='buscalable' value='no' defaultChecked={assessment?.buscalable?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* ========================================================= */}
                        {/*  8. Is this product able to be scaled across regions? *   */}
                        {/* ========================================================= */}           

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>8. Is this product able to be scaled across regions? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='regionscalable' value='yes' defaultChecked={assessment?.regionscalable?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='regionscalable' value='no' defaultChecked={assessment?.regionscalable?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* =========================================================== */}
                        {/*  9. Is this product able to be scaled across functions? *   */}
                        {/* =========================================================== */}      

                        <div className='ICTAssessment-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>9. Is this product able to be scaled across functions? *</b> </label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input type='radio' name='functionscalable' value='yes' defaultChecked={assessment?.functionscalable?.toLowerCase() === 'yes'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>                
                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input type='radio' name='functionscalable' value='no' defaultChecked={assessment?.functionscalable?.toLowerCase() === 'no'} disabled></input>
                                    <span className='Radio-Checkmark'></span>
                                </label>
                            </div>
                        </div>              

                        {/* =================================================================================================== */}
                        {/*  10. Provide a summary of how this product will improve productivity and efficiency. *              */}
                        {/* =================================================================================================== */}   

                        <div className='BusinessRequest-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>10. Provide a summary of how this product will improve productivity and efficiency. *</b> </label>
                            <textarea className={'Input-Field-TextArea-ReadOnly'} value={assessment?.justifyproductivity} disabled></textarea>
                        </div>              

                        {/* =================================================================================================== */}
                        {/*  11. Provide a summary of how this product would reduce operating costs. *                          */}
                        {/* =================================================================================================== */} 

                        <div className='BusinessRequest-Form-Row'>
                            <label><b style={{fontWeight: "500"}}>11. Provide a summary of how this product would reduce operating costs. *</b> </label>
                            <textarea className={'Input-Field-TextArea-ReadOnly'} value={assessment?.justifycosts} disabled></textarea>
                        </div>     

                    </form>
                    
                </div>
              }
        ></PageComponent>

    )

  //------------------------------------------------------
}
