//================================================================
//  Component: Business Request Approvals
//================================================================

//  Purpose: Component of the BusinessRequestsId.js page, created to support tabular view

//  Properties:

//  Example:
//  <BusinessRequestsApprovals
//      businessRequest={businessRequest}       // useState that holds the business request
//      setPageStatus={setPageStatus}           // useState holding page status ('pending', 'onload', etc)
//  ></BusinessRequestsApprovals>

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';

//Contexts

//Components
import StatusLabel from '../StatusLabel/StatusLabel';

//Functions
import QueryListener from '../../Library/QueryListener';
import convertDate from '../../Library/ConvertDate';


export default function BusinessRequestsApprovals({
    businessRequest,
    setPageStatus,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [approvals, setApprovals] = useState([]);

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    //  1. Get all approvals for current business request
    useEffect(() => {

        if (businessRequest === undefined) return;
        if (businessRequest.businessrequestid === undefined) return;

        function onLoadChange(documents){
  
            setApprovals(documents);
    
        };
    
        function onError(error) {
    
            console.log('error', error);
            setPageStatus('error-fatal');

        };

        const unsubscribe = QueryListener('approvals', [['businessrequestid', '==', businessRequest.businessrequestid],], onLoadChange, onLoadChange, onError, ['created', 'asc'])

        return () => {

            unsubscribe();
          
        }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessRequest]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return(
        <div className='Table-Container'>

            <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                    <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                        <th className='py-[15px] px-[10px] font-medium'></th>
                        <th className='py-[15px] px-[10px] font-medium'>Name</th>
                        <th className='py-[15px] px-[10px] font-medium'>Approver</th>
                        <th className='py-[15px] px-[10px] font-medium'>Stage</th>
                        <th className='py-[15px] px-[10px] font-medium'>Role</th>
                        <th className='py-[15px] px-[10px] font-medium'>Status</th>
                        <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                        <th className='py-[15px] px-[10px] font-medium'>Comments</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        // No Results
                        approvals.length === 0 ? 
                        (
                            <tr>
                                <td colSpan={8}>
                                <div className='text-center p-[2%]'>
                                    No results found.
                                </div>
                                </td>
                            </tr>
                        ) 
                        : 
                        (
                            // Results
                            approvals.map((row, index) => (

                                <tr key={index} className='border-b-[#C8C8C9] border-solid border-b'>
                                    <td className='py-[15px] px-[10px]'></td>
                    
                                    {/* Name */}
                                    <td className='py-[15px] px-[10px]'>
                                        {row?.requestname}
                                    </td>
                        
                                    {/* Approver */}
                                    <td className='py-[15px] px-[10px] break-words'>
                                        {row?.approver}
                                    </td>
                        
                                    {/* Stage */}
                                    <td className='py-[15px] px-[10px]'>
                                        {row?.stage}
                                    </td>

                                    {/* Role */}
                                    <td className='py-[15px] px-[10px]'>
                                        { row?.role ? row?.role : '-' }
                                    </td>
                    
                                    {/* Status */}
                                    <td className='pt-[15px] pb-[10px] px-[10px]'>
                                        <StatusLabel status={row?.status}></StatusLabel>
                                    </td>
                    
                                    {/* Modified Date */}
                                    <td className='py-[15px] px-[10px]'>
                                        {convertDate(row?.modified)}
                                    </td>
                    
                                    {/* Comments */}
                                    <td className={`py-[15px] px-[10px]`}>
                                        {row?.comments.length > 0 ? row?.comments : '-'}
                                    </td>

                                </tr>
                            
                            ))
                        )
                    }
                </tbody>
            </table>

        </div>
    )

  //------------------------------------------------------
}
