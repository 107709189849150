//================================================================
//  Page: ICT Assessment Form
//================================================================

//  Purpose: This is the ICT Assessment Form

//  Example:
//    <ICTAssessmentForm></ICTAssessmentForm>    

//================================================================


//Libraries
import React, { useState, useContext, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadCrumbs';

//Functions
import WriteDocument from '../../Library/WriteDocument';
import CollectionListener from '../../Library/CollectionListener';

//Images

//CSS
import './ICTAssessmentForm.css';


export default function ICTAssessmentForm() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
  const getUser = useContext(GetUser);
  const navigate = useNavigate();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store form inputs
  const [formData, setFormData] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      'initiativename': '',
      'initiativenameValid': true,                            
      'ictarchitecture': '',
      'ictarchitectureValid': true,
      'isdependent': '',
      'isdependentValid': true,
      'adependency': '',
      'adependencyValid': true,
      'productintegration': '',
      'productintegrationValid': true,              
      'security': [],
      'securityValid': true,         
      'trainingtime': '',
      'trainingtimeValid': true,           
      'buscalable': '',
      'buscalableValid': true,
      'regionscalable': '',
      'regionscalableValid': true,                                    
      'functionscalable': [],
      'functionscalableValid': true,                           
      'justifyproductivity': '',
      'justifyproductivityValid': true,   
      'justifycosts': '',
      'justifycostsValid': true, 
    }
  );

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status >  'pending', 'onload', 'success', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Used to store all the business requests 
  const [allBusinessRequests, setAllBusinessRequests] = useState([]);
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Function to handle changes to input fields
  function handleInputChange(evt){
    
    const name = evt.target.name;
    const newValue = evt.target.value;

    //Validate field - 'initiativename'
    if (name === 'initiativename') {

      if(newValue.length <= 0) {

        setFormData({'initiativenameValid': false});

      } else {

        setFormData({'initiativenameValid': true});

      }
    
    }

    //Validate field - 'ictarchitecture'
    if (name === 'ictarchitecture') {

      if (newValue.length <= 0) {

        setFormData({'ictarchitectureValid': false});

      } else {

        setFormData({'ictarchitectureValid': true});

      }
    
    }

    //Validate field - 'isdependent'
    if(name === 'isdependent') {

      if(newValue.length <= 0) {

        setFormData({'isdependentValid': false});

      } else {

        setFormData({'isdependentValid': true});
      
      }

    }

    //Validate field - 'productintegration'
    if(name === 'productintegration') {

      if(newValue.length <= 0) {

        setFormData({'productintegrationValid': false});

      } else {

        setFormData({'productintegrationValid': true});

      }
    
    }

    //Validate field - 'security'
    if(name === 'security') {

      if(newValue.length <= 0) {

        setFormData({'securityValid': false});

      } else {

        setFormData({'securityValid': true});
        
      }
    }

    //Validate field - 'trainingtime'
    if(name === 'trainingtime') {

      if(newValue.length <= 0) {

        setFormData({'trainingtimeValid': false});

      } else {

        setFormData({'trainingtimeValid': true});

      }

    }

    //Validate field - 'buscalable'
    if (name === 'buscalable') {

      if (newValue.length <= 0) {

        setFormData({ 'buscalableValid': false });

      } else {

        setFormData({ 'buscalableValid': true });

      }

    }

    //Validate field - 'regionscalable'
    if (name === 'regionscalable') {

      if (newValue.length <= 0) {

        setFormData({ 'regionscalableValid': false });

      } else {

        setFormData({ 'regionscalableValid': true });

      }

    }

    //Validate field - 'functionscalable'
    if (name === 'functionscalable') {

      if (newValue.length <= 0) {

        setFormData({ 'functionscalableValid': false })

      } else {

        setFormData({ 'functionscalableValid': true })

      }

    }

    //Validate field - 'justifyproductivity'
    if (name === 'justifyproductivity') {

      if (newValue.length <= 0 || newValue.split(" ").length > 50) {

        setFormData({ 'justifyproductivityValid': false });

      } else {
        
        setFormData({ 'justifyproductivityValid': true });

      }
    
    }

    //Validate field - 'justifyproductivity'
    if (name === 'justifycosts') {

      if (newValue.length <= 0 || newValue.split(" ").length > 50) {

        setFormData({ 'justifycostsValid': false });

      } else {

        setFormData({ 'justifycostsValid': true });

      }
      
    }

    setFormData({ [name]: newValue });

  };

  // Handle submit
  function onSubmit(e){

    // Prevent default behaviour (refresh)
    e.preventDefault();

    //=================================================
    //  Validation Checks
    //=================================================

    // OnSubmit Validation of all required fields
    let preventSubmit = false;

    // Question 1 - Initiative Name
    if (formData.initiativename.length === 0) {
      preventSubmit = true;
      formData.initiativenameValid = false;

    } else {
      formData.initiativenameValid = true;

    };

    // Question 2 - ICT Architecture
    if (formData.ictarchitecture.length === 0) {
      preventSubmit = true;
      formData.ictarchitectureValid = false;

    } else {
      formData.ictarchitectureValid = true;

    };

    // Question 3 - Dependent on Technology
    if (formData.isdependent.length === 0) {
      preventSubmit = true;
      formData.isdependentValid = false;

    } else {
      formData.isdependentValid = true;

    };

    // Question 3a - Reliant Technology
    if (formData.isdependent === 'yes' && formData.adependency.length === 0) {
      preventSubmit = true;
      formData.adependencyValid = false;

    } else {
      formData.adependencyValid = true;

    };

    // Question 4 - Product Integration
    if (formData.productintegration.length === 0) {
      preventSubmit = true;
      formData.productintegrationValid = false;

    } else {
      formData.productintegrationValid = true;

    };

    // Question 5 - Cyber Security
    if (formData.security.length === 0) {
      preventSubmit = true;
      formData.securityValid = false;

    } else {
      formData.securityValid = true;

    };

    // Question 6 - Onboarding
    if (formData.trainingtime.length === 0 ) {
      preventSubmit = true;
      formData.trainingtimeValid = false;

    } else {
      formData.trainingtimeValid = true;

    };
    
    // Question 7 - Scaled Across BU
    if (formData.buscalable.length === 0) {
      preventSubmit = true;
      formData.buscalableValid = false;

    } else {
      formData.buscalableValid = true;

    };

    // Question 8 - Scaled Across Regions
    if (-formData.regionscalable.length === 0) {
      preventSubmit = true;
      formData.regionscalableValid = false;

    } else {
      formData.regionscalableValid = true;

    };

    // Question 9 - Scaled Across Functions
    if (formData.functionscalable.length === 0) {
      preventSubmit = true;
      formData.functionscalableValid = false;

    } else {
      formData.functionscalableValid = true;

    };

    // Question 10 - Summary of Productivity/Efficiency
    if (formData.justifyproductivity.length === 0 || formData.justifyproductivity.split(" ").length > 50) {
      preventSubmit = true;
      formData.justifyproductivityValid = false;

    } else {
      formData.justifyproductivityValid = true;

    };
    
    // Question 11 - Summary of Reducing Operating Costs
    if (formData.justifycosts.length === 0) {
      preventSubmit = true;
      formData.justifycostsValid = false;

    } else {
      formData.justifycostsValid = true;

    };

    setFormData(formData);

    // Check if ALL validations have passed
    if (preventSubmit === true) return;

    setPageStatus('pending');
    
    //=================================================
    //  Write Document to Firestore
    //=================================================

    const ictAssessmentId = `ict-${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}`;

    const ictAssessmentDoc = {
      'ictassessmentid': ictAssessmentId,   
      'initiativename': formData?.initiativename,                            
      'ictarchitecture': formData?.ictarchitecture,
      'isdependent': formData?.isdependent,
      'adependency': formData?.adependency,
      'productintegration': formData?.productintegration, 
      'security': formData?.security,
      'trainingtime': formData?.trainingtime,                                 
      'buscalable': formData?.buscalable,                  
      'regionscalable': formData?.regionscalable,                  
      'functionscalable': formData?.functionscalable,                           
      'justifyproductivity': formData?.justifyproductivity,   
      'justifycosts': formData?.justifycosts, 
      'createdby': getUser?.emailaddress,
      'created': new Date(), 
      'modified': '',    
      'modifiedby': '',       
    };
  
    return WriteDocument('ictassessments', ictAssessmentId, ictAssessmentDoc, true)
    .then(() =>{
      navigate('/ictassessments') // Navigate back to ICT Assessments screen

    })
    .catch((error) =>{
      console.log(error)
      setPageStatus('error-fatal')

    })

  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Onload --> Get all business requests
  useEffect(()=>{
  
    if (getUser === undefined) return;
  
    function onLoad(document){

      setAllBusinessRequests(document);
      setPageStatus('onload');

    }
    
    function onChange(document){
    
      setAllBusinessRequests(document);
      setPageStatus('onload');

    }
    
    function onError(){
      setPageStatus('error-fatal');
    }
    
    const unsubscribe = CollectionListener('businessrequests', onLoad, onChange, onError)
    
    return () =>{
      unsubscribe();
    };
    
  // eslint-disable-next-line
  }, [getUser]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
            
            {/* Breadcrumbs */}
            <BreadCrumbs
                rootLinkName={'ICT Assessments'}                      
                rootLinkRoute={'/ictassessments'}                    
                childLinkName='Form'
            ></BreadCrumbs>    

            {/* Heading */}
            <div className='flex flex-col w-fit-content'>
                <p className='text-[20px] font-medium m-0 p-0 text-wrap'>ICT Assessment Form</p>
                <p className='mt-2 text-base text-wrap'>
                  Please complete the form below. Once submitted, the business request will be sent to the IT approver for approval.
                </p>
            </div>

        </div>
        }
        requiredRoles={ ['isCSM', 'isApprovalAdmin'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='Page-Section-Container'>

            <form className='ICTAssessment-Form' onSubmit={(e) => onSubmit(e)}>

              {/* =========================================================== */}
              {/*  1. What is the name of this initiative / application? *    */}
              {/* =========================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>1. What is the name of this initiative / application? *</b> </label>
  
                <select 
                  className={formData.initiativenameValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  name='initiativename'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.initiativename}
                  style={{ width: '450px'}}
                >
                  <option hidden value=''>-</option>
                  {
                      allBusinessRequests?.map((request, index) => (
  
                        // Only allow 'BU Approved' to appear in dropdown
                        request.status === 'BU Approved' &&
                        <option key={index} value={request?.initiativename}> {request?.initiativename} </option>
                        
                      ))
                  }
                </select>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.initiativenameValid}>
                  This is a required field.
                </label>
  
              </div>
              
              {/* =========================================================== */}
              {/*  2. What ICT Architecture is this initiative a part of? *   */}
              {/* =========================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>2. What ICT Architecture is this initiative a part of? *</b> </label>
                <select 
                    className={formData.ictarchitectureValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                    name='ictarchitecture'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.ictarchitecture}
                    style={{width: '200px'}}
                  >
                    <option hidden value=''>-</option>
                    <option value='Investment'> Investment </option>
                    <option value='Development'> Development </option>
                    <option value='Construction'> Construction </option>
                    <option value='Group'> Group </option>
                    <option value='Enterprise IT/Technology'> Enterprise IT/Technology </option>
                </select>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.ictarchitectureValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* ==================================================================================================== */}
              {/*  3. Does the product for this initiative rely on other technology to function effectively? *         */}
              {/* ==================================================================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>3. Does the product for this initiative rely on other technology to function effectively? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='isdependent' 
                      value='yes' 
                      checked={formData.isdependent === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.isdependentValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='isdependent' 
                      value='no' 
                      checked={formData.isdependent === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.isdependentValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.isdependentValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* ======================================================= */}
              {/*  3a. If yes, what is the reliant technology? *          */}
              {/* ======================================================= */}
  
              <div className='ICTAssessment-Form-Row' hidden={formData.isdependent === 'yes' ? false : true}>
  
                <label><b style={{fontWeight: "500"}}>3a. If yes, what is the reliant technology? *</b> </label>
                <input
                  className={formData.adependencyValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                  name='adependency'
                  type='text'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.adependency}
                ></input>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.adependencyValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* ======================================================================================*/}
              {/*  4. Can this product integrate with other Lendlease products and / or dashboards?  *  */}
              {/* ======================================================================================*/}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>4. Can this product integrate with other Lendlease products and / or dashboards? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='productintegration' 
                      value='yes' 
                      checked={formData.productintegration === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.productintegrationValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='productintegration' 
                      value='no' 
                      checked={formData.productintegration === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.productintegrationValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.productintegrationValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* ===========================================================================*/}
              {/*  5. Does this product meet the Lendlease cyber security requirements?  *   */}
              {/* ========================================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>5. Does this product meet the Lendlease cyber security requirements? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='security' 
                      value='yes' 
                      checked={formData.security === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.securityValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='security' 
                      value='no' 
                      checked={formData.security === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.securityValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.securityValid}>
                  This is a required field.
                </label>
  
              </div>
              
              {/* ========================================================================================= */}
              {/*  6. How long will it take to onboard this product and train team members in its use? *    */}
              {/* ========================================================================================= */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>6. How long will it take to onboard this product and train team members in its use? *</b> </label>
                <select 
                    className={formData.trainingtimeValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                    name='trainingtime'
                    onChange={(e) => handleInputChange(e)}
                    value={formData.trainingtime}
                    style={{width: '200px'}}
                  >
                    <option hidden value=''>-</option>
                    <option value='Within 6 months'> Within 6 months </option>
                    <option value='6 months to 1 year'> 6 months to 1 year </option>
                    <option value='1 to 2 years'> 1 to 2 years </option>
                    <option value='In more than 2 years'> In more than 2 years </option>
                </select>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.trainingtimeValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* ====================================================== */}
              {/*  7. Is this product able to be scaled across BUs?  *   */}
              {/* ====================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>7. Is this product able to be scaled across BUs? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='buscalable' 
                      value='yes' 
                      checked={formData.buscalable === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.buscalableValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='buscalable' 
                      value='no' 
                      checked={formData.buscalable === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.buscalableValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.buscalableValid}>
                  This is a required field.
                </label>
  
              </div>
  
  
              {/* ========================================================= */}
              {/*  8. Is this product able to be scaled across regions? *   */}
              {/* ========================================================= */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>8. Is this product able to be scaled across regions? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='regionscalable' 
                      value='yes' 
                      checked={formData.regionscalable === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.regionscalableValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='regionscalable' 
                      value='no' 
                      checked={formData.regionscalable === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.regionscalableValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.regionscalableValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* =========================================================== */}
              {/*  9. Is this product able to be scaled across functions? *   */}
              {/* =========================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>9. Is this product able to be scaled across functions? *</b> </label>
                
                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input 
                      type='radio' 
                      name='functionscalable' 
                      value='yes' 
                      checked={formData.functionscalable === 'yes'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.functionscalableValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                  </label>
  
                  <label className='Radio-Buttons-Container'>
                    No
                    <input 
                      type='radio' 
                      name='functionscalable' 
                      value='no' 
                      checked={formData.functionscalable === 'no'} 
                      onChange={(e) => handleInputChange(e)}
                      ></input>
                    <span className={formData.functionscalableValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                  </label>
                </div>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.functionscalableValid}>
                  This is a required field.
                </label>
  
              </div>
  
              {/* =================================================================================================== */}
              {/*  10. Provide a summary of how this product will improve productivity and efficiency. *              */}
              {/* =================================================================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>10. Provide a summary of how this product will improve productivity and efficiency. *</b> </label>
                <textarea
                  className={formData.justifyproductivityValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')}
                  name='justifyproductivity' 
                  placeholder='Enter a summary in 50 words or less.'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.justifyproductivity}
                ></textarea>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.justifyproductivityValid}>
                  {
                    formData.justifyproductivity.length === 0 ?
                    <>This is a required field.</>
                    : formData.justifyproductivity.split(" ").length > 50 ?
                    <>Please enter a summary in 50 words or less.</>
                    : null
                  }
                </label>
  
              </div>
  
              {/* =================================================================================================== */}
              {/*  11. Provide a summary of how this product would reduce operating costs. *                          */}
              {/* =================================================================================================== */}
  
              <div className='ICTAssessment-Form-Row'>
  
                <label><b style={{fontWeight: "500"}}>11. Provide a summary of how this product would reduce operating costs. *</b> </label>
                <textarea
                  className={formData.justifycostsValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')}
                  name='justifycosts' 
                  placeholder='Enter a summary in 50 words or less.'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.justifycosts}
                ></textarea>
  
                <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.justifycostsValid}>
                  {
                    formData.justifycosts.length === 0 ?
                    <>This is a required field.</>
                    : formData.justifycosts.split(" ").length > 50 ?
                    <>Please enter a summary in 50 words or less.</>
                    : null
                  }
                </label>
  
              </div>
  
              {/* ===================================== */}
              {/*  Submit Buttons                       */}
              {/* ===================================== */}
  
              <div>
                <button style={{marginTop: '30px'}} className='Primary-Button' type='submit' value='submit'>Submit</button>
              </div>
  
            </form>

          </div>
        }
      ></PageComponent> 
    )
}
