//================================================================
//  Component: ICT Assessments
//================================================================

//  Purpose: This page displays all ICT assessments

//  Example:
//    <ICTAssessments></ICTAssessments>    

//================================================================


//Libraries
import React, { useState, useContext, useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';

//Functions
import CollectionListener from '../../Library/CollectionListener';
import ConvertDate from '../../Library/ConvertDate';

//Images
import Form from '../../Components/Images/Icon_Form_White.svg';

//CSS


export default function ICTAssessments() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');


    // Used to store all ICT assessments
    const [ictAssessments, setIctAssessments] = useState([]);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> Get all ICT Assessments
    useEffect(()=>{
    
      if (getUser === undefined) return;
    
      function onLoadChange(document){

        setIctAssessments(document.reverse());
        setPageStatus('onload');

      }
      
      function onError(){
        setPageStatus('error-fatal');
      }
      
      const unsubscribe = CollectionListener('ictassessments', onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
      
    // eslint-disable-next-line
    }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
            
            {/* Heading */}
            <div className='flex flex-col w-fit-content'>
                <p className='text-[20px] font-medium m-0 p-0 text-wrap'>ICT Assessments</p>
                <p className='mt-2 text-base text-wrap'>
                  Below is a list of all of the completed ICT assessments. An ICT assessment is completed by a Customer Success Manager once a business request has received finance manager approval. 
                </p>

                {/* ICT Assessment Form */}
                <div>
                  <p>Click the button below to complete a new ICT assessment.</p>
                  <button className='Primary-Button' style={{display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center'}} onClick={() => navigate('/ictassessmentform')}>
                    <img src={Form} alt='Form'></img>
                    ICT Assessment Form
                  </button>
                </div>

            </div>
        </div>
        }
        requiredRoles={ ['isCSM', 'isApprovalAdmin'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='Table-Container'>

            <table className='w-full max-h-96 overflow-y-scroll'>
              <thead>
                <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                  <th className='p-[10px] font-medium'></th>
                  <th className='p-[15px] font-medium'>Initiative Name</th>
                  <th className='p-[15px] font-medium'>ICT Architecture</th>
                  <th className='p-[15px] font-medium'>Submitted On</th>
                  <th className='p-[15px] font-medium'>Submitted By</th>
                </tr>
              </thead>
              <tbody>
                {
                  ictAssessments.length === 0 ? 
                  (
                    <tr>
                      <td colSpan={5}>
                        <div className='text-center p-[2%]'>
                          No results found.
                        </div>
                      </td>
                    </tr>

                  ): (

                    ictAssessments.map((assessment, index) => (
                      <tr key={index} className='border-b-[#C8C8C9] border-solid border-b cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/ictassessments/id?ictassessmentid=${assessment?.ictassessmentid}`)}>
                        <td className='p-[10px]'></td>
                        <td className='p-[15px] text-black font-medium hover:underline'>{assessment?.initiativename}</td>
                        <td className='p-[15px]'>{assessment?.ictarchitecture}</td>
                        <td className='p-[15px]'>{ConvertDate(assessment?.created)}</td>
                        <td className='p-[15px] break-words'>
                          {assessment?.createdby}
                        </td>
                      </tr>
                    ))
                  )


                }
              </tbody>
            </table>
            
          </div>
        }

      ></PageComponent> 
    )
}
