//================================================================
//  Component: Business Requests
//================================================================

//  Purpose: This page displays all business requests submitted by LL Users

//  Example:
//    <BusinessRequests></BusinessRequests>    

//================================================================


//Libraries
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

// Library
import CollectionListener from '../../Library/CollectionListener';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import StatusLabel from '../../Components/StatusLabel/StatusLabel';

//Functions
import ConvertDate from '../../Library/ConvertDate';

//Images

//CSS


export default function BusinessRequests() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Used to store all the business requests 
    const [allBusinessRequests, setAllBusinessRequests] = useState([]);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> Get all business requests
    useEffect(()=>{
    
      if (getUser === undefined) return;
    
      function onLoadChange(documents){

        // Sorting by created time (in seconds)
        documents.sort((a, b) => {

          if (a.created.seconds < b.created.seconds) return 1;
          if (a.created.seconds > b.created.seconds) return -1;
          return 0;

        });

        setAllBusinessRequests(documents);
        setPageStatus('onload');

      }
      
      function onError(){
        setPageStatus('error-fatal');
      }
      
      const unsubscribe = CollectionListener('businessrequests', onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
      
    // eslint-disable-next-line
    }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
              
            {/* Heading */}
            <div className='flex flex-col w-fit-content'>
                <p className='text-[20px] font-medium m-0 p-0 text-wrap'>Business Requests</p>
                <p className='mt-2 text-base text-wrap m-0'>
                  Click on each business request to view the full submission and see its approval history.
                </p>
            </div>

          </div>
        }
        requiredRoles={ ['isCSM', 'isApprover', 'isApprovalAdmin'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='Table-Container'>

            <table className='w-full max-h-96 overflow-y-scroll'>
              <thead>
                <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                  <th className='py-[15px] px-[10px] font-medium'></th>
                  <th className='py-[15px] px-[10px] font-medium'>Initiative Name</th>
                  <th className='py-[15px] px-[10px] font-medium'>Business Need</th>
                  <th className='p-[15px] font-medium'>Owner</th>
                  <th className='py-[15px] px-[10px] font-medium'>Status</th>    
                  <th className='py-[15px] px-[10px] font-medium'>Created</th>              
                </tr>
              </thead>
              <tbody>
                {

                  // No requests found
                  allBusinessRequests.length === 0 ? (

                    <tr>
                      <td colSpan={6}>
                        <div className='text-center p-[2%]'>
                          No results found.
                        </div>
                      </td>
                    </tr>

                  ): (

                    // Map in each business request
                    allBusinessRequests.map((request, index) => (

                      <tr key={index} className='border-b-[#C8C8C9] border-solid border-b cursor-pointer hover:bg-[#F0F7F7]' onClick={() => navigate(`/businessrequests/id?businessrequestid=${request?.businessrequestid}&view=overview`)}>
                        <td className='py-[15px] px-[10px]'></td>
                        <td className='py-[15px] px-[10px] font-medium hover:underline'>{request?.initiativename}</td>
                        <td className='py-[15px] px-[10px]'>
                          <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                            {request?.businessneed}

                          </div>
                        </td>
                        <td className='p-[15px] capitalize'>{request?.owner.split('.')[1].split('@')[0]}, {request?.owner.split('.')[0]} </td>
                        <td className='pt-[15px] pb-[10px] px-[10px]'>
                          <StatusLabel status={request?.status}></StatusLabel>
                        </td>
                        <td className='p-[15px]'>{ConvertDate(request?.created)}</td>
                      </tr>

                    ))

                  )

                }
              </tbody>
            </table>

          </div>
        }

      ></PageComponent> 
    )
}
