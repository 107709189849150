//================================================================
//  Component: Modify Approvers Modal
//================================================================

//  Purpose: Child component of ApproversTable.js

//  Example:
//    <ModifyApproversModal
//      modalState={modalState}                 // useReducer in ApproversTable.js
//      setModalState={setModalState}           // useReducer in ApproversTable.js
//      modalHeader={<></>}                     // modal Header
//      modalType={undefined or 'steerco'}      // <OPTIONAL> Adds the business unit to the input fields ('undefined' for the standard modal or 'steerco' for the SteerCo approval modal) 
//    ></ModifyApproversModal>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import writeDocument from '../../../Library/WriteDocument';
import deleteDocument from '../../../Library/DeleteDocument';

//Images
import addIcon from '../../../Components/Images/Icon_Add_Green.svg';
import trashIcon from '../../../Components/Images/Icon_Trash_Green.svg';


export default function ModifyApproversModal({
    modalState,
    setModalState,
    modalHeader,
    modalType,
}) {

  //------------------------------------------------------
  //  useContexts & Variables
  //------------------------------------------------------

    const getUser = useContext(GetUser);
    const listOfBusinessUnits = [
        'Capella',
        'Communities',
        'Construction',
        'Corporate Affairs and Marketing',
        'Corporate Real Estate',
        'Development',
        'DOO Office',
        'EHS',
        'Finance',
        'Integrated Solutions',
        'Investment Management',
        'Legal',
        'People & Culture',
        'RAP',
        'Risk and Insurance',
        'Sustainability',
    ];

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // search input state
    const [emailAddress, setEmailAddress] = useState('');
    const [emailAddressInvalid, setEmailAddressInvalid] = useState(false);

    // givenname input state
    const [givenname, setGivenname] = useState('');
    const [givennameInvalid, setGivennameInvalid] = useState(false);

    // <Optional> Business unit select state
    const [businessUnit, setBusinessUnit] = useState('');
    const [businessUnitInvalid, setBusinessUnitInvalid] = useState(false);

    // Holds the original data, so we can revert back any unsubmitted changes
    const [request, setRequest] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handle adding the user
    function handleAddUser(){

        // 1. Validate email address
        if (!/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(emailAddress)) {

            setEmailAddressInvalid(true);

            return setModalState({
                'errorSubmit': `Provide a valid email address.`,
            });

        }

        // 2. Lendlease addresses ONLY
        if (emailAddress.split('@')[1].toLowerCase() !== 'lendlease.com') {

            setEmailAddressInvalid(true);

            return setModalState({
                'errorSubmit': `Provide a valid 'lendlease.com' email address.`,
            });

        }

        // 3. Check if the user is already a member of that role!
        const existCheck = request?.filter((user) => user.emailaddress === emailAddress && user.status !== 'deleted');
        if (existCheck.length > 0 && modalType === undefined) {

            setEmailAddressInvalid(true);

            return setModalState({
                'errorSubmit': `User ${emailAddress} already has ${modalState.role} role`,
            });
        
        }

        // 4. Check for a givenname
        if (givenname.length === 0) {

            setGivennameInvalid(true);

            return setModalState({
                'errorSubmit': `Provide a givenname.`,
            });
        
        }

        // 5. <OPTIONAL CHECK> make sure a business unit is selected
        if (modalType !== undefined && businessUnit.length === 0) {

            setBusinessUnitInvalid(true);

            return setModalState({
                'errorSubmit': `Select a business unit`,
            });
        
        }

        // 6. <OPTIONAL CHECK> make sure there is only one approver per business unit
        const buApprovers = request.filter((approver) => approver.businessunit === businessUnit && approver.status !== 'deleted')
        if (modalType !== undefined && buApprovers.length > 0) {

            setBusinessUnitInvalid(true);

            return setModalState({
                'errorSubmit': `An approver for this business unit '${buApprovers[0].businessunit}' exists. Please remove them and resubmit.`,
            });
        
        }

        // Add a user to the 'array' in the useReducer
        setModalState({
            'errorSubmit': '',
            'allowSubmit': true,
        });

        request.push({
            'emailaddress': emailAddress,
            'givenname': givenname,
            'approvalbucket': modalState.approvalbucket,
            'role': modalState.role,
            'approverid': `${modalState.role.toLowerCase().replace(/ /g, '')}-${emailAddress}`,
            'created': new Date(),
            'createdby': getUser.emailaddress,
            'businessunit': businessUnit,
            'status': 'new',
        })

        setRequest([...request])

        setEmailAddress('');
        setEmailAddressInvalid(false);

        setGivenname('');
        setGivennameInvalid(false);

        setBusinessUnit('');
        setBusinessUnitInvalid(false);

    }

    // Handle the form submission
    function handleSubmit(){

        const remainingApprovers = request.filter((approver) => approver.status === 'new' || approver.status === 'existing');

        // ------------------------------------------------------
        //  Validation Checks
        // ------------------------------------------------------

        // Check we have at least one approver
        if (remainingApprovers.length === 0) {
            
            return setModalState({
                'errorSubmit': 'You must select at least ONE approver.',
            });        
        }

        // If Steer Co Approver > Check at least one approver per BU
        if (modalType !== undefined) {

            let preventSubmit = false;
            let invalidBUApprovers = '';

            listOfBusinessUnits.forEach((bu) => {
            
                // Check there is an approver for this BU
                const buApprover = request.filter((approver) => approver.businessunit === bu && approver.status !== 'deleted');

                if (buApprover.length === 0) {

                    preventSubmit = true;

                    // Create a string of missing BU's
                    if (invalidBUApprovers.length === 0) return invalidBUApprovers = `${bu}`;
                    invalidBUApprovers = `${bu}, ${invalidBUApprovers}`;
                }
                    
            });
            
            if (preventSubmit === true) {
                return setModalState({
                    'errorSubmit': `You must select at least ONE approver per business unit. Affected business units: ${invalidBUApprovers}`,
                });     
            }
        }


        // Disable submit button
        setModalState({
            'allowSubmit': false,
        });

        // ------------------------------------------------------
        //  New Approvers
        // ------------------------------------------------------

        const newUserpromises = [];

        request?.forEach((document) => {

            const approverid = modalType === undefined ?
            `${document.role.toLowerCase().replace(/ /g, '')}-${document.emailaddress}`
            :
            `${document.role.toLowerCase().replace(/ /g, '')}-${document.businessunit.toLowerCase().replace(/ /g, '')}-${document.emailaddress}`

            // Skip existing users
            if (document.status === 'existing') return;

            // New Users
            if (document.status === 'new') {

                // Add the modified flag to each user doc
                document.modified = new Date();
                document.modifiedby = getUser.emailaddress;
                delete document.status;

                // Send each update to firestore
                newUserpromises.push(
                    writeDocument('approvers', approverid, document, true),
                );
            
            }

        });

        // ------------------------------------------------------
        //  Settle Promises
        // ------------------------------------------------------
        
        Promise.all(newUserpromises)
        .then(() => {

            // ------------------------------------------------------
            //  Deleted Approvers
            // ------------------------------------------------------

            const deletedUserPromises = [];

            request?.forEach((document) => {

                const approverid = modalType === undefined ?
                `${document.role.toLowerCase().replace(/ /g, '')}-${document.emailaddress}`
                :
                `${document.role.toLowerCase().replace(/ /g, '')}-${document.businessunit.toLowerCase().replace(/ /g, '')}-${document.emailaddress}`

                // Skip existing users
                if (document.status === 'existing') return;

                // Deleted Users
                if (document.status === 'deleted') {

                    // Send each update to firestore
                    deletedUserPromises.push(
                        deleteDocument('approvers', approverid),
                    );
                
                }

            });

            return new Promise((resolve) => {

                setTimeout(resolve, 2 * 1000);
            
            })
            .then(() => {

                // Settle Promises
                return Promise.all(deletedUserPromises)
                .then(() => {

                    // ------------------------------------------------------
                    //  Reset Form
                    // ------------------------------------------------------

                        setModalState({
                            'isOpen': false,
                            'roleUsers': undefined,
                            'errorSubmit': '',
                        });
            
                        setRequest([]);

                })

            })
            

        })
        .catch((error) => {

            setModalState({
                'errorSubmit': `Failed to save, error: ${error}`,
                'allowSubmit': true,
            });

        });

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    //  1. Add the current data to a useState, this allows us to create a point in time copy of the data
    //     By doing this, we can revert back to the previous version
    useEffect(() => {

        if (modalState.roleUsers === undefined) return;

        modalState.roleUsers?.forEach((object) => {

            object.status = 'existing';

        });

        setRequest([...modalState.roleUsers]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState.roleUsers]);

  //------------------------------------------------------
  //  HTML 
  //------------------------------------------------------

    if (modalState.isOpen !== true) return;

    return (
        <div className='Modal-Background'>

                <dialog className='Modal-Container w-[800px] gap-1'>

                {/* ======================================= */}
                {/*        Body Container                   */}
                {/* ======================================= */}

                <div>
                    {/* Header */}
                    {modalHeader}

                    {/* Modify Users Form */}
                    <div className='grid grid-cols-[1fr_1fr]'>

                        {/* Search Column */}
                        <div className='mt-[10px] px-3'>

                            <h6>Add Lendlease User</h6>

                            <div className='flex items-center flex-row my-[20px]'>

                                {/* ======================================= */}
                                {/*         Search Input Box                */}
                                {/* ======================================= */}

                                <div>
                                    {/* Email Address */}
                                    <input 
                                        placeholder='sally.smith@lendlease.com'
                                        type='text' 
                                        className={emailAddressInvalid === false ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                        style={{width: '300px'}}
                                        onChange={(e) => setEmailAddress(e.target.value.toLocaleLowerCase())}
                                        value={emailAddress}
                                        autoComplete='off'
                                    ></input>

                                    {/* Given Name */}
                                    <input 
                                        placeholder='sally'
                                        type='text' 
                                        className={givennameInvalid === false ? 'Input-Field-Text mt-[5px]' : 'Input-Field-Text-Error'}
                                        style={{width: '300px'}}
                                        onChange={(e) => setGivenname(e.target.value)}
                                        value={givenname}
                                        autoComplete='off'
                                    ></input>

                                    {/* Business Unit */}
                                    <select 
                                        hidden={modalType === undefined ? true : false}
                                        className={businessUnitInvalid === false ? 'Input-Field-Select mt-[5px]' : 'Input-Field-Select-Error mt-[5px]'}
                                        onChange={(e) => {
                                                setBusinessUnitInvalid(false);
                                                setBusinessUnit(e.target.value);
                                            }
                                        }
                                        value={businessUnit}
                                        style={{ width: '300px'}}
                                    >
                                        <option hidden value=''>-</option>
                                        <option value='Capella'> Capella </option>
                                        <option value='Communities'> Communities </option>
                                        <option value='Construction'> Construction </option>
                                        <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                                        <option value='Corporate Real Estate'> Corporate Real Estate </option>
                                        <option value='Development'> Development </option>
                                        <option value='DOO Office'> DOO Office </option>
                                        <option value='EHS'> EHS </option>
                                        <option value='Finance'> Finance </option>
                                        <option value='Integrated Solutions'> Integrated Solutions </option>
                                        <option value='Investment Management'> Investment Management </option>
                                        <option value='Legal'> Legal </option>
                                        <option value='People & Culture'> People & Culture </option>
                                        <option value='RAP'> RAP </option>
                                        <option value='Risk and Insurance'> Risk and Insurance </option>
                                        <option value='Sustainability'> Sustainability </option>

                                    </select>

                                </div>

                                {/* ======================================= */}
                                {/*             Add Button                  */}
                                {/* ======================================= */}

                                <img className='self-end cursor-pointer' src={addIcon} alt='add-icon' title='Add' onClick={() => handleAddUser()}></img>
                       
                            </div>

                        </div>
            
                        {/* ======================================= */}
                        {/*             User List                   */}
                        {/* ======================================= */}

                        <div className='mt-[10px]'>

                            <h6 className='ml-[20px]'>Modify Users</h6>

                            <ul className='max-h-[250px] min-w-[300px] px-3 overflow-y-auto overflow-x-hidden border-l-[2px] '>

                                {
                                    request?.map((user, index) => (
                                        // Each User
                                        <li key={index} className='flex flex-row justify-between items-center px-[17px] py-[10px] my-[10px] mx-[0px] rounded-[5px] border border-zinc-300' hidden={user.status === 'deleted' ? true : false}>
                                            <div className='flex flex-col gap-[5px]'>
                                                {user.emailaddress}
                                                <br></br>
                                                <label hidden={modalType === undefined ? true : false} className='w-fit text-[12px] px-[10px] py-[5px] bg-[#E7F2DA] rounded-[30px]'>{user.businessunit}</label>
                                            </div>

                                            {/* ======================================= */}
                                            {/*             Remove Button               */}
                                            {/* ======================================= */}

                                            <img className='w-[18px] cursor-pointer' title='Remove' src={trashIcon} alt='remove-icon' onClick={() => {

                                                const selectedUserIndex = request.findIndex((object) => object.emailaddress === user.emailaddress && object.businessunit === user.businessunit); 

                                                // Update the users status to deleted
                                                request[selectedUserIndex].status = 'deleted';
                                                setRequest([...request]);
                                                
                                                // Check if anyone still has this role...
                                                const allowSubmit = request.length === 0 ? false : true; 

                                                // Save back to useReducer
                                                setModalState({
                                                    'allowSubmit': allowSubmit,
                                                    'errorSubmit': allowSubmit ?  '' : 'At least one person is required',
                                                });
                                                
                                            }}></img>
                                    
                                        </li>

                                    ))
                                }

                            </ul>

                        </div>


                    </div>

                </div>

                {/* ======================================= */}
                {/*        Footer Container                 */}
                {/* ======================================= */}

                <div className='px-3'>

                    {/* Error Messages */}
                    <div className='my-3'>
                        <label hidden={modalState.errorSubmit.length === 0 ? true : false} className='text-[#DE0000] font-medium'>{modalState.errorSubmit}</label>
                    </div>

                    {/* Approve Button */}
                    <button 
                        className='Primary-Button' 
                        style={{marginRight: '10px'}} 
                        onClick={() => handleSubmit()}
                        disabled={!modalState.allowSubmit}
                    >
                        Submit
                    </button>

                    {/* Cancel Button */}
                    <button 
                        className='Secondary-Button' 
                        onClick={() => {

                            setModalState({
                                'isOpen': false,
                                'errorSubmit': '',
                                'allowSubmit': false,
                                'roleUsers': undefined,
                            });
                            setEmailAddress('');
                            setGivenname('');
                            setBusinessUnit('');
                            setRequest([]);
                            setEmailAddressInvalid(false);
                            setBusinessUnitInvalid(false);

                        }}
                    >
                        Back
                    </button>
                    
                </div>

            </dialog>

        </div>
    )

  //------------------------------------------------------
}
