//================================================================
//  Component: Business Request Form
//================================================================

//  Purpose: This is digital business improvement request form

//  Example:
//    <BusinessRequestForm></BusinessRequestForm>    

//================================================================


//Libraries
import React, { useState, useContext, useReducer } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';

//Functions
import QueryDocument from '../../Library/QueryDocument';
import WriteDocument from '../../Library/WriteDocument';

//Images
import Tick from '../../Components/Images/Icon_Tick_DarkTeal.svg';

//CSS
import './BusinessRequestForm.css';


export default function BusinessRequestForm() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store form inputs
  const [formData, setFormData] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      'initiativename': '',
      'initiativenameValid': true,     
      'initiativenameError': '',                        
      'businessneed': '',
      'businessneedValid': true,
      'owner': '',
      'ownerValid': true,
      'businesslead': '',
      'businessleadValid': true,
      'linemanager': '',
      'linemanagerValid': true,
      'financemanager': '',
      'financemanagerValid': true,
      'primarybeneficiary': '',
      'primarybeneficiaryValid': true,              
      'secondarybeneficiary': [],
      'secondarybeneficiaryValid': true,         
      'budoo': '',
      'budooValid': true,           
      'requesttype': '',
      'requesttypeValid': true,
      'aexistingproduct': '',
      'aexistingproductValid': true,                                    
      'improvements': [],
      'improvementsValid': true,                           
      'summary': '',
      'summaryValid': true,   
      'revenuegrowth': '',
      'revenuegrowthValid': true, 
      'arevenuegrowthcommence': '',
      'arevenuegrowthcommenceValid': true, 
      'fundsgrowth': '',
      'fundsgrowthValid': true, 
      'afundsgrowthcommence': '',
      'afundsgrowthcommenceValid': true, 
      'loatype': '',
      'loatypeValid': true, 
      'cost': '',
      'costValid': true, 
    }
  );

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('onload');
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Validate business request name
  const validateBusinessRequest = (event) => {

    const value = event.target.value;

    QueryDocument('businessrequests', [
      ['initiativename', '==', value]
    ]).then((existingRequests) => {

      if (existingRequests.length > 0) {

        setFormData({ 
          'initiativenameError': 'Found an existing initiative with this name.',
          'initiativenameValid': false,
        });

      } else {

        setFormData({ 
          'initiativenameError': '',
          'initiativenameValid': true,
        });

      }

    }).catch((error) => {

      console.log('error', error);
      setFormData({ 
        'initiativenameError': 'Oops, something went wrong. Try another initiative name and raise a support case if the issue persists.',
        'initiativenameValid': false,
      });

    });
  
  };

  // Handle multi-select dropdowns
  const handleMultiSelect = (event) => {

    let selected = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    )
    
    if(selected.length <= 0) {
      setFormData({[`${event.target.name}Valid`]: false})
    } else {
      setFormData({[`${event.target.name}Valid`]: true})
    }

    setFormData({ [event.target.name]: selected  });
  };

  // Function to handle changes to input fields
  function handleInputChange(evt){
    
    const name = evt.target.name;
    const newValue = evt.target.value;

    //Validate field - 'initiativename'
    if(name === 'initiativename') {
      if(newValue.length <= 0) {
        setFormData({'initiativenameValid': false})
      } else {
        setFormData({'initiativenameValid': true})
      }
    }

    //Validate field - 'businessneed'
    if(name === 'businessneed') {
      if(newValue.length <= 0 || newValue.split(" ").length > 50) {
        setFormData({'businessneedValid': false})
      } else {
        setFormData({'businessneedValid': true})
      }
    }

    //Validate field - 'owner'
    if(name === 'owner') {
      if(newValue.length <= 0) {
        setFormData({'ownerValid': false})
      } else {
        setFormData({'ownerValid': true})
      }
    }

    //Validate field - 'businesslead'
    if(name === 'businesslead') {
      if(newValue.length <= 0) {
        setFormData({'businessleadValid': false})
      } else {
        setFormData({'businessleadValid': true})
      }
    }

    //Validate field - 'linemanager'
    if(name === 'linemanager') {
      if(newValue.length <= 0) {
        setFormData({'linemanagerValid': false})
      } else {
        setFormData({'linemanagerValid': true})
      }
    }

    //Validate field - 'financemanager'
    if(name === 'financemanager') {
      if(newValue.length <= 0) {
        setFormData({'financemanagerValid': false})
      } else {
        setFormData({'financemanagerValid': true})
      }
    }

    //Validate field - 'primarybeneficiary'
    if(name === 'primarybeneficiary') {
      if(newValue.length <= 0) {
        setFormData({'primarybeneficiaryValid': false})
      } else {
        setFormData({'primarybeneficiaryValid': true})
      }
    }

    //Validate field - 'secondarybeneficiary'
    if(name === 'secondarybeneficiary') {
      if(newValue.length <= 0) {
        setFormData({'secondarybeneficiaryValid': false})
      } else {
        setFormData({'secondarybeneficiaryValid': true})
      }
    }

    //Validate field - 'budoo'
    if(name === 'budoo') {
      if(newValue.length <= 0) {
        setFormData({'budooValid': false})
      } else {
        setFormData({'budooValid': true})
      }
    }

    //Validate field - 'requesttype'
    if(name === 'requesttype') {
      if(newValue.length <= 0) {
        setFormData({'requesttypeValid': false})
      } else {
        setFormData({'requesttypeValid': true})
      }
    }

    //Validate field - 'improvements'
    if(name === 'improvements') {
      if(newValue.length <= 0) {
        setFormData({'improvementsValid': false})
      } else {
        setFormData({'improvementsValid': true})
      }
    }

    //Validate field - 'summary'
    if(name === 'summary') {
      if(newValue.length <= 0 || newValue.split(" ").length > 100) {
        setFormData({'summaryValid': false})
      } else {
        setFormData({'summaryValid': true})
      }
    }

    //Validate field - 'revenuegrowth'
    if(name === 'revenuegrowth') {
      if(newValue.length <= 0) {
        setFormData({'revenuegrowthValid': false})
      } else {
        setFormData({'revenuegrowthValid': true})
      }
    }

     //Validate field - 'fundsgrowth'
     if(name === 'fundsgrowth') {
      if(newValue.length <= 0) {
        setFormData({'fundsgrowthValid': false})
      } else {
        setFormData({'fundsgrowthValid': true})
      }
    }       

     //Validate field - 'loatype'
     if(name === 'loatype') {
      if(newValue.length <= 0) {
        setFormData({'loatypeValid': false})
      } else {
        setFormData({'loatypeValid': true})
      }
    }     

    //Validate field - 'cost'
    if(name === 'cost') {
    if(newValue.length <= 0) {
      setFormData({'costValid': false})
    } else {
      setFormData({'costValid': true})
    }
  }         

    setFormData({ [name]: newValue });

  };

  // Handle submit
  function onSubmit(e){

    // Prevent default behaviour (refresh)
    e.preventDefault();

    //=================================================
    //  Validation Checks
    //=================================================

      // OnSubmit Validation of all required fields
      let preventSubmit = false;

      // Question 1 - Initiative Name
      if (formData.initiativename.length === 0) {
      
        preventSubmit = true;
        formData.initiativenameValid = false;

      } else if (formData.initiativenameError.length > 0) {
      
        preventSubmit = true;
        formData.initiativenameValid = false;

      } else {

        formData.initiativenameValid = true;

      };

      // Question 2 - Business/Project Need
      if (formData.businessneed.length === 0 || formData.businessneed.split(" ").length > 50) {
        preventSubmit = true;
        formData.businessneedValid = false;

      } else {
        formData.businessneedValid = true;

      };

      // Question 3 - Initiative Owner
      if (formData.owner.length === 0 || formData.owner.split('@')[1] !== 'lendlease.com') {
        preventSubmit = true;
        formData.ownerValid = false;

      } else {
        formData.ownerValid = true;

      };

      // Question 4 - Project Lead/Business Lead
      if (formData.businesslead.length === 0 || formData.businesslead.split('@')[1] !== 'lendlease.com') {
        preventSubmit = true;
        formData.businessleadValid = false;

      } else {
        formData.businessleadValid = true;

      };

      // Question 5 - Line Manager
      if (formData.linemanager.length === 0 || formData.linemanager.split('@')[1] !== 'lendlease.com') {
        preventSubmit = true;
        formData.linemanagerValid = false;

      } else {
        formData.linemanagerValid = true;

      };

      // Question 6 - Finance Manager
      if (formData.financemanager.length === 0 || formData.financemanager.split('@')[1] !== 'lendlease.com') {
        preventSubmit = true;
        formData.financemanagerValid = false;

      } else {
        formData.financemanagerValid = true;

      };

      // Question 5 - Primary Beneficiary
      if (formData.primarybeneficiary.length === 0) {
        preventSubmit = true;
        formData.primarybeneficiaryValid = false;

      } else {
        formData.primarybeneficiaryValid = true;

      };

      // Question 6 - Secondary Beneficiary
      if (formData.secondarybeneficiary.length === 0) {
        preventSubmit = true;
        formData.secondarybeneficiaryValid = false;

      } else {
        formData.secondarybeneficiaryValid = true;

      };

      // Question 7 - BU DOO
      if (formData.budoo.length === 0 || formData.budoo.split('@')[1] !== 'lendlease.com') {
        preventSubmit = true;
        formData.budooValid = false;

      } else {
        formData.budooValid = true;

      };
      
      // Question 8 - Request Type
      if (formData.requesttype.length === 0) {
        preventSubmit = true;
        formData.requesttypeValid = false;

      } else {
        formData.requesttypeValid = true;

      };

      // Question 8a - Existing Product
      if (formData.requesttype === 'Replacing an existing product' && formData.aexistingproduct.length === 0) {
        preventSubmit = true;
        formData.aexistingproductValid = false;

      } else {
        formData.aexistingproductValid = true;

      };

      // Question 9 - Improvements
      if (formData.improvements.length === 0) {
        preventSubmit = true;
        formData.improvementsValid = false;

      } else {
        formData.improvementsValid = true;

      };

      // Question 10 - Summary
      if (formData.summary.length === 0 || formData.summary.split(" ").length > 100) {
        preventSubmit = true;
        formData.summaryValid = false;

      } else {
        formData.summaryValid = true;

      };
      
      // Question 11 - Revenue Growth
      if (formData.revenuegrowth.length === 0) {
        preventSubmit = true;
        formData.revenuegrowthValid = false;

      } else {
        formData.revenuegrowthValid = true;

      };

      // Question 11a - Revenue Growth Commencement
      if (formData.revenuegrowth === 'yes' && formData.arevenuegrowthcommence.length === 0) {
        preventSubmit = true;
        formData.arevenuegrowthcommenceValid = false;

      } else {
        formData.arevenuegrowthcommenceValid = true;

      };

      // Question 12 - Funds Growth
      if (formData.fundsgrowth.length === 0) {
        preventSubmit = true;
        formData.fundsgrowthValid = false;

      } else {
        formData.fundsgrowthValid = true;

      };
                  
      // Question 12a - Funds Growth Commencement
      if (formData.fundsgrowth === 'yes' && formData.afundsgrowthcommence.length === 0) {
        preventSubmit = true;
        formData.afundsgrowthcommenceValid = false;

      } else {
        formData.afundsgrowthcommenceValid = true;

      };

      // Question 13 - LoA Type
      if (formData.loatype.length === 0) {
        preventSubmit = true;
        formData.loatypeValid = false;

      } else {
        formData.loatypeValid = true;

      };

      // Question 14 - Cost
      if (formData.cost.length === 0) {
        preventSubmit = true;
        formData.costValid = false;

      } else {
        formData.costValid = true;

      };

      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) return;

      setPageStatus('pending');
    
    //=================================================
    //  Write Document to Firestore
    //=================================================

    const businessRequestId = `br-${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}`;

    const businessRequestDoc = {
      'businessrequestid': businessRequestId,   
      'initiativename': formData.initiativename,                            
      'businessneed': formData.businessneed,
      'owner': formData.owner.toLowerCase(),
      'businesslead': formData.businesslead.toLowerCase(),
      'primarybeneficiary': formData.primarybeneficiary,              
      'secondarybeneficiary': formData.secondarybeneficiary,         
      'budoo': formData.budoo.toLowerCase(),           
      'requesttype': formData.requesttype,
      'aexistingproduct': formData.aexistingproduct,                            
      'improvements': formData.improvements,                           
      'summary': formData.summary,   
      'revenuegrowth': formData.revenuegrowth, 
      'arevenuegrowthcommence': formData.arevenuegrowthcommence,  
      'fundsgrowth': formData.fundsgrowth, 
      'afundsgrowthcommence': formData.afundsgrowthcommence, 
      'loatype': formData.loatype, 
      'cost': formData.cost, 
      'status': 'Awaiting Line Manager Approval',
      'csm': '', 
      'created': new Date(),
      'createdby': getUser?.emailaddress.toLowerCase(), 
      'modified': '', 
      'modifiedby': '',
      'region': 'AU',      
      'auditlogs': [],  
      'linemanager': formData.linemanager.toLowerCase(),
      'financemanager': formData.financemanager.toLowerCase(),  
  };
  

    return WriteDocument('businessrequests', businessRequestId, businessRequestDoc, true)
    .then(() =>{
      setPageStatus('success')

    })
    .catch((error) =>{
      console.log(error)
      setPageStatus('error-fatal')

    })

  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          pageStatus !== 'success' &&
          <div className='Page-Header-Container'>
            
            <p className='font-medium text-[20px]'>Welcome to the Digital Business Improvement Request Form</p>

            <p className='text-base'>
              This is the first step in having your Digital or ICT business improvement initiative tested and assessed.
              <br></br>
              <br></br>

              This is an automated process that follows a path that is determined by the answers you provide on this form.  
              You will be supported along this path by your Digital Customer Success Manager who will be activated and will contact you once you complete this questionnaire in full.  
              Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.  
              <br></br>
              <br></br>

              All business improvement initiatives are discussed at the monthly Digital SteerCo meeting and your DOO will represent your initiative there.  
              Funding for any approved initiative must come from the project or Business Unit, and final approval will be through Group IT, so it is important to work with your Project Lead and the DOO.
              <br></br>
              <br></br>

              We are excited at learning about your improvement initiatives, and how it may benefit the business and look forward to taking it along this pathway with you.
            </p>

          </div>
        }
        requiredRoles={ ['isLLUser'] }
        requiredRolesValue={ true }
        status={pageStatus}
        body={
          <div className='Page-Section-Container'>
            
            <form className='BusinessRequest-Form' onSubmit={(e) => onSubmit(e)}>

            {/* =========================================================== */}
            {/*  1. What is the name of this initiative / application? *    */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>1. What is the name of this initiative / application? *</b> </label>
              <input
                className={formData.initiativenameValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='initiativename'
                type='text'
                placeholder='Enter a name for this initiative'
                onChange={(e) => handleInputChange(e)}
                onBlur={(e) => validateBusinessRequest(e)}
                value={formData.initiativename}
              ></input>
              
              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.initiativenameValid}>

                {
                  formData.initiativenameError.length === 0 ? (
                    <>
                      This is a required field.
                    </>
                  ): (
                    <>
                      {formData.initiativenameError}
                    </>
                  )

                }

              </label>

            </div>
            
            {/* =========================================================== */}
            {/*  2. What is the business or project need? *                 */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>2. What is the business or project need? *</b> </label>
              <textarea
                className={formData.businessneedValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')}
                name='businessneed'
                placeholder='Enter business or project need in 50 words or less.'
                onChange={(e) => handleInputChange(e)}
                value={formData.businessneed}
              ></textarea>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.businessneedValid}>
                {
                  formData.businessneed.length === 0 ?
                  <>This is a required field.</>
                  : formData.businessneed.split(" ").length > 50 ?
                  <>Please describe your business or project need in 50 words or less.</>
                  : null
                }
              </label>

            </div>

            {/* =========================================================== */}
            {/*  3. Who is the owner of this initiative / application? *    */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>3. Who is the owner of this initiative / application? *</b> </label>
              <input
                className={formData.ownerValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='owner'
                type='text'
                placeholder='Enter the email address of the owner'
                onChange={(e) => handleInputChange(e)}
                value={formData.owner}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.ownerValid}>
                {
                  formData.owner.length === 0 ?
                  <>This is a required field.</>
                  : formData.owner.split('@')[1] !== 'lendlease.com' ?
                  <>Please enter a Lendlease email address.</>
                  : null
                }
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  4. Who is the project lead / business lead supporting this initiative / application? *    */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>4. Who is the project lead / business lead supporting this initiative / application? *</b> </label>
              <input
                className={formData.businessleadValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='businesslead'
                type='text'
                placeholder='Enter the email address of the project/business lead'
                onChange={(e) => handleInputChange(e)}
                value={formData.businesslead}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.businessleadValid}>
                {
                  formData.businesslead.length === 0 ?
                  <>This is a required field.</>
                  : formData.businesslead.split('@')[1] !== 'lendlease.com' ?
                  <>Please enter a Lendlease email address.</>
                  : null
                }
              </label>

            </div>
            


            {/* ========================================================================================== */}
            {/*  5. Who is your line manager? *                                                            */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>5. Who is your line manager? *</b> </label>
              <input
                className={formData.linemanagerValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='linemanager'
                type='text'
                placeholder='Enter the email address of your line manager'
                onChange={(e) => handleInputChange(e)}
                value={formData.linemanager}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.linemanagerValid}>
                {
                  formData.linemanager.length === 0 ?
                  <>This is a required field.</>
                  : formData.linemanager.split('@')[1] !== 'lendlease.com' ?
                  <>Please enter a Lendlease email address.</>
                  : null
                }
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  6. Who is the finance manager for this request? *                                         */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>6. Who is the finance manager for this request? *</b> </label>
              <input
                className={formData.financemanagerValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='financemanager'
                type='text'
                placeholder='Enter the email address of finance manager'
                onChange={(e) => handleInputChange(e)}
                value={formData.financemanager}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.financemanagerValid}>
                {
                  formData.financemanager.length === 0 ?
                  <>This is a required field.</>
                  : formData.financemanager.split('@')[1] !== 'lendlease.com' ?
                  <>Please enter a Lendlease email address.</>
                  : null
                }
              </label>

            </div>



            {/* ========================================================================================== */}
            {/*  7.  Who will be the primary beneficiary of this business improvement solution? *          */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>7. Who will be the primary beneficiary of this business improvement solution? *</b> </label>
              <select 
                className={formData.primarybeneficiaryValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                name='primarybeneficiary'
                onChange={(e) => handleInputChange(e)}
                value={formData.primarybeneficiary}
                style={{ width: '300px'}}
              >
                <option hidden value=''>-</option>
                <option value='Capella'> Capella </option>
                <option value='Communities'> Communities </option>
                <option value='Construction'> Construction </option>
                <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                <option value='Corporate Real Estate'> Corporate Real Estate </option>
                <option value='Development'> Development </option>
                <option value='DOO Office'> DOO Office </option>
                <option value='EHS'> EHS </option>
                <option value='Finance'> Finance </option>
                <option value='Integrated Solutions'> Integrated Solutions </option>
                <option value='Investment Management'> Investment Management </option>
                <option value='Legal'> Legal </option>
                <option value='People & Culture'> People & Culture </option>
                <option value='RAP'> RAP </option>
                <option value='Risk and Insurance'> Risk and Insurance </option>
                <option value='Sustainability'> Sustainability </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.primarybeneficiaryValid}>
                This is a required field.
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  8. Who will be the secondary beneficiary(s) for this business improvement solution? *     */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label>
                <b style={{fontWeight: "500"}}>8. Who will be the secondary beneficiary(s) for this business improvement solution? *</b>
                <p className='text-sm text-slate-600 mx-3 mb-0 mt-2 p-0'>To select more than one, press Ctrl and select your options.</p> 
              </label>
              <select 
                className={formData.secondarybeneficiaryValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                name='secondarybeneficiary'
                onChange={(event) => handleMultiSelect(event)}
                value={formData.secondarybeneficiary}
                style={{ width: '400px', height: '100px' }}
                multiple
              >
                <option hidden value=''>-</option>
                <option value='Capella'> Capella </option>
                <option value='Communities'> Communities </option>
                <option value='Construction'> Construction </option>
                <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                <option value='Corporate Real Estate'> Corporate Real Estate </option>
                <option value='Development'> Development </option>
                <option value='DOO Office'> DOO Office </option>
                <option value='EHS'> EHS </option>
                <option value='Finance'> Finance </option>
                <option value='Integrated Solutions'> Integrated Solutions </option>
                <option value='Investment Management'> Investment Management </option>
                <option value='Legal'> Legal </option>
                <option value='People & Culture'> People & Culture </option>
                <option value='RAP'> RAP </option>
                <option value='Risk and Insurance'> Risk and Insurance </option>
                <option value='Sustainability'> Sustainability </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.secondarybeneficiaryValid}>
                This is a required field.
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  9. Who is the BU DOO supporting this initiative / application? *                          */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>9. Who is the BU DOO supporting this initiative / application? *</b> </label>
              <input
                className={formData.budooValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='budoo'
                type='text'
                placeholder='Enter the email address of the BU DOO'
                onChange={(e) => handleInputChange(e)}
                value={formData.budoo}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.budooValid}>
                {
                  formData.budoo.length === 0 ?
                  <>This is a required field.</>
                  : formData.budoo.split('@')[1] !== 'lendlease.com' ?
                  <>Please enter a Lendlease email address.</>
                  : null
                }
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  10. What type of request is this? *                                                        */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>10. What type of request is this? *</b> </label>
              <select 
                className={formData.requesttypeValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                name='requesttype'
                onChange={(e) => handleInputChange(e)}
                value={formData.requesttype}
                style={{ width: '450px' }}
              >
                <option hidden value=''>-</option>
                <option value='New Initiative / Product'> New Initiative / Product </option>
                <option value='Renewal of an existing product'> Renewal of an existing product </option>
                <option value='Replacing an existing product'> Replacing an existing product </option>
              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.requesttypeValid}>
                This is a required field.
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  10a. What is the name of the existing product? *                                           */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row' hidden={formData.requesttype === 'Replacing an existing product' ? false : true}>

              <label><b style={{fontWeight: "500"}}>10a. What is the name of the existing product? *</b> </label>
              <input
                className={formData.aexistingproductValid ? ('Input-Field-Text'): ('Input-Field-Text-Error')}
                name='aexistingproduct'
                type='text'
                placeholder='Enter the name of the existing product'
                onChange={(e) => handleInputChange(e)}
                value={formData.aexistingproduct}
              ></input>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.aexistingproductValid}>
                Please enter the name of the existing product.
              </label>

            </div>

            {/* ========================================================================================== */}
            {/*  11. Will this initiative improve one or more of the following? *                           */}
            {/* ========================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label>
                <b style={{fontWeight: "500"}}>11. Will this initiative improve one or more of the following? *</b>
                <p className='text-sm text-slate-600 mx-3 mb-0 mt-2 p-0'>To select more than one, press Ctrl and select your options.</p> 
              </label>
              <select 
                className={formData.improvementsValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                name='improvements'
                onChange={(event) => handleMultiSelect(event)}
                value={formData.improvements}
                style={{ width: '400px', height: '100px' }}
                multiple
              >
                <option hidden value=''>-</option>
                <option value='Business efficiency'> Business efficiency </option>
                <option value='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                <option value='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                <option value='ESG requirements or credentials'> ESG requirements or credentials </option>
                <option value='Investment in our people'> Investment in our people </option>
                <option value='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                <option value='Regulatory compliance'> Regulatory compliance </option>
                <option value='Risk mitigation'> Risk mitigation </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.improvementsValid}>
                This is a required field.
              </label>

            </div>

            {/* =================================================================================================== */}
            {/*  12. Provide a summary of how this initiative will improve the items chosen above *                 */}
            {/* =================================================================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>12. Provide a summary of how this initiative will improve the items chosen above *</b> </label>
              <textarea
                className={formData.summaryValid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')}
                name='summary' 
                placeholder='Enter a summary in 100 words or less.'
                onChange={(e) => handleInputChange(e)}
                value={formData.summary}
              ></textarea>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.summaryValid}>
                {
                  formData.summary.length === 0 ?
                  <>This is a required field.</>
                  : formData.summary.split(" ").length > 100 ?
                  <>Please enter a summary in 100 words or less.</>
                  : null
                }
              </label>

            </div>

            {/* =========================================================== */}
            {/*  13. Does this initiative drive revenue growth? *           */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>13. Does this initiative drive revenue growth? *</b> </label>
              
              <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                <label className='Radio-Buttons-Container'>
                  Yes
                  <input 
                    type='radio' 
                    name='revenuegrowth' 
                    value='yes' 
                    checked={formData.revenuegrowth === 'yes'} 
                    onChange={(e) => handleInputChange(e)}
                    ></input>
                  <span className={formData.revenuegrowthValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                </label>

                <label className='Radio-Buttons-Container'>
                  No
                  <input 
                    type='radio' 
                    name='revenuegrowth' 
                    value='no' 
                    checked={formData.revenuegrowth === 'no'} 
                    onChange={(e) => handleInputChange(e)}
                    ></input>
                  <span className={formData.revenuegrowthValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                </label>
              </div>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.revenuegrowthValid}>
                This is a required field.
              </label>

            </div>

            {/* =========================================================== */}
            {/*  13a. If yes, when would revenue growth commence?*          */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row' hidden={formData.revenuegrowth === 'yes' ? false : true}>

              <label><b style={{fontWeight: "500"}}>13a. If yes, when would revenue growth commence? *</b> </label>
              
              <select 
                  className={formData.arevenuegrowthcommenceValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  name='arevenuegrowthcommence'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.arevenuegrowthcommence}
                  style={{ width: '450px'}}
                >
                  <option hidden value=''>-</option>
                  <option value='Within 6 months'> Within 6 months </option>
                  <option value='6 months to 1 year'> 6 months to 1 year </option>
                  <option value='1 to 2 years'> 1 to 2 years </option>
                  <option value='In more than 2 years'> In more than 2 years </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.arevenuegrowthcommenceValid}>
                Please select an option.
              </label>

            </div>

            {/* =========================================================== */}
            {/*  14. Does this product accelerate funds growth? *           */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>14. Does this product accelerate funds growth? *</b> </label>
              
              <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                <label className='Radio-Buttons-Container'>
                  Yes
                  <input 
                    type='radio' 
                    name='fundsgrowth' 
                    value='yes' 
                    checked={formData.fundsgrowth === 'yes'} 
                    onChange={(e) => handleInputChange(e)}
                    ></input>
                  <span className={formData.fundsgrowthValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                </label>

                <label className='Radio-Buttons-Container'>
                  No
                  <input 
                    type='radio' 
                    name='fundsgrowth' 
                    value='no' 
                    checked={formData.fundsgrowth === 'no'} 
                    onChange={(e) => handleInputChange(e)}
                    ></input>
                  <span className={formData.fundsgrowthValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                </label>
              </div>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.fundsgrowthValid}>
                This is a required field.
              </label>

            </div>

            {/* =========================================================== */}
            {/*  14a. If yes, when would funds growth commence? *           */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row' hidden={formData.fundsgrowth === 'yes' ? false : true}>

              <label><b style={{fontWeight: "500"}}>14a. If yes, when would funds growth commence? *</b> </label>
              <select 
                  className={formData.afundsgrowthcommenceValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  name='afundsgrowthcommence'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.afundsgrowthcommence}
                  style={{ width: '450px'}}
                >
                  <option hidden value=''>-</option>
                  <option value='Within 6 months'> Within 6 months </option>
                  <option value='6 months to 1 year'> 6 months to 1 year </option>
                  <option value='1 to 2 years'> 1 to 2 years </option>
                  <option value='In more than 2 years'> In more than 2 years </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.afundsgrowthcommenceValid}>
                Please select an option.
              </label>

            </div>
            
            {/* =========================================================== */}
            {/*  15. What LoA Type is this initiative / application? *      */}
            {/* =========================================================== */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>15. What LoA Type is this initiative / application? *</b> </label>
              <select 
                  className={formData.loatypeValid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
                  name='loatype'
                  onChange={(e) => handleInputChange(e)}
                  value={formData.loatype}
                >
                  <option hidden value=''>-</option>
                  <option value='IT Capex'> IT Capex </option>
                  <option value='IT Opex'> IT Opex </option>
                  <option value='Project Based'> Project Based </option>

              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.loatypeValid}>
                This is a required field.
              </label>

            </div>

            {/* ================================================================ */}
            {/*  16. What is the anticipated complete cost of this initiative? * */}
            {/* ================================================================ */}

            <div className='BusinessRequest-Form-Row'>

              <label><b style={{fontWeight: "500"}}>16. What is the anticipated complete cost of this initiative? *</b> </label>
              <select  className={formData.costValid ? ('Input-Field-Select'):('Input-Field-Select-Error')} name='cost' onChange={(e) => handleInputChange(e)} value={formData.cost}>
                  <option hidden value=''>-</option>
                  <option value='$0'> $0 </option>
                  <option value='$1 to $10k'> $1 to $10k </option>
                  <option value='$10k to $30k'> $10k to $30k </option>
                  <option value='$30k to $50k'> $30k to $50k </option>
                  <option value='$50k to $100k'> $50k to $100k </option>
                  <option value='$100k to $250k'> $100k to $250k </option>
                  <option value='$250k or more'> $250k or more </option>
              </select>

              <label className='font-medium text-sm text-[var(--darkred)]' hidden={formData.costValid}>
                This is a required field.
              </label>

            </div>

            {/* ===================================== */}
            {/*  Submit Buttons                       */}
            {/* ===================================== */}

            <div>
              <button style={{marginTop: '30px'}} className='Primary-Button' type='submit' value='submit'>Submit</button>
            </div>

            </form>

          </div>
        }
        successContent={
          <div className='Page-Section-Container' style={{padding: '3%'}}>

            <div className='flex flex-row gap-[12px] items-center'>
              <img className='m-0 p-0' src={Tick} alt='Complete'></img>
              <h4 className='my-2 text-[#007A78]'>Thank You</h4>
            </div>

            <p className='font-medium text-[18px] my-3'>Your request has been successfully submitted</p>

            <p className='text-base'>
              This is the first step in having your Digital or ICT business improvement initiative tested and assessed.
              <br></br>
              <br></br>

              This is an automated process that follows a path that is determined by the answers you provide on this form.  
              You will be supported along this path by your Digital Customer Success Manager who will be activated and will contact you once you complete this questionnaire in full.  
              Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.  
              <br></br>
              <br></br>

              All business improvement initiatives are discussed at the monthly Digital SteerCo meeting and your DOO will represent your initiative there.  
              Funding for any approved initiative must come from the project or Business Unit, and final approval will be through Group IT, so it is important to work with your Project Lead and the DOO.
              <br></br>
              <br></br>

              We are excited at learning about your improvement initiatives, and how it may benefit the business and look forward to taking it along this pathway with you.
            </p>

            <button className='Primary-Button' onClick={() => window.location.reload()}> Submit Another Request </button>

          </div>
        }
      ></PageComponent> 
    )
}
