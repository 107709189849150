// Libraries
import React, {useContext, useEffect} from 'react'
import { getAuth, signInWithRedirect, signInWithPopup } from 'firebase/auth';

// Contexts
import { GetFireBaseProvider, SetAppStatus, SetAppErrors } from './GlobalContexts';

/**
 * Trigger a login through Firebase
 * 
 * @param {boolean} autoSignin - <Optional> Trigger a signin onmount
 * 
 * ```
 * const autoSignin = true;
 * 
 * <Login
 *   autoSignin={autoSignin}
 * ></Login>    
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
 */

export default function Login({
  autoSignin
}) {

  //------------------------------------------------------
  //  Import Firebase
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // This function triggers a redirect signin > AuthProvider.js will handle the results
  // https://firebase.google.com/docs/auth/web/microsoft-oauth
  //------------------------------------------------------

    function SignIn(){

      // If Development > Pop-up
      if (process.env.REACT_APP_FIREBASE_AUTH_DOMAIN.includes('firebaseapp.com')) {

        return signInWithPopup(auth, Provider)
        .catch((error) =>{
  
          setAppStatus('failed');
          setAppErrors(error.message);
  
        });
        
      }

      // If Production > Redirect
      signInWithRedirect(auth, Provider).catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }

  //------------------------------------------------------
  // useEffects
  //------------------------------------------------------

    useEffect(() => {
      
      if (autoSignin === false) return;

      SignIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSignin]);

  //------------------------------------------------------
  //  Return
  //------------------------------------------------------

    if (autoSignin === true) return null;

    return (
      <button className='Primary-Button'onClick={SignIn}>
        Sign in
      </button>
    )

  //------------------------------------------------------
}