// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore, onSnapshot, collection, getDocs } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';

/**
 * Creates a Firebase collection listener
 * 
 * @param {string} collectionId
 * @param {function} onLoad
 * @param {function} onChange
 * @param {function} onError
 * 
 * ```
 * useEffect(()=>{
 * 
 *   if (productId === undefined) return;
 * 
 *   function onLoad(document){
 *     setProductObject(document);
 *   }
 * 
 *   function onChange(document, previousValue){
 *     if(previousValue === undefined) return;
 *     if(previousValue?.pricingamount !== document?.pricingamount) setShowModal(true);   
 *   }
 * 
 *   function onError(error){
 *     setRequestType('error-fatal');
 *     setError(`Failed to get product information, unable to create the document listener error: ${error}`);  
 *   }
 * 
 *   const unsubscribe = CollectionListener('products', onLoad, onChange, onError);
 * 
 *   return () =>{
 *     unsubscribe();
 *   };
 * 
 * }, [productId]);
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/listen
 * - https://firebase.google.com/docs/reference/android/com/google/firebase/firestore/DocumentSnapshot
 */

export default function CollectionListener(collectionId, onLoad, onChange, onError){

  try {

    //Firestore client
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    //Create document reference
    const colRef = collection(db, collectionId);

    //Get the document > call 'onLoad' function
    getDocs(colRef).then((documents) => {

      let array = []

      documents.forEach(doc =>{

        array.push(doc.data());

      });

      onLoad(array);

    }).catch((error) => {
      console.log(error)
      onError(`CollectionListener has failed to complete, error: ${error}`);

    });

    var previousValue;

    // Invoke document listener > call 'onChange' function
    const unsubscribe = onSnapshot(colRef, (documents) => {

      let array = []

      documents.forEach(doc =>{

        array.push(doc.data());

      });

      onChange(array, previousValue);
      previousValue = array;

    });

    // Return the callback function > Allows the caller to stop listening to changes
    return unsubscribe;

  } catch (error) {

    onError(`CollectionListener has failed to complete, error: ${error}`);

  }

}
